import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import VerifyImage from '../../../../../images/verified.png'
import NotVerified from '../../../../../images/cross.png'
import { ROLE } from '../../../../../utils/Constant'
import moment from 'moment'
const CrifNew = ({ showOrignal, originalCrifReport, viewOriginaReportCrif, crifButtonFromApi, regenerateButton, rawReportData, zoomState, allDetail, jsondata, crfJsonData, underWriterHandler, saveUnderWritter, openCommentPopup, notesPopup, closepopup, newNotes, commentChangeHandler, saveNotesType, admin, notesType, crifTab, analysisData, crifData, userCrifData, regenerateCrif, crifLoaderData, parseData, sortedData, zoomPage,getCrifValueWiseData,crifprevdata }) => {

    return (
        <>

            {zoomState ?
                <div className='container-fluid'>
                    <div className="background-blur">
                        <div className='text-right' style={{
                        }}>
                            <i onClick={() => zoomPage('zoom')} style={{
                                fontSize: '14px', cursor: 'pointer', marginRight: '4.9%',
                                marginTop: '79px',
                                position: 'relative',
                                zIndex: 99,
                                height: '25px',
                                width: '25px',
                                background: 'red',
                                color: '#fff', padding: '4px 7px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'
                            }} class="fa fa-times" aria-hidden="true"></i>
                        </div>

                        <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                            <div className=' ' style={{ marginBottom: '20px' }}>

                                <div className='row'>
                                    {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                                        <div className='col-sm-6 col-xs-6'>
                                            <div style={{ borderRadius: '6px', border: analysisData.finalDecision === 'Rejected' ? '1px solid  #FF4444' : '1px solid var(--success, #00C851)', background: analysisData.finalDecision === 'Rejected' ? '#FEECEB' : 'var(--light-green, ##FEECEB)', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Automated Decision</p>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.finalDecision === 'Rejected' ? 'red' : analysisData.finalDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finalDecision}</b></h4>
                                                        {analysisData.fetchDate ? <> Verified on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                    {analysisData.score != '' && analysisData.score != null && crifTab === 'crifreport' ?
                                        <div className='col-sm-6 col-xs-6'>
                                            <div style={{ borderRadius: '6px', border: '1px solid #267DFF', background: '#F1F7FF', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Cibil Score</p>

                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.score === 'Rejected' ? 'red' : analysisData.score === 'Approved' ? 'green' : '#31311f' }}>{analysisData.score}</b></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}

                                </div>

                                {analysisData != '' && analysisData != null && crifTab === 'crifreport' ?
                                    <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '50px' }}>


                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Dual Pan&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>{analysisData.dualPan == true ?
                                                    <img src={VerifyImage} width="22px" />
                                                    : <img src={NotVerified} width="20px" />} </h5>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Mobile Matched&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>{analysisData.mobileMatched == true ?
                                                    <img src={VerifyImage} width="22px" />
                                                    : <img src={NotVerified} width="20px" />
                                                }</h5>

                                            </div>
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address Pincode Matched&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>
                                                    {analysisData.currentAddressPincodeMatched == true ?
                                                        <img src={VerifyImage} width="22px" />
                                                        : <img src={NotVerified} width="20px" />
                                                    }                     </h5>

                                            </div>

                                        </div>



                                    </div>
                                    : ""}


<div 
                                        dangerouslySetInnerHTML={{
                                            __html: crifprevdata
                                        }}
                                    ></div>

                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <div className="row from-group">
                            <div className="col-xs-12" style={{ padding: '10px' }}>
                                <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                            </div>
                        </div>
                        {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?

                            <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>

                                <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                                <div className='text-right'>
                                    <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                </div>
                            </div>
                            : ""}
                        {newNotes !== '' && newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                        {newNotes !== '' && newNotes.length > 0 ?
                            <div style={{
                                borderRadius: '10px',
                                border: '1px solid #D1D9E2',
                                background: '#fff',
                                maxHeight: '200px',
                                overflowY: 'scroll'
                            }}>

                                {newNotes.map((data, i) => {
                                    return (
                                        <div className='container-fluid'>
                                            <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                <div className='col-sm-6 col-xs-6'>
                                                    {data.notes}<br />
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                </div>

                                            </div>
                                            {/* <hr /> */}
                                        </div>
                                    )
                                })}
                            </div>
                            : <h4 className='text-center' style={{ marginTop: '10px' }}>No already saved Comments Found</h4>}

                    </div>
                </div>
                : ""}
            <div className='container-fluid'>
                {crifTab === 'crifreport' ?
                    <div className='col-xs-12  ' style={{ marginTop: '20px', marginBottom: '20px' }}>
                        {crifButtonFromApi === true && admin.rolelist.indexOf(ROLE.CRIF_RE_GENERATE) >= 0 && crifLoaderData === false && regenerateButton === true && (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                            <button className='btn btn-primary' onClick={e => regenerateCrif()}>
                                Regenerate Crif
                            </button>
                            : ""}
                        {crifTab === 'crifreport' ?
                            <div style={{ float: 'inline-end', marginTop: '-15px' }} >
                                <span onClick={() => openCommentPopup('crifDecision')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>
                                {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <>
                                    <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                        <option value={''}>Select Option</option>
                                        <option value={'Approved'}>Approved</option>
                                        <option value={'Reject'}>Reject</option>

                                    </select>
                                    <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter()}>Save</button>
                                </> : ""}
                            </div>
                            : ""}
                    </div>
                    : ""}

                {analysisData != null &&
                    analysisData.rejectNote != '' &&
                    analysisData.rejectNote != null &&
                    analysisData.rejectNote != undefined ? (
                    <h4 className='text-center'>
                        <b>

                            <u> Notes</u>
                        </b>
                    </h4>
                ) : (
                    ''
                )}
                {analysisData != null &&
                    analysisData != undefined &&
                    analysisData != '' &&
                    analysisData.rejectNote != null &&
                    analysisData.rejectNote != '' &&
                    analysisData.rejectNote.length > 0
                    ? analysisData.rejectNote.map((data, i) => {
                        return (
                            <div key={i}>
                                <h5 className='reject-note'>{data}</h5>
                            </div>
                        )
                    })
                    : ''}


                {/* {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                    <p className='text-center'>
                        <h2>Automated Decision:-</h2>

                        {analysisData.finalDecision == 'Rejected' ?
                            <h1 className='blinknew text-center text-danger'>{analysisData.finalDecision}</h1>
                            : <h1 className='blinknew text-center' style={{ color: 'green' }}>{analysisData.finalDecision}</h1>}
                    </p>
                    : ""} */}

                {crifLoaderData === false ?
                    <div className=''>
                        {crifTab === 'crifreport' ?
                            <div className='row ' style={{ marginBottom: '20px' }}>
                                <div className='text-right' style={{ marginBottom: '10px' }}>
                                    <button className='btn btn-primary' onClick={() => zoomPage('zoom')}>
                                        View Full Report&nbsp; <i style={{ cursor: 'pointer', fontSize: '20px' }} className='fa fa-expand'></i>
                                    </button>
                                    &nbsp;&nbsp;
                                    <button className='btn btn-primary' onClick={() => viewOriginaReportCrif('zoom')}>
                                    View Original Report&nbsp; <i style={{ cursor: 'pointer', fontSize: '20px' }} className='fa fa-expand'></i>
                                    </button>
                                </div>
                                <div className='row'>
                                    {analysisData.finalDecision != null && crifTab === 'crifreport' ?
                                        <div className='col-sm-6 col-xs-6'>
                                            <div style={{ borderRadius: '6px', border: analysisData.finalDecision === 'Rejected' ? '1px solid  #FF4444' : '1px solid var(--success, #00C851)', background: analysisData.finalDecision === 'Rejected' ? '#FEECEB' : 'var(--light-green, ##FEECEB)', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Automated Decision</p>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.finalDecision === 'Rejected' ? 'red' : analysisData.finalDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finalDecision}</b></h4>
                                                        {analysisData.fetchDate ? <> Verified on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                    {analysisData.score != '' && analysisData.score != null && crifTab === 'crifreport' ?
                                        <div className='col-sm-6 col-xs-6'>
                                            <div style={{ borderRadius: '6px', border: '1px solid #267DFF', background: '#F1F7FF', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Cibil Score</p>

                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.score === 'Rejected' ? 'red' : analysisData.score === 'Approved' ? 'green' : '#31311f' }}>{analysisData.score}</b></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}

                                </div>

                                {analysisData != '' && analysisData != null && crifTab === 'crifreport' ?
                                    <div className='credit-second-box-small' style={{ padding: '0px 12px', minHeight: '50px' }}>


                                        <div className="row">
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Dual Pan&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>{analysisData.dualPan == true ?
                                                    <img src={VerifyImage} width="22px" />
                                                    : <img src={NotVerified} width="20px" />} </h5>
                                            </div>
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Mobile Matched&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>{analysisData.mobileMatched == true ?
                                                    <img src={VerifyImage} width="22px" />
                                                    : <img src={NotVerified} width="20px" />
                                                }</h5>

                                            </div>
                                            <div className="col-sm-4 col-xs-12 form-group">
                                                <b style={{ fontSize: '14px', color: ' rgba(0, 0, 0, 0.60)' }}>Current Address Pincode Matched&nbsp;</b>
                                                <h5 style={{ fontWeight: '700' }}>
                                                    {analysisData.currentAddressPincodeMatched == true ?
                                                        <img src={VerifyImage} width="22px" />
                                                        : <img src={NotVerified} width="20px" />
                                                    }                     </h5>

                                            </div>

                                        </div>



                                    </div>
                                    : ""}


                             

                            </div>
                            : ""}

                        {crifTab === 'clearrejection' ?
                            <div className='' >

                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.rejected != null &&
                                    analysisData.rejected != '' &&
                                    analysisData.rejected != undefined && analysisData.finalDecision == 'Rejected' ? (
                                    <div className='red-table-new phocket-table-new'>
                                        <table>
                                            <thead>
                                                <tr className='thead-class'>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th>Status</th>
                                                    <th>Matched Condition</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != ''
                                                    ? analysisData.rejected.map((data, i) => {
                                                        const messageType = data.disburseAmount;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['redFlag'] && jsondata['redFlag'].some(item => (item.disburseAmount === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('redFlag', data)}>
                                                                <td>{data.accountType}</td>
                                                                <td>{data.overDueAmount}</td>
                                                                <td>{data.disburseAmount}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBalance}</td>
                                                                <td>{data.status}</td>
                                                                {/* <td>{data.matchedCondition}</td> */}
                                                                <td>{data.matchedCondition == 'Case 1' ? 'Suit-Filed/Willful/DBT/LSS/SMA & no sanctioning in last 3 Years' : data.matchedCondition == 'Case 2' ? 'Write-off/Settled, DBT, LSS & SMA with amount > INR 5000 in last 2 years' : data.matchedCondition == 'Case 3' ? 'Max DPD >= 90 in last 24 months with Balance > INR 5,000' : data.matchedCondition == 'Case 4' ? 'DPD >= 5 in last 6 months with Balance/Overdue amount >INR 5,000' : data.matchedCondition == 'Case 5' ? 'Current Sanctioning Exception Case & previous loan overdue' : ""}</td>

                                                            </tr>
                                                        )
                                                    })
                                                    : ""}

                                            </tbody>
                                        </table>
                                    </div>

                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                        {crifTab === 'greenflag' ?
                            <div className='' >
                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.approve != null &&
                                    analysisData.approve != '' &&
                                    analysisData.approve != undefined && analysisData.finalDecision != 'Rejected' ? (
                                    <div className='green-table phocket-table-new'>
                                        <table className='text-center '>
                                            <thead>
                                                <tr>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != ''
                                                    ? analysisData.approve.map((data, i) => {
                                                        const messageType = data.disburseAmount;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['greenFlag'] && jsondata['greenFlag'].some(item => (item.disburseAmount === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('greenFlag', data)}>
                                                                <td>{data.accountType}</td>
                                                                <td>{data.overDueAmount}</td>
                                                                <td>{data.disburseAmount}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBalance}</td>
                                                                <td>{data.status}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                        {crifTab === 'exceptional' ?
                            <div className='' >

                                {analysisData != null &&
                                    analysisData != undefined &&
                                    analysisData != '' &&
                                    analysisData.exceptionCase != null &&
                                    analysisData.exceptionCase != '' &&
                                    analysisData.exceptionCase != undefined ? (
                                    <div className='yellow-table phocket-table-new'>
                                        <table className='text-center '>
                                            <thead>
                                                <tr>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {analysisData != null &&
                                                    analysisData != undefined &&
                                                    analysisData != ''
                                                    ? analysisData.exceptionCase.map((data, i) => {
                                                        const messageType = data.disburseAmount;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['yellowFlag'] && jsondata['yellowFlag'].some(item => (item.disburseAmount === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('yellowFlag', data)}>
                                                                <td>{data.accountType}</td>
                                                                <td>{data.overDueAmount}</td>
                                                                <td>{data.disburseAmount}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBalance}</td>
                                                                <td>{data.status}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                        {crifTab === 'obligation' && crifData.obligationData && crifData.obligationData.loanReportAnalysed ?
                            <div className='' >

                                {crifData != null &&
                                    crifData != undefined &&
                                    crifData != '' &&
                                    crifData.obligationData.loanReportAnalysed != null &&
                                    crifData.obligationData.loanReportAnalysed != '' &&
                                    crifData.obligationData.loanReportAnalysed != undefined ? (
                                    <div className='phocket-table-new'>
                                        <table className='text-center '>
                                            <thead>
                                                <tr>
                                                    <th>Account Type</th>
                                                    <th>OverDue Amount</th>
                                                    <th>Disburse Amount</th>
                                                    <th>Disburse Date</th>
                                                    <th>Account Status</th>
                                                    <th>Current Balance</th>
                                                    <th style={{ width: '50px' }}>Combined Payment History</th>
                                                    <th>Ownership</th>
                                                    <th>Security Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {crifData.obligationData != null &&
                                                    crifData.obligationData != undefined &&
                                                    crifData.obligationData != ''
                                                    ? crifData.obligationData.loanReportAnalysed.map((data, i) => {
                                                        var updatedData = data.combinedPaymentHistory.replace(/\|/g, ' ');
                                                        const messageType = data.disbursedAmt;
                                                        const amount = data.status;

                                                        const isEmiIncluded = jsondata['obligation'] && jsondata['obligation'].some(item => (item.disbursedAmt === messageType) && (item.status === amount));

                                                        return (
                                                            <tr key={i} style={{
                                                                background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer'
                                                            }} onClick={() => crfJsonData('obligation', data)}>
                                                                <td>{data.accType}</td>
                                                                <td>{data.overDueAmt}</td>
                                                                <td>{data.disbursedAmt}</td>
                                                                <td>{data.disburseDate}</td>
                                                                <td>{data.accountStatus}</td>
                                                                <td>{data.currentBal}</td>
                                                                <td style={{ width: '50px' }}>{updatedData}</td>
                                                                <td>{data.ownershipInd}</td>
                                                                <td>{data.securityStatus}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            : ""}
                    </div>
                    : ""}
                {showOrignal && originalCrifReport !== 'no data'&&originalCrifReport !== '' ?
                    <div className='container-fluid'>
                        <div className="background-blur">
                            <div className='text-right' style={{
                            }}>
                                <i onClick={() => viewOriginaReportCrif('zoom')} style={{
                                    fontSize: '14px', cursor: 'pointer', marginRight: '4.9%',
                                    marginTop: '79px',
                                    position: 'relative',
                                    zIndex: 99,
                                    height: '25px',
                                    width: '25px',
                                    background: 'red',
                                    color: '#fff', padding: '4px 7px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'
                                }} class="fa fa-times" aria-hidden="true"></i>
                            </div>

                            <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                                <div className=' ' style={{ marginBottom: '20px' }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: originalCrifReport
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        </div>
                    : ""}
                        {crifLoaderData ?
                            <Skeleton height={50} count={10} />
                            : ""}
                    </div>
        </>
            )
}
            export default CrifNew