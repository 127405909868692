import React, { useEffect, useState } from "react";
import { saveEmpDataApi } from "../../../AdminActionCreator.component";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { getAllDepartmentListOld, saveSalaryTargetsapi, getSavedSalaryTargetDataApi } from "../../targetactioncreator";
import Loader from '../../../../../presentationals/Loader/Loader.component';

const EmployeesTarget = ({ admin, editData }) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2022 + 1 }, (_, i) => 2022 + i);

    const months = [
        { value: 'JANUARY', label: 'JANUARY' },
        { value: 'FEBRUARY', label: 'FEBRUARY' },
        { value: 'MARCH', label: 'MARCH' },
        { value: 'APRIL', label: 'APRIL' },
        { value: 'MAY', label: 'MAY' },
        { value: 'JUNE', label: 'JUNE' },
        { value: 'JULY', label: 'JULY' },
        { value: 'AUGUST', label: 'AUGUST' },
        { value: 'SEPTEMBER', label: 'SEPTEMBER' },
        { value: 'OCTOBER', label: 'OCTOBER' },
        { value: 'NOVEMBER', label: 'NOVEMBER' },
        { value: 'DECEMBER', label: 'DECEMBER' },
    ];
    

    const [loader, setLoader] = useState(false);
    const [employeeData, setEmployeeData] = useState({
        departmentId: "",
        salaryTarget: "",
        targetBy: "",
        targetMonth: "",
        year: "",
        addedBy: admin.emailId
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [department, SetDepartMent] = useState('');

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.departmentId) {
            formErrors.departmentId = "Department is required";
            isValid = false;
        }

        if (!employeeData.salaryTarget) {
            formErrors.salaryTarget = "Salary Target is required";
            isValid = false;
        }

        if (!employeeData.targetBy) {
            formErrors.targetBy = "Target By is required";
            isValid = false;
        }

        if (!employeeData.targetMonth) {
            formErrors.targetMonth = "Target Month is required";
            isValid = false;
        }

        if (!employeeData.year) {
            formErrors.year = "Target Year is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoader(true);
            const saveCallback = (callback) => {
                setLoader(false);
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus(editData.id ? 'Data Updated Successfully!' : 'Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            };

            if (editData.id) {
                saveSalaryTargetsapi(employeeData, saveCallback);
            } else {
                saveSalaryTargetsapi(employeeData, saveCallback);
            }
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            console.log(editData)
            setEmployeeData({
                departmentId: editData.departmentId || "",
                salaryTarget: editData.salaryTarget || "",
                targetBy: editData.targetBy || "",
                targetMonth: editData.targetMonth || "",
                year: editData.year || "",
                addedBy: editData.addedBy || "",
                id: editData.id
            });
        }
    }, [editData]);

    useEffect(() => {
        getAllDepartmentListOld((callback) => {
            setLoader(false);
            if (callback?.status === 200) {
                SetDepartMent(callback.data);
            }
        });
    }, []);

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            {loader && <Loader />}
            <div className="target-box">
                <h3>Add/Update Targets</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="departmentId" value={employeeData.departmentId} onChange={handleChange}>
                                        <option value="">Select Department</option>
                                        {department && department.map((data, i) => (
                                            <option key={i} value={data.departmentId}>{data.departmentName}</option>
                                        ))}
                                    </select>
                                    {errors.departmentId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.departmentId}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Salary Target</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="salaryTarget"
                                        value={employeeData.salaryTarget}
                                        onChange={handleChange}
                                    />
                                    {errors.salaryTarget && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.salaryTarget}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target By</label>
                                </div>
                          
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="targetBy" value={employeeData.targetBy} onChange={handleChange}>
                                        <option value="">Select Target By</option>
                                        <option value="MONTHLY">Monthly</option>
                                        <option value="YEARLY">Yearly</option>
                                    </select>
                                    {errors.targetBy && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetBy}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target Month</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="targetMonth" value={employeeData.targetMonth} onChange={handleChange}>
                                        <option value="">Select Month</option>
                                        {months.map(m => (
                                            <option key={m.value} value={m.value}>{m.label}</option>
                                        ))}
                                    </select>
                                    {errors.targetMonth && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetMonth}</span>
                                    )}
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target Year</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select className="form-control" name="year" value={employeeData.year} onChange={handleChange}>
                                        <option value="">Select Year</option>
                                        {years.map(y => (
                                            <option key={y} value={y}>{y}</option>
                                        ))}
                                    </select>
                                    {errors.year && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.year}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmployeesTarget;
