import React, { useState } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RepeatMandatory from "./repeatMandatory";
import FreshMandatory from "./freshchecklist";

const MainChcekList = ({ pageViewHandler }) => {
    const [viewState, setViewState] = useState('repeat')
    const BackBtnStyle2 = {
        color: '#fff',
        fontWeight: '400',
        display: 'flex',
        background: '#223d64',
        cursor: 'pointer',
        padding: '12px 20px',
        'boxShadow': 'inset -4px -4px 4px 0px #1d252d'
    }
    return (
        <>
            <div className="row fixed-top" style={{ marginTop: '0px', display: 'flex', width: '101%' }}>
                <div className="" onClick={(e) => pageViewHandler(e, 'landingPage')} style={BackBtnStyle2}>
                    <ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back

                </div>
                <div style={{ width: '50%' }}></div>
                <div className="" onClick={() => setViewState('repeat')} style={{ borderBottom: viewState === 'repeat' ? '2px solid #267DFF' : '', background: viewState === 'repeat' ? '#267DFF' : '#DBE9FF', padding: viewState === 'repeat' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'repeat' ? '#fff' : '#000', cursor: 'pointer', width: '25%' }}>
                    Repeat

                </div>
                <div className="" onClick={() => setViewState('fresh')} style={{ borderBottom: viewState === 'fresh' ? '2px solid #267DFF' : '', background: viewState === 'fresh' ? '#267DFF' : '#DBE9FF', padding: viewState === 'fresh' ? '13px' : '12.2px', textAlign: 'center', color: viewState === 'fresh' ? '#fff' : '#000', cursor: 'pointer', width: '25%' }}>
                    Fresh

                </div>


            </div>
            {viewState === 'repeat' ?
                <RepeatMandatory />
                : ""}
            {viewState === 'fresh' ?
                <FreshMandatory />
                : ""}
        </>

    )
}
export default MainChcekList