import React, { useState, useEffect } from 'react'
import moment from "moment";
import { ComposedChart, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Label } from 'recharts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { getPaymentStatsAPI, getPaymentSModesBreakDownAPI, getPaymentReceivedByDateAPI, getInvoicedPaidAmountMonthlyAPI, getPaymentReceivedByDPDAPI } from './DebtListActionCreator';
import PaymentPopup from './PaymentPopup';
import Loader from '../../../presentationals/Loader/Loader.component';
// const [yearValue, setYearValue] = useState([
//     '2021', '2022', '2023'
// ])
// const [yearsSelected, setYearsSelected] = useState([]);
// const handleyearcheckbox = (value) => {
//     console.log(value)
//     setYearsSelected(value)

// }


const getYearList = (type, yearSelected) => {
    const d = new Date();
    let date = d.getDate()
    let month = d.getMonth()
    let currentYear = d.getFullYear();
    if (type == 'currentYear') {
        return currentYear
    }
    else if (type == 'yearList') {
        const list = [];
        for (let i = 2018; i <= currentYear; i++) {
            list.push(i)
        }
        return list;
    }
    else if (type == 'startDate') {
        if (yearSelected == '' || yearSelected == undefined || yearSelected == null) {
            return ''
        } else {
            let sDate = `01-01-${yearSelected}`
            return sDate
        }
    }

    else if (type == 'endDate') {
        if (yearSelected == '' || yearSelected == undefined || yearSelected == null) {
        } else {
            if (yearSelected == currentYear) {
                let newDate = (date).toString()
                let newMonth = (Number(month) + 1).toString()
                let eDate = `${newDate.padStart(2, "0")}-${newMonth.padStart(2, "0")}-${yearSelected}`
                return eDate
            } else {
                let eDate = `31-12-${yearSelected}`
                return eDate
            }

        }

    }

}


const paymentPopupfilters = (type) => {
    const d = new Date();
    let date = d.getDate().toString()
    let month = (Number(d.getMonth()) + 1).toString()
    let year = d.getFullYear()

    const lastWeek = new Date(d);
    lastWeek.setDate(d.getDate() - 7);

    const lastWeekDate = `${year}-${(Number(lastWeek.getMonth()) + 1).toString().padStart(2, "0")}-${lastWeek.getDate().toString().padStart(2, "0")}`;
    const todaysDate = `${year}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`;
    if (type == 'from') {
        return lastWeekDate
    } else if (type == 'to') {
        return todaysDate
    }
}

export default function PaymentsCollected({ nbfcList }) {

    const Piecolors = {
        "180+ days": '#223d64',
        "151-180 days": '#f24839',
        "121-150 days": '#267dff',
        "91-120 days": '#a05195',
        "61-90 days": '#d45087',
        "31-60 days": '#f95d6a',
        "0-30 days": '#ff7c43',
    };
    const [loader, setLoader] = useState(false)
    const [loanTrendsChartType, setLoanTrendsChartType] = useState(['notReceived', 'expected', 'received', 'foreClosed', 'totalPaymentsReceived']);
    const [yearList, setYearList] = useState(getYearList('yearList'));
    const [selectedYear, setSelectedYear] = useState(getYearList('currentYear'));
    const [partner, setPartner] = useState('');
    const [product, setProduct] = useState('');
    const [rangeDpd, setRangeDpd] = useState('');
    const [nbfcFilter, setnbfcFilter] = useState('')
    const [startDate, setStartDate] = useState(getYearList('startDate', selectedYear))
    const [endDate, setEndDate] = useState(getYearList('endDate', selectedYear))
    const [tabsData, setTabsData] = useState({})
    const [paymentBreakdDownChartData, setpaymentBreakdDownChartData] = useState([])
    const [paymentData, setPaymentsData] = useState([])
    const [tablePopupData, setTablePopupData] = useState([]);
    const [tablePopup, setTablePopup] = useState(false);
    const [invoicedPaidData, setInvoicedPaidData] = useState([])
    const [paymentCollectedFilter, setPaymentCollectedFilter] = useState({
        fromDate: paymentPopupfilters('from'),
        toDate: paymentPopupfilters('to'),
        text: 'Payment Received in Last 7 Days'
    })
    const [paymentByDPDData, setpaymentByDPDData] = useState([])
    const [activeStatuses, setActiveStatuses] = useState([]);
    const [filterColorData, setFiltrColorData] = useState([])

    const toggleStatus = (status) => {
        setActiveStatuses(prevStatuses =>
            prevStatuses.includes(status)
                ? prevStatuses.filter(item => item !== status)
                : [...prevStatuses, status]
        );
    };

    useEffect(() => {
        filterPieData();
    }, [activeStatuses]);

    const filterPieData = () => {
        setFiltrColorData(paymentByDPDData.filter(entry => activeStatuses.includes(entry.interval)))
    }


    const applyFilterbtn = () => {
        APIFunction()
    }

    const handlechange = (e, type) => {
        if (type == 'partner') {
            setPartner(e.target.value);
        }
        else if (type == 'product') {
            setProduct(e.target.value);
        }
        else if (type == 'rangedpd') {
            setRangeDpd(e.target.value);
        }
        else if (type == 'nbfc') {
            setnbfcFilter(e.target.value);
        } else if (type == 'year') {
            setSelectedYear(e.target.value)
            setStartDate(getYearList('startDate', e.target.value))
            setEndDate(getYearList('endDate', e.target.value))

        }
    }

    const handlechartValues = (e, type) => {
        if (loanTrendsChartType?.includes(type)) {
            setLoanTrendsChartType(loanTrendsChartType.filter(item => item !== type));
        } else {
            setLoanTrendsChartType([...loanTrendsChartType, type]);
        }
    }

    const tabsDataFunction = (value) => {
        if (value == '' || value == undefined || value == null) {
            return '0'
        } else {
            let formattedValue = Number(value).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formattedValue;
        }
    }

    const handlePopup = () => {
        getPaymentReceivedByDateFunction()
        setTablePopup(true)
    }
    const closePopup = () => {
        setTablePopup(false)
        setTablePopupData([])
    }

    useEffect(() => {
        APIFunction()
    }, [])

    const APIFunction = () => {
        getPaymentStatsFunction()
        getPaymentSModesBreakDownFunction()
        getInvoicedPaidAmountMonthlyFunction()
        getPaymentReceivedByDPDFunction()
    }

    const getPaymentStatsFunction = () => {
        getPaymentStatsAPI(startDate, endDate, nbfcFilter, product, (callback) => {
            if (callback && callback?.status == 200) {
                setTabsData(callback?.data)
            } else {
                setTabsData({})
            }
        })
    }
    const getPaymentSModesBreakDownFunction = () => {
        getPaymentSModesBreakDownAPI(startDate, endDate, nbfcFilter, product, (callback) => {
            if (callback && callback?.status == 200) {
                setpaymentBreakdDownChartData(callback?.data)
            } else {
                setpaymentBreakdDownChartData([])
            }
        })
    }

    const getInvoicedPaidAmountMonthlyFunction = () => {
        setLoader(true)
        getInvoicedPaidAmountMonthlyAPI(startDate, endDate, nbfcFilter, product, (callback) => {
            setLoader(false)
            if (callback && callback?.status == 200) {
                setInvoicedPaidData(callback?.data)
            } else {
                setInvoicedPaidData([])
            }
        })
    }

    const getPaymentReceivedByDPDFunction = () => {
        getPaymentReceivedByDPDAPI(startDate, endDate, nbfcFilter, product, (callback) => {
            if (callback && callback?.status == 200 && callback?.data?.length > 0) {
                const filteredDataWithColors = callback?.data?.map(entry => ({
                    ...entry,
                    color: Piecolors[entry.interval] || '#000000'
                }));
                setpaymentByDPDData(filteredDataWithColors)
                const loanStatuses = filteredDataWithColors.map(entry => entry.interval);
                setActiveStatuses(loanStatuses)
            } else {
                setpaymentByDPDData([])
            }
        })
    }

    const getPaymentReceivedByDateFunction = () => {

        const fromDate = moment(paymentCollectedFilter.fromDate);
        const toDate = moment(paymentCollectedFilter.toDate);
        const diffInDays = toDate.diff(fromDate, 'days');
        let text = `Payment Received in Last ${diffInDays} Days`;
        setPaymentCollectedFilter(prevState => ({
            ...prevState,
            text: text
        }));

        const formattedFromDate = moment(paymentCollectedFilter.fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(paymentCollectedFilter.toDate).format('DD-MM-YYYY');
        setLoader(true)
        getPaymentReceivedByDateAPI(formattedFromDate, formattedToDate, nbfcFilter, product, (callback) => {
            setLoader(false)
            setTablePopupData([])
            if (callback && callback?.status == 200) {
                setPaymentsData(callback?.data)
                setTablePopupData(callback?.data)
            } else {
                setPaymentsData([])
                setTablePopupData([])
            }
        })
    }

    const paymentcollectedFilterFunction = (e) => {
        const { name, value } = e.target;

        setPaymentCollectedFilter({
            ...paymentCollectedFilter,
            [name]: value
        })
    }

    return (
        <>
            <div className="PaymentsCollected">
                {
                    loader ?
                        <Loader />
                        : ''
                }
                <div className="filters" style={{ margin: '0px' }}>
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Year</label>
                            <select className='selectBox2' id="nbfc" name="nbfc" value={selectedYear} onChange={(e) => handlechange(e, 'year')} style={{ width: '100%' }} >
                                {
                                    yearList && yearList?.length > 0 ? yearList?.map((value, index) => {
                                        return (
                                            <option key={index} value={value}>{value}</option>
                                        )
                                    })
                                        : ''}
                            </select>
                            {/* <CustomMultiSelectCheckbox OptionValues={yearValue} onChange={handleyearcheckbox} selectedValues={yearsSelected} /> */}
                        </div>
                        {/* <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Partner</label>
                            <select className='selectBox2' value={partner} onChange={(e) => handlechange(e, 'partner')}>
                                <option value="All">All</option>
                            </select>
                        </div> */}
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Product</label>
                            <select className='selectBox2' value={product} onChange={(e) => handlechange(e, 'product')} style={{ width: '100%' }}>
                                <option value="">All</option>
                            </select>
                        </div>
                        {/* <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <label className='labeltag'>Range_DPD</label>
                            <select className='selectBox2' value={rangeDpd} onChange={(e) => handlechange(e, 'rangedpd')}  style={{ width: '100%' }}>
                                <option value="">All</option>
                            </select>
                        </div> */}
                        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='selectBox2' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e) => handlechange(e, 'nbfc')} style={{ width: '100%' }} >
                                    <option value="">All</option>
                                    {
                                        nbfcList && nbfcList?.length > 0 ? nbfcList?.map((value, index) => {
                                            return (
                                                <option key={index} value={value?.nbfcId}>{value?.name}</option>
                                            )
                                        })
                                            : ''}
                                </select>
                            </div>
                        </div>
                        <div className=" col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <button className='ApplyFilter' onClick={() => applyFilterbtn()} style={{ marginTop: window.innerWidth > 1800 ? '42px' : '36px' }}>Apply Filter</button>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-sm-4 col-xs-12" style={{ float: window.innerWidth > 576 ? 'right' : 'none', textAlign: 'right', marginTop: '15px' }}>
                            <button className='FilterApply' style={{ backgroundColor: '#223d64', color: '#fff', border: '1px solid #223d64', marginTop: '23px', padding: '10px', borderRadius: '5px' }} onClick={() => handlePopup()}><i class="fa fa-eye" aria-hidden="true"></i> Payments Received In Last 7 Days</button>
                        </div>

                    </div>
                </div>

                <div className="toptabs" style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Total Principal</h6>
                                <h4>{tabsDataFunction(tabsData?.principal) || 0}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Total Balance</h6>
                                <h4>{tabsDataFunction(tabsData?.balance) || 0}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Total Interest</h6>
                                <h4>{tabsDataFunction(tabsData?.interest) || 0}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Total Bounce</h6>
                                <h4>{tabsDataFunction(tabsData?.bounce) || 0}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Total Penalty</h6>
                                <h4>{tabsDataFunction(tabsData?.penalty) || 0}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className='subtabs'>
                                <h6>Total Payments Collected</h6>
                                <h4>{tabsDataFunction(tabsData?.paymentCollected) || 0}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="graphsSection">
                    <div className="row">
                        <div className=" col-lg-6 col-xs-12 newPadding newmargin20">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Payments Collected By DPD</h5>
                                </div>
                                <div className="chart" style={{ height: window.innerWidth > 1899 ? '315px' : '298px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart
                                            width={150}
                                            height={150}
                                            margin={{
                                                top: 5, bottom: 20,
                                            }}
                                        >
                                            <Pie data={filterColorData} dataKey="amount" cx="50%" cy="50%" outerRadius={window.innerWidth > 1800 ? 120 : 90} innerRadius={window.innerWidth > 1800 ? 70 : 50} cornerRadius={5} fill="#82ca9d"
                                            // label={({ value, name, ...otherData }) => <CustomPieLabel value={value} name={name} otherData={otherData} />}
                                            >
                                                {filterColorData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                                ))}
                                            </Pie>
                                            <Tooltip content={<CustomPieTooltip />} />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="customLegend">
                                    {paymentByDPDData && paymentByDPDData.length > 0 ? paymentByDPDData.map((value, index) => (
                                        <div className="box" key={index} onClick={() => toggleStatus(value.interval)} style={{ cursor: 'pointer' }}>
                                            <div className="shape" style={{ backgroundColor: value.color }}></div>
                                            <h6 style={{
                                                textDecoration: activeStatuses.includes(value.interval) ? 'none' : 'line-through',
                                                color: activeStatuses.includes(value.interval) ? '#000' : '#999'
                                            }}>{value.interval}</h6>
                                        </div>
                                    )) : ''}
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 col-xs-12  newPadding newmargin20">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Mode-Wise Payment Breakdown</h5>
                                </div>
                                <div className="chart" style={{ height: '331px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={560}
                                            height={300}
                                            data={paymentBreakdDownChartData}
                                            barSize={15}
                                            layout="vertical"
                                            margin={{
                                                top: 20, right: 60, left: 20, bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray={3} horizontal={false} />
                                            <XAxis type="number" axisLine={false} />
                                            <YAxis type="category" dataKey="mode" axisLine={false} />
                                            <Tooltip />
                                            <Bar type="monotone" dataKey="count" fill="#3b68a9" label={{ position: 'right' }} radius={[0, 5, 5, 0]} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className=" col-md-6 col-xs-12 newPadding newmargin20">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Balance, Principal & Recovery Rate By Partner</h5>
                                </div>
                                <div className="customLegend" style={{margin:'20px 0px'}}>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#223D64' }}></div>
                                        <h6>Balance</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#267dff' }}></div>
                                        <h6>Principal</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                        <h6>Recovery Rate</h6>
                                    </div>
                                </div>
                                <div className="chart" style={{ height: '322px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={300}
                                            data={BalancePrincipalRecoveryRateByPartner}
                                            barSize={15}
                                            margin={{
                                                top: 20,
                                                right: -5,
                                                left: -5,
                                                bottom: 2,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis yAxisId="left" orientation="left" axisLine={false} />
                                            <YAxis yAxisId="right" orientation="right" axisLine={false} />
                                            <Tooltip />
                                            <Bar yAxisId="left" dataKey="Balance" fill="#223D64" radius={[5,5,0,0]}/>
                                            <Bar yAxisId="right" dataKey="Principal" fill="#267dff" radius={[5,5,0,0]}/>
                                            <Scatter yAxisId="left" dataKey="RecoveryRate" fill="#f24839" strokeWidth={2} line shape="dot" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className=" col-md-6 col-xs-12 newPadding newmargin20 ">
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Balance, Principal & Recovery Rate By Product</h5>
                                </div>
                                <div className="customLegend" style={{margin:'20px 0px'}}>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#223D64' }}></div>
                                        <h6>Balance</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#267dff' }}></div>
                                        <h6>Principal</h6>
                                    </div>
                                    <div className="box">
                                        <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                        <h6>Recovery Rate</h6>
                                    </div>
                                </div>
                                <div className="chart" style={{ height: '322px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={300}
                                            data={BalancePrincipalRecoveryRateByPartner}
                                            barSize={15}
                                            margin={{
                                                top: 20,
                                                right: -5,
                                                left: -5,
                                                bottom: 2,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis yAxisId="left" orientation="left" axisLine={false} />
                                            <YAxis yAxisId="right" orientation="right" axisLine={false} />
                                            <Tooltip />
                                            <Bar yAxisId="left" dataKey="Balance" fill="#223D64" radius={[5,5,0,0]}/>
                                            <Bar yAxisId="right" dataKey="Principal" fill="#267dff" radius={[5,5,0,0]}/>
                                            <Scatter yAxisId="left" dataKey="RecoveryRate" fill="#f24839" strokeWidth={2} line shape="dot" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ padding: '0px 15px', marginTop: '40px' }}>
                            <div className="graphbox">
                                <div className="heading">
                                    <h5>Payment Collected & Balance</h5>
                                </div>

                                <div className="chart" style={{ height: '322px' }}>
                                    {/* <div className="chart" style={{ height: yearsSelected?.length <= 1 ? '322px' : '94px' }}> */}
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ScatterChart
                                            width={560}
                                            height={300}
                                            data={invoicedPaidData}
                                            margin={{
                                                top: 30,
                                                right: 0,
                                                bottom: 20,
                                                left: 50,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" axisLine={false} />
                                            <YAxis axisLine={false} />
                                            <Tooltip content={<ScatterCustomTooltip  loanTrendsChartType={loanTrendsChartType}/>} />
                                            {
                                                loanTrendsChartType?.includes('notReceived') ?
                                                    <Scatter type="monotone" dataKey="notReceived" fill="#305fa2" line />
                                                    : ''}
                                            {
                                                loanTrendsChartType?.includes('expected') ?
                                                    <Scatter type="monotone" dataKey="expected" fill="#f24839" line />
                                                    : ''}
                                            {
                                                loanTrendsChartType?.includes('received') ?
                                                    <Scatter type="monotone" dataKey="received" fill="#003f5c" line />
                                                    : ''}
                                            {
                                                loanTrendsChartType?.includes('foreClosed') ?
                                                    <Scatter type="monotone" dataKey="foreClosed" fill="#bc5090" line />
                                                    : ''}
                                            {
                                                loanTrendsChartType?.includes('totalPaymentsReceived') ?
                                                    <Scatter type="monotone" dataKey="totalPaymentsReceived" fill="#ffa600" line />
                                                    : ''}
                                        </ScatterChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className="customLegend" style={{ margin: '20px 0px' }}>
                                    <div className="box" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('notReceived') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'notReceived')}>
                                        <div className="shape" style={{ backgroundColor: '#305fa2' }}></div>
                                        <h6>Not Received</h6>
                                    </div>
                                    <div className="box" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('expected') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'expected')}>
                                        <div className="shape" style={{ backgroundColor: '#f24839' }}></div>
                                        <h6>Expected</h6>
                                    </div>
                                    <div className="box" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('received') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'received')}>
                                        <div className="shape" style={{ backgroundColor: '#003f5c' }}></div>
                                        <h6>Received</h6>
                                    </div>
                                    <div className="box" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('foreClosed') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'foreClosed')}>
                                        <div className="shape" style={{ backgroundColor: '#bc5090' }}></div>
                                        <h6>ForeClosed</h6>
                                    </div>
                                    <div className="box" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('totalPaymentsReceived') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'totalPaymentsReceived')}>
                                        <div className="shape" style={{ backgroundColor: '#ffa600' }}></div>
                                        <h6>Total Payments Received</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className=" col-md-6 col-xs-12 newPadding newmargin20">
                            <div className="graphbox" style={{ height: '434px',padding:'0px' }}>
                                <div className="tables" style={{marginTop:'6px'}}>
                                    {
                                        tableData?.length > 0 ?
                                            <div className="phocket-table-new newTable" style={{ height:'auto',maxHeight: '428px', overflowX: 'hidden' }}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>Partner</th>
                                                            <th style={{ textAlign: 'center' }}>Payment Collected</th>
                                                            <th style={{ textAlign: 'center' }}>Recovery Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((data, index) => (
                                                            <tr key={index}>
                                                                <td style={{ textAlign: 'center' }}>{data.partner}</td>
                                                                <td style={{ textAlign: 'center' }}>{data.PaymentCollected}</td>
                                                                <td style={{ textAlign: 'center' }}>{data.RecoveryRate + '%'}</td>
                                                            </tr>
                                                        ))}
                                                       
                                                    </tbody>
                                                    <thead style={{bottom:'0'}}>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>Total</th>
                                                            <th style={{ textAlign: 'center' }}>34567890</th>
                                                            <th style={{ textAlign: 'center' }}>0.6%
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            : ''}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {
                    tablePopup ?
                        <PaymentPopup tablePopupData={tablePopupData} close={closePopup} tabName={'PaymentsCollected'} paymentcollectedFilterFunction={paymentcollectedFilterFunction} paymentCollectedFilter={paymentCollectedFilter} APIFunction={getPaymentReceivedByDateFunction} />
                        : ''}
            </div>
        </>
    )
}

const CustomPieLabel = ({ value, name, otherData }) => {
    const RADIAN = Math.PI / 180;
    const radius = otherData.innerRadius + (otherData.outerRadius - otherData.innerRadius) * 0.5;
    const newx = otherData.cx + radius * Math.cos(-otherData.midAngle * RADIAN);
    const newy = otherData.cy + radius * Math.sin(-otherData.midAngle * RADIAN);

    const labelRadius = otherData.outerRadius * 1.2;
    const labelX = otherData.cx + labelRadius * Math.cos(-otherData.midAngle * RADIAN);
    const labelY = otherData.cy + labelRadius * Math.sin(-otherData.midAngle * RADIAN);

    const horizontalLabelX = otherData.cx + (labelRadius + 20) * Math.cos(-otherData.midAngle * RADIAN);
    const horizontalLabelY = labelY;

    const isLeftSide = labelX < otherData.cx;
    return (
        <>
            <path
                d={`M${newx},${newy} L${labelX},${labelY} L${horizontalLabelX},${horizontalLabelY}`}
                stroke="#000"
                fill="none"
            />
            <text
                x={isLeftSide ? horizontalLabelX - 10 : horizontalLabelX + 10}
                y={horizontalLabelY}
                fill="#000"
                textAnchor={isLeftSide ? 'end' : 'start'}
                dominantBaseline="central"
                style={{ fontSize: '16px' }}
            >
                {value}
            </text>
        </>
    );
};

const CustomMultiSelectCheckbox = ({ OptionValues, onChange, selectedValues }) => {
    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 6;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        if (onChange) {
            onChange(value);
        }
    };

    const generateLabel = () => {
        if (selectedValues.length > 0) {
            const selectedStatuses = OptionValues.filter(optionvalue => selectedValues.includes(optionvalue)).map(optionvalue => optionvalue);
            return selectedStatuses.join(', ');
        }
        return 'Select Year';
    };
    return (
        <>
            <FormControl sx={{ width: '-webkit-fill-available', backgroundColor: '#DBE9FF', borderRadius: '6px', height: '40px' }}>
                <label style={{ display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '400', position: 'absolute', zIndex: '1', top: '4%', left: '2%', width: '80%', height: '35px', overflowY: 'hidden', backgroundColor: '#DBE9FF', borderRadius: '6px' }}>{generateLabel()}</label>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedValues}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    style={{ height: '41.5px', backgroundColor: '#DBE9FF' }}
                >


                    {OptionValues.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedValues.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}


const ScatterCustomTooltip = ({ active, payload, label,loanTrendsChartType }) => {
    if (active && payload && payload.length) {
        console.log("payload",payload)
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#223D64', border: '0px', padding: '10px' }}>
                <p style={{ color: '#fff', textAlign: 'center', marginBottom: '24px', fontWeight: '600' }}>{payload[0].value}</p>
                {Object.entries(payload[0].payload).map(([keys, values], index) => {
                    if (keys == 'name' || !loanTrendsChartType.includes(keys)) {
                        return null
                    }
                    return (
                        <p key={`item-${index}`} style={{ color: '#fff' }}>
                            {`${keys}: ${values}`}
                        </p>
                    )
                })}

            </div>
        );
    }
    return null;
};

const CustomPieTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        console.log("payload", payload)
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#223D64', border: '0px', padding: '10px' }}>
                <p style={{ color: '#fff', textAlign: 'center', marginBottom: '24px', fontWeight: '600' }}>{payload[0]?.payload?.payload?.interval || ''}</p>

                <p style={{ color: '#fff' }}>
                    {`EMICount: ${payload[0]?.payload?.payload?.emiCount || 0}`}
                </p>
                <p style={{ color: '#fff' }}>
                    {`Amount: ${payload[0]?.payload?.payload?.amount || 0}`}
                </p>


            </div>
        );
    }
    return null;
};