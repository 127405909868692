import React, { useState, useEffect } from "react";
import AgregatorDetails from "./agregatorDetails";
import Agreegatordashboard from "./agreegatordashboard";
import LeadBreakDown from "./leadBreakDown";
import PushLeadList from "./pushLeadList";
import AppliedLeadList from "./appliedLeadList";
import DisbursedLeadList from "./disbursedLeadList";
import Back from "../../maketingimages/back.png";
import BulkCommunicationModal from "./modals/bulkCommunicationModal";
import { getEventDataByPartnerId } from "../actioncreator";
// import { getEventDataByPartnerId } from "../actioncreator";
import { aggregatorDetailsTabName as tabName } from "../constans";
import Datainage from '../../newdesigns/imagesmarket/Vector (31).png'

function AggregatorDetails({ aggregatorId, setDetailShow, admin, listData }) {
  const [tab, setTab] = useState(tabName.Aggregator_Data);
  const [showBulkCommunication, setShowBulkcommunication] = useState(false);
  const [showBulkCommunicationEvent, setShowBulkcommunicationEvent] = useState(
    {}
  );
  const [showAllLeads, setShowAllLeads] = useState(false);
  const [aggregator, setAggregator] = useState({});
  const handleChangeTab = (tabName) => {
    setTab(tabName);
  };
  const showBulkCommunicationAction = (item, show) => {
    setShowBulkcommunicationEvent(item);
    setShowBulkcommunication(show);
  };

  useEffect(() => {
    // aggregatorId,
    getEventDataByPartnerId(
      aggregatorId,
      (callBack) => {
        console.log(callBack);
        setAggregator(callBack);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      {/* {showBulkCommunication && (
        <BulkCommunicationModal
          showBulkCommunicationLeadName={tab}
          setShowBulkcommunication={setShowBulkcommunication}
          showBulkCommunicationEvent={showBulkCommunicationEvent}
          aggregatorId={aggregator.partnerId}
        />
      )} */}

      <div className="row"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   width: "94%",
      //   margin: "auto",
      //   zIndex: 1,
      // }}
      >
        {/* fixed header */}
        <div className='' style={{ background: '#006c67', height: '100%', width: '5%', minWidth: '100px', position: 'fixed',paddingTop:'20px' }}>

          {/* <h3 className="aggregatorHeader">Aggregator Details</h3> */}
          {!showAllLeads && (
            <div className="tabsContainer">

              <div
                className="aggDetailsTab"
                style={{ color: '#fff' }}
              >

                <a
                  className="tabText"
                  style={{ color: '#fff' }}
                  onClick={() => handleChangeTab(tabName.Aggregator_Data)}
                >
                  <div className={tab === tabName.Aggregator_Data ? 'active-box' : 'inactive-box'}>
                    <i class="fa fa-database" aria-hidden="true"></i>
                  </div>
                  Aggregator<br /> Data
                </a>
              </div>
              <div
                className="aggDetailsTab"
                style={{ color: '#fff' }}
              >
                <a
                  className="tabText"
                  style={{ color: '#fff' }}
                  onClick={() => handleChangeTab(tabName.Aggregator_Dashboard)}
                >
                   <div className={tab === tabName.Aggregator_Dashboard ? 'active-box' : 'inactive-box'}>
                    <i class="fa fa-th-large" aria-hidden="true"></i>
                  </div>
                  Aggregator<br /> Dashboard
                </a>
              </div>
              {listData && listData.creatorMode && listData.creatorMode === 'Branch' ?
                <div
                  className="aggDetailsTab"
                  style={{ color: '#fff' }}
                >
                  <a
                    className="tabText"
                    style={{ color: '#fff' }}
                    onClick={() => handleChangeTab(tabName.Lead_Breakdown)}
                  >
                     <div className={tab === tabName.Lead_Breakdown ? 'active-box' : 'inactive-box'}>
                    <i class="fa fa-list" aria-hidden="true"></i>
                  </div>
                    Lead<br /> Breakdown
                  </a>
                </div>
                : ""}
              {listData && listData.creatorMode && listData.creatorMode === 'API/ javaScript' ?

                <div
                  className="aggDetailsTab"
                  style={{ color: '#fff' }}
                >
                  <a
                    className="tabText"
                    style={{ color: '#fff' }}
                    onClick={() => handleChangeTab(tabName.Pushed_Lead)}
                  >
                     <div className={tab === tabName.Pushed_Lead ? 'active-box' : 'inactive-box'}>
                    <i class="fa fa-list" aria-hidden="true"></i>
                  </div>
                    Pushed<br /> Lead List
                  </a>
                </div>
                : ""}
              <div
                className="aggDetailsTab"
                style={{ color: '#fff' }}
              >
                <a
                  className="tabText"
                  style={{ color: '#fff' }}
                  onClick={() => handleChangeTab(tabName.Applied_Lead)}
                >
                   <div className={tab === tabName.Applied_Lead ? 'active-box' : 'inactive-box'}>
                    <i class="fa fa-list" aria-hidden="true"></i>
                  </div>
                  Applied <br />Lead List
                </a>
              </div>
              <div
                className="aggDetailsTab"
                style={{ color: '#fff' }}
              >
                <a
                  className="tabText"
                  style={{ color: '#fff' }}
                  onClick={() => handleChangeTab(tabName.Disbursed_Lead)}
                >
                   <div className={tab === tabName.Disbursed_Lead ? 'active-box' : 'inactive-box'}>
                    <i class="fa fa-list" aria-hidden="true"></i>
                  </div>
                  Disbursed <br /> Lead List
                </a>
              </div>
            </div>
          )}
        </div>

        {/* outlet tabs */}
        <div style={{ width: 'calc(95%-100px)', marginLeft: '100px' }}>
          {/* <div style={{ marginTop: "50px" ,cursor:'pointer'}}  onClick={() =>
                !showAllLeads ? setDetailShow('list') : setShowAllLeads(false)
              }> */}
            {/* <img
              className=""
             
              src={Back}
              width={37}
              height={37}
            /> */}
            <button style={{ marginTop: "50px" ,marginLeft:'20px',marginBottom:'20px'}} className="btn btn-primary"  onClick={() =>
                !showAllLeads ? setDetailShow('list') : setShowAllLeads(false)}
              >Back</button>
          {/* </div> */}
          {tab === tabName.Aggregator_Data ? (
            <AgregatorDetails
              admin={admin}
              aggregatorId={aggregatorId} aggregator={aggregator} />
          ) : tab === tabName.Aggregator_Dashboard ? (
            <Agreegatordashboard
              admin={admin} aggregator={aggregator} />
          ) : tab === tabName.Lead_Breakdown ? (
            <LeadBreakDown
              setShowBulkcommunication={showBulkCommunicationAction}
              admin={admin}
              aggregatorId={aggregatorId} aggregator={aggregator}
            />
          ) : tab === tabName.Pushed_Lead ? (
            <PushLeadList
              setShowBulkcommunication={showBulkCommunicationAction}
              setShowAllLeads={setShowAllLeads}
              showAllLeads={showAllLeads}
              aggregator={aggregator}
              showBulkCommunicationEvent={showBulkCommunicationEvent}
              showBulkCommunicationLeadName={tab}
              admin={admin}

            />
          ) : tab === tabName.Applied_Lead ? (
            <AppliedLeadList
              admin={admin}
              setShowBulkcommunication={showBulkCommunicationAction}
              setShowAllLeads={setShowAllLeads}
              showAllLeads={showAllLeads}
              aggregator={aggregator}
            />
          ) : tab === tabName.Disbursed_Lead ? (
            <DisbursedLeadList aggregator={aggregator} admin={admin} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default AggregatorDetails;
