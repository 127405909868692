import React, { useEffect, useState } from "react";
import { getAllExpense, deleteExpenseById } from "../targetactioncreator";
import ReactApexChart from 'react-apexcharts';
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Nodata from '../../../../../images/nodata.png'
import Loader from '../../../../presentationals/Loader/Loader.component';
import Popup from '../../../../presentationals/Popup/Popup.component';
import moment from 'moment';
import BarChart from './BarChart'
const ExpensesList = ({ editHandler,handleTotalExpense,applyFilter,fromDate,toDate,ActiveMonthFilter,applyFilterState }) => {
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
 
    const [expenseData, setExepensedata] = useState('')
    const [openAccordion, setOpenAccordion] = useState(null);


    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };


    useEffect(() => {
        if(applyFilter == true || ActiveMonthFilter !== ''){
            expenseList()
        }
    }, [applyFilter,ActiveMonthFilter])

    const expenseList = () => {
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getAllExpense(formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback && callback?.status == 200 ){
                setExepensedata(callback?.data?.expenseList)
                handleTotalExpense(callback?.data?.totalExpense || 0)
            }else{
                setExepensedata([])

            }
        })
        applyFilterState()
    }


    const tableDatacheck = (data) => {
        if (data == null || data == undefined || data == '') {
            return '-'
        } else {
            return data
        }
    }

    const handleDelete = (item) => {
        setLoader(true)
        deleteExpenseById(item.id, (callback) => {
            setLoader(false)
            if (callback?.status == 200) {
                expenseList()
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };


    const options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        xaxis: {
            categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
        yaxis: [
            {
                seriesName: 'Income',
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: "Income (thousand crores)",
                    style: {
                        color: '#008FFB',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Cashflow',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Operating Cashflow (thousand crores)",
                    style: {
                        color: '#00E396',
                    }
                },
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Revenue (thousand crores)",
                    style: {
                        color: '#FEB019',
                    }
                }
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    }





    return (
        // <div className="container-fluid Executor">
        //     <div
        //         className=""
        //         style={{
        //             background: '#f2f2f2',
        //             padding: '10px',
        //             marginTop: '50px',
        //             borderRadius: '5px',
        //             overflow: 'auto',
        //             // maxWidth: '1000px'
        //         }}
        //     >
        //         <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
        //             <thead>
        //                 <tr>
        //                     <th>Expense Amount</th>
        //                     <th>Added By</th>
        //                     <th>GST Amount</th>
        //                     <th>GST Inclusive</th>
        //                     <th>Verified</th>
        //                     <th>Verified By</th>
        //                     <th>Edit</th>
        //                     <th>Delete</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                  <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }} >
        //                     <td className='text-center'>{expenseData.expenseAmount}</td>
        //                     <td>{expenseData.addedBy}</td>
        //                     <td>{expenseData.gstAmount}</td>
        //                     <td style={{ textAlign: 'center' }}>
        //                         <input
        //                             type='checkbox'
        //                             checked={expenseData.isGSTInclusive}
        //                             readOnly
        //                             aria-label="GST Inclusive"
        //                         />
        //                     </td>
        //                     <td style={{ textAlign: 'center' }}>
        //                         <input
        //                             type='checkbox'
        //                             checked={expenseData.isVerified}
        //                             readOnly
        //                             aria-label="Verified"
        //                         />
        //                     </td>
        //                     <td>{expenseData.verifiedBy}</td>
        //                     <td>
        //                         <i
        //                             className="fa fa-pencil-square-o fa-lg"
        //                             aria-hidden="true"
        //                             onClick={() => editHandler(expenseData)}
        //                             style={{ cursor: 'pointer' }}
        //                             aria-label="Edit Expense"
        //                             title="Edit Expense"
        //                         ></i>
        //                     </td>
        //                     <td>
        //                         <i
        //                             className="fa fa-trash-o fa-lg"
        //                             aria-hidden="true"
        //                             onClick={() => handleDelete(expenseData)}
        //                             style={{ cursor: 'pointer' }}
        //                             aria-label="Delete Expense"
        //                             title="Delete Expense"
        //                         ></i>
        //                     </td>
        //                 </tr> 
        //             </tbody>
        //         </table>
        //     </div>
        // </div>


        <div className="projection-new">

            <div className="container-fluid Projection" style={{ padding: '20px 50px' }}>

                {loader ? <Loader /> : ''}
                {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
                <div className="accords openaccord" style={{ background: '#fff' }}>
                    <div className="">
                        {expenseData && expenseData?.length > 0 ? expenseData.map((item, index) => (
                            <AccordionItem
                                key={index}
                                id={index}
                                isOpen={openAccordion === index}
                                toggleAccordion={toggleAccordion}
                                item={item}
                                expenseData={expenseData}
                                tableDatacheck={tableDatacheck}
                                editHandler={editHandler}
                                handleDelete={handleDelete}
                                options={options}


                            />
                        )) :
                            <div className="text-center" style={{ marginTop: '20px', marginBottom: '20px' }}><img width={'30%'} src={Nodata} /></div>
                        }

                    </div>

                </div>
            </div>


        </div>
    );
};

export default ExpensesList;


function AccordionItem({ id, isOpen, toggleAccordion, expenseData, item, tableDatacheck, editHandler, handleDelete, options ,ActiveMonthFilter}) {
    const graphData = [
        {
            name:'Expense Amount',
            amount: item?.expenseAmount || 0
        },
        {
            name:'GST Amount',
            amount: item?.gstAmount || 0
        },
    ]
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            {console.log(expenseData, 'expenseDataexpenseData')}
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        {isOpen ? <ArrowUpward /> : <ArrowDownward />}
                    </h5>
                </div>
                <table className="table text-center tableheader">
                    <tbody>
                        {expenseData !== '' && expenseData.length > 0 ?
                            <tr>
                                <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>Category Name</td>
                                <td style={{ width: '35%' }}><b>{tableDatacheck(item.categoryName)}</b></td>
                                <td>
                                    <i
                                        className="fa fa-pencil-square-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => editHandler(item)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Edit Expense"
                                        title="Edit Expense"
                                    ></i>
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => handleDelete(item)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Delete Expense"
                                        title="Delete Expense"
                                    ></i>
                                </td>
                            </tr>

                            : ""}

                    </tbody>
                </table>
            </div>

            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`} style={{ overflow: 'hidden', border: 'none',padding:'0px 15px' }}>
                        <div className="row">
                            <div className="col-md-6 col-xs-12" style={{ padding: '10px 8px' }}>
                                <div className="tableedit" style={{ boxShadow: '3px 3px 9px 1px #c4c1c1', borderRadius: '6px', height: window.innerWidth > 1800 ? '430px' : '366px', padding: '10px' ,overflowY:'auto'}}>
                                    <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Sub-Category Name</th>
                                                    <td>{tableDatacheck(item.subCategoryName)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Spending Type</th>
                                                    <td>{tableDatacheck(item.spendingType)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Expense Amount</th>
                                                    <td>{tableDatacheck(item.expenseAmount)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Added By</th>
                                                    <td>{tableDatacheck(item.addedBy)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Expense Date</th>
                                                    <td>{tableDatacheck(item.expenseDate)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>GST Amount</th>
                                                    <td>{tableDatacheck(item.gstAmount)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>GST Inclusive</th>
                                                    <td>{item.gstinclusive ? 'Yes' : 'No'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Verified</th>
                                                    <td>{item.verified ? 'Yes' : 'No'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Verified By</th>
                                                    <td>{tableDatacheck(item.varifiedBy)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12" style={{ padding: '10px 8px' }}>
                                <div className="tableedit" style={{ boxShadow: '3px 3px 9px 1px #c4c1c1', borderRadius: '6px', height: window.innerWidth > 1800 ? '430px' : '366px' }}>
                                    {/* <div id="chart"> */}
                                        {/* <ReactApexChart options={options} series={item.graphData} type="line" height={350} /> */}
                                    {/* </div> */}
                                        <BarChart sectionType={'ExpenseList'} Data={graphData} ActiveMonthFilter={ActiveMonthFilter} Heading={''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>);
}
