import React, { useState, useEffect } from "react";

import Person from "../../maketingimages/person-square.png";
import Configuration from "../../maketingimages/Frame 30393.png";
import Organisation from "../../maketingimages/Organisati.png";
import Fox from "../../maketingimages/Fox.png";
import { getAggregatorPayout, getEventDataByPartnerId } from "../actioncreator";
const AgregatorDetails = ({ aggregator, aggregatorId }) => {
  const [aggregatorPayout, setAggregatorPayout] = useState({});
  const [payoutModel, setPayoutModel] = useState([]);

  const getAggregatorPayoutData = () => {
    getAggregatorPayout(
      aggregatorId,
      (callBack) => {
        setAggregatorPayout(callBack);

        if (callBack.cpa > 0 && payoutModel.indexOf("cpa") == -1) {
          payoutModel.push("cpa");
        }
        if (callBack.cpd > 0 && payoutModel.indexOf("cpd") == -1) {
          payoutModel.push("cpd");
        }
        if (callBack.cpl > 0 && payoutModel.indexOf("cpl") == -1) {
          payoutModel.push("cpl");
        }
        if (callBack.cpr > 0 && payoutModel.indexOf("cpr") == -1) {
          payoutModel.push("cpr");
        }

      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getAggregatorPayoutData();
  }, []);

  return (
    <>
     
      <div className="container-fluid details-new">
        <div className="row">
          <div className="col-sm-4 col-xs-12">
            <div className="detail-box">
              <div className="detail-box-head">
                <i class="fa fa-user" aria-hidden="true"></i> &nbsp;Basic Details
              </div>
              <div className="detail-body">
                <div className=''>
                  <div style={{ padding: '15px', background: '#F1F7FF' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Aggregator Name </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.companyName || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#fff' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Partner Name </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.partnerName || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#F1F7FF', }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>POC Email </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.email || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#fff', }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Password </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.password || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#F1F7FF', }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Aggregator Type </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.type || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#fff', }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Creator Mode </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.creatorMode || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#F1F7FF', }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>POC Name </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.contactPerson || ""}</span>
                  </div>
                  <div style={{ padding: '15px', background: '#fff' }}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>POC Mobile Number  </span>
                    <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.mobileNumber || ""}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-xs-12">
            <div className="detail-box">
              <div className="detail-box-head">
                <i class="fa fa-sliders" aria-hidden="true"></i> &nbsp;Configuration  Details

              </div>
              <div className="detail-body">
                <div className="detail-body">
                  <div className=''>
                    <div style={{ padding: '15px', background: '#F1F7FF' }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Payout Amount </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.paymentAmount || ""}</span>
                    </div>
                    <div style={{ padding: '15px', background: '#fff', }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Payout Model </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>

                      {payoutModel.map((item, index) => {
                      return (
                        <span key={index}>
                          {`${item}${index !== payoutModel.length - 1 ? ", " : ""
                            }`}
                        </span>
                      );
                    })}
                      </span>
                    </div>
                    <div style={{ padding: '15px', background: '#F1F7FF', }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Payout Cycle </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.payoutCycle || ""}</span>
                    </div>
                    
                    <div style={{ padding: '15px', background: '#fff' }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>POC Email   </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.email || ""}</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-xs-12">
            <div className="detail-box">
              <div className="detail-box-head">
                <i class="fa fa-briefcase" aria-hidden="true"></i> &nbsp;Organisation Details

              </div>
              <div className="detail-body">
                <div className="detail-body">
                  <div className=''>
                    <div style={{ padding: '15px', background: '#F1F7FF' }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Contact Number </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.organizationContactNo || ""}</span>
                    </div>
                    <div style={{ padding: '15px', background: '#fff', }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Status </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{(aggregator?.active ? "Active" : "InActive") || "InActive"}</span>
                    </div>
                    <div style={{ padding: '15px', background: '#F1F7FF', }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Website Link </span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}>{aggregator?.webAddress || ""}</span>
                    </div>
                    <div style={{ padding: '15px', background: '#fff', }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Logo</span>
                      <span style={{ float: 'right', fontWeight: '700', color: 'rgba(0, 0, 0, 0.80)' }}> <img src={aggregator.uploadLogoUrl} alt="" style={{maxWidth:'100px'}} /></span>
                    </div>
                  

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>



    </>
  );
};

export default AgregatorDetails;
