import React, { useEffect, useState } from "react";
import { getAllEmployeesAttendanceApi } from "../../targetactioncreator";

const AllAttendence = () => {
    const [allData, setAllData] = useState('');

    // Fetch data from API on component mount
    useEffect(() => {
        getAllEmployeesAttendanceApi(callback => {
            setAllData(callback.data);
        });
    }, []);

    return (
        <div className="container">
            {/* Check if there's data */}
            {allData && Object.keys(allData).map((month) => (
                <div key={month} className="month-table">
                    <h3>{month}</h3>
                    <div className="phocket-table-new">
                        <table>
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Present</th>
                                    <th>Absent</th>
                                    <th>Half Day</th>
                                    <th>Paid Leave</th>
                                    <th>Earn Leave</th>
                                    <th>Total Working Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allData[month].map((attendance) => (
                                    <tr key={attendance.id}>
                                        <td>{attendance.employeeName}</td>
                                        <td>{attendance.present}</td>
                                        <td>{attendance.noAbsent}</td>
                                        <td>{attendance.noHalfDay}</td>
                                        <td>{attendance.noPaidLeave}</td>
                                        <td>{attendance.noEarnLeave}</td>
                                        <td>{attendance.totalWorkingDays || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AllAttendence;
