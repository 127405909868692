import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Pagination } from '@mui/material';
import { getSingularDasboardDataAPI,getSingularDasboardReportAPI } from '../../actioncreator';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import Nodata from '../../../../../../images/nodata.png'
const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}

export default function SingularDashboard() {
    const itemsPerPage = 10;
    const [loader,setLoader] =useState(false)
    const [filterType, setFilterType] = useState('applicationNo')
    const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
    const [toDate, setToDate] = useState(InitalDate('toDate'))
    const [filterValue, setFilterValue] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([])
    const [totalData,setTotalData] =useState('')

    const filterHandler = (e) => {
        setFilterType(e.target.value)
    }

    const dataFilter = (e, type) => {
        if (type === 'filter') {
            setFilterValue(e.target.value)
        } else if (type === "fromDate") {
            setFromDate(e.target.value)
        } else if (type === "toDate") {
            setToDate(e.target.value)
        } else if (type === "apply") {
            setCurrentPage(1);
            APIFunction(fromDate, toDate,1)
        } else if (type === "clear") {
            setFilterValue('applicationNo')
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))
            setCurrentPage(1);
            APIFunction(InitalDate('fromDate'), InitalDate('toDate'),1)
        }
    }

    useEffect(() => {
        APIFunction(fromDate, toDate,currentPage)
    }, [])

    const APIFunction = (sDate, eDate,currentPageNo) => {
        const formattedFromDate = moment(sDate).format('DD-MM-YYYY');
        const formattedToDate = moment(eDate).format('DD-MM-YYYY');
        const obj = {
            pageNo: currentPageNo,
            size: itemsPerPage,
            sDate: formattedFromDate,
            eDate: formattedToDate,
            applicationId: filterType === 'applicationNo' ? filterValue : '',
            email: filterType === 'email' ? filterValue : '',
            partnerName: filterType === 'partnerName' ? filterValue : '',
        }
        setLoader(true)
        getSingularDasboardDataAPI(obj, (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.status == 200 && callback?.message == "success" && callback?.data) {
                setData(callback?.data?.data)
                setTotalData(callback?.data?.count)
            }else{
                setData([])
            }
        })
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
            APIFunction(fromDate, toDate,value)
    };


    const downloadFile =()=>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        const obj = {
            pageNo: currentPage,
            size: totalData,
            sDate: formattedFromDate,
            eDate: formattedToDate,
            applicationId: filterType === 'applicationNo' ? filterValue : '',
            email: filterType === 'email' ? filterValue : '',
            partnerName: filterType === 'partnerName' ? filterValue : '',
        }
        setLoader(true)
        getSingularDasboardReportAPI(obj,(callback)=>{
            setLoader(false)
            // console.log(callback)
        })
    }

    return (
        <>
            <div className="SingularDashboard">
                {
                    loader ? <Loader /> :''
                }
                <div className="container-fluid">
                    <div className="filterSection">
                        <div className="row">
                            <div className="col-sm-3 col-xs-12">
                                <label htmlFor="">Select Filter</label>
                                <span className="main-search" >
                                    <select className="form-control main-select" style={{ background: '#DBE9FF', fontWeight: '700' }} onChange={(e) => filterHandler(e)}>
                                        <option value="applicationNo">Application no</option>
                                        <option value="email">Email</option>
                                        <option value="partnerName">Partner Name</option>
                                    </select>
                                    <input type="text" value={filterValue} className="form-control inputTag" onChange={e => dataFilter(e, 'filter')} placeholder="Search.." />

                                </span>
                            </div>
                            <div className="col-sm-2 col-xs-12">
                                <label htmlFor="">From</label>
                                <input type="date" className="form-control" value={fromDate} style={{ marginTop: '0px' }} onChange={e => dataFilter(e, 'fromDate')} />
                            </div>
                            <div className="col-sm-2 col-xs-12">
                                <label htmlFor="">To</label>
                                <input type="date" className="form-control" value={toDate} style={{ marginTop: '0px' }} onChange={e => dataFilter(e, 'toDate')} />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <div className='filterbtns'>
                                    <button className="apply" style={{ marginTop: '24px' }} onClick={e => dataFilter(e, 'apply')}>Apply Filter</button>
                                    <button className="clear" style={{ marginTop: '24px' }} onClick={e => dataFilter(e, 'clear')}>Clear Filter</button>
                                </div>
                            </div>
                        </div>
                        {
                            data && data?.length > 0 ?
                            <div className="row">
                            <div className="col-sm-2 col-xs-12" style={{float:'right'}}>
                                <div className='filterbtns'>
                            <button className="apply" style={{ marginTop: '24px' }} onClick={e => downloadFile(e)}><i className='fa fa-download'></i>&nbsp;&nbsp;Download</button>
                                </div>
                            </div>
                        </div>
                        :''}
                    </div>

                    {
                        data && data?.length > 0 ?
                            <div>
                                <div className="overflow-x-auto phocket-table-new">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th className='text-center'>Partner Name</th>
                                                <th className='text-center'>Application Id</th>
                                                <th className='text-center'>Name</th>
                                                <th className='text-center'>Mobile Number</th>
                                                <th className='text-center'>Email Id</th>
                                                <th className='text-center'>Singular Event Name</th>
                                                <th className='text-center'>Approve Date</th>
                                                <th className='text-center'>Disburse Date</th>
                                                <th className='text-center'>Loan Status</th>
                                                <th className='text-center'>Reject Reason</th>
                                                <th className='text-center'>Customer Type</th>
                                                <th className='text-center'>Apply Date</th>
                                                <th className='text-center'>EventTimestamp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data?.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='text-center'>{value?.partnerName || '-'}</td>
                                                            <td className='text-center'>{value?.applicationId || '-'}</td>
                                                            <td className='text-center'>{value?.name || '-'}</td>
                                                            <td className='text-center'>{value?.mobileNumber || '-'}</td>
                                                            <td className='text-center'>{value?.emailId || '-'}</td>
                                                            <td className='text-center'>{value?.singularEventName || '-'}</td>
                                                            <td className='text-center'>{value?.approveDate || '-'}</td>
                                                            <td className='text-center'>{value?.disburseDate || '-'}</td>
                                                            <td className='text-center'>{value?.loanStatus || '-'}</td>
                                                            <td className='text-center'>{value?.rejectReason || '-'}</td>
                                                            <td className='text-center'>{value?.customerType || '-'}</td>
                                                            <td className='text-center'>{value?.applyDate || '-'}</td>
                                                            <td className='text-center'>{value?.eventTimestamp || '-'}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: window.innerWidth > 1850 ? '20px' : '10px' }}>
                                    <Pagination
                                        count={Math.ceil(totalData / itemsPerPage)}
                                        page={currentPage}
                                        onChange={(event, value) => handlePageChange(event, value)}
                                        color="success"
                                    />

                                </div>

                            </div>
                            : 
                            <div className="text-center" style={{ marginTop: '40px', marginBottom: '20px' }}><img width={'50%'} src={Nodata} /></div>
                        
                            }
                </div>
            </div>
        </>
    )
}
