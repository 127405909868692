import React,{useState} from 'react'
import Singular from './Singular'
import List from './List';
import SingularDashboard from './SingularDashboard';
export default function Main({setmargin}) {
    const [viewState,setViewState] =useState('SingularDashboard');

  return (
    <>
<div className="row fixed-top" style={{ marginTop: setmargin?'78px':'8px' }}>
                <div className="col-sm-4 col-xs-6" onClick={() => setViewState('SingularDashboard')} style={{ background: viewState === 'SingularDashboard' ? '#006c67' : '#DBE9FF', padding: viewState==='SingularDashboard'?'13px':'12.2px', textAlign: 'center', color: viewState === 'SingularDashboard' ? '#fff' : '#000', cursor: 'pointer' }}>
                Singular Dashboard

                </div>
                <div className="col-sm-4 col-xs-6" onClick={() => setViewState('list')} style={{background: viewState === 'list' ? '#006c67' : '#DBE9FF', padding: viewState==='list'?'13px':'12.2px', textAlign: 'center', color: viewState === 'list' ? '#fff' : '#000', cursor: 'pointer' }}>
                     Listing

                </div>
                <div className="col-sm-4 col-xs-6" onClick={() => setViewState('upload')} style={{ background: viewState === 'upload' ? '#006c67' : '#DBE9FF', padding: viewState==='upload'?'13px':'12.2px', textAlign: 'center', color: viewState === 'upload' ? '#fff' : '#000', cursor: 'pointer' }}>
                  Upload Logs

                </div>

            </div>
            
            <div className="full-height">
                {viewState === 'list' ?
                    <List />
                    : ""}
                {viewState === 'upload' ?
                    <Singular/>
                    : ""}
                {viewState === 'SingularDashboard' ?
                    <SingularDashboard/>
                    : ""}
            </div>
    </>
  )
}
