import React from "react";
import { MSG_STRING } from "../../../../../utils/Constant";
import { pushNotification } from "../../AdminActionCreator.component";
import Popup from '../../../../presentationals/Popup/Popup.component';

class MessageHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            templateId: '',
            popupState: false,
            popupStatus: '',
        }
    }
    messageHandler(msgType) {
        if (msgType == 'ExecutiveContact') {
            this.setState({ templateId: '1207161475779223316' })
            this.setState({ message: MSG_STRING.EXECUTIVE_CONTACT })
        } else if (msgType == 'DocsUpload') {
            this.setState({ templateId: '1207161475786136184' })
            this.setState({ message: MSG_STRING.DOC_MSG })
        } else if (msgType == 'razorpay') {
            this.setState({ templateId: '1207161475799205532' })
            this.setState({ message: MSG_STRING.RAZ_MSG })
        } else if (msgType==='') {
            this.setState({ templateId: '' })
            this.setState({ message: '' })
        }
    }
    
    sendHandler() {
        const { allDetail, admin } = this.props;
        if (this.state.message != '' && this.state.templateId !='') {
            pushNotification(allDetail, this.state.message.replace(/&/g, 'and'), '', admin.emailId, 'message', '', this.state.notificationImage, '', '',this.state.templateId,
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Message Sent Successfully',
                    })
                    this.removePopup();
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter message',
            })
            this.removePopup();
        }
    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    render() {
        return (
            <div className="container-fluid details-new">
                 {this.state.popupState == true ?

                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    :""}
                <div className="sms-box">
                    <select style={{ background: '#DBE9FF', color: '#000' }} className="form-control" onChange={(e) => this.messageHandler(e.target.value)}>
                        <option value={''}>Select Template</option>
                        <option value='ExecutiveContact'> Executive contact </option>
                        <option value='DocsUpload'>Document upload</option>
                        <option value='razorpay'>Bank not live with Razorpay's e-mandate</option>

                    </select>
                    {this.state.message !== '' ?
                        <textarea value={this.state.message} readOnly style={{ background: '#F1F7FF', color: '#000', borderRadius: '6px', border: 'none', marginTop: '20px', minHeight: '150px' }} className="form-control" />
                        : ""}
                    {this.state.message !== '' ?
                        <div>
                            <button onClick={()=>this.sendHandler()} className="btn btn-primary" style={{ marginTop: '20px' }}>Send</button>
                        </div>
                        : ''}
                </div>

            </div>
        )
    }
}
export default MessageHandler