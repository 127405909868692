import React, { useEffect, useState } from "react";
import { aadharVerificationDetails, aadharXmlDetails, getFraudDetailApiNew, panProfileDetails, saveUnderWriterApi } from "../AdminActionCreator.component";
import { getCreditAnalysisData, getCrifAnalysisStatus, getFinbitAnalysis, getNotes, getPersonalAnalysisReport, getSmsAnalysisStatus, saveCommentForCreditAssesment, similarProfileData } from "./Analysis/analysisactionCreator";
import { APIS } from "../../../../utils/api-factory";
import CrifNew from "./Analysis/crifNewPage";
import Image1Active from './images/placeholder Fill (23).png'
import Image2Active from './images/placeholder Fill (24).png'
import Image3Active from './images/placeholder Fill (25).png'
import Image4Active from './images/placeholder Fill (26).png'
import Image1InActive from './images/placeholder Fill (27).png'
import Image2InActive from './images/placeholder Fill (28).png'
import Image3InActive from './images/placeholder Fill (29).png'
import Image4InActive from './images/placeholder Fill (30).png'
import InfoImage from './images/placeholder Fill (19).png'
import InfoInactive from './images/placeholder Fill (20).png'
import moment from "moment";
import { getNotesnew } from "../Analysis/analysisactionCreator";
import PopUp from '../../../presentationals/Popup/Popup.component'


const COLORSPINK = ['#514C9F', '#D8D4FE'];
const ColorGreen = ['#95C90A', '#DFEFB6']
let paginationNumber = 10
let jsondata = {};

const Analysis = ({ crifDataApi, crifJson, allDetail, admin, userDetails, sendDataToMainPageCrif ,handletabs }) => {
    const [crifData, setCrifAnalysisData] = useState('')
    const [viewPage, setViewPage] = useState('detail')
    const [personalData, setpersonalData] = useState('')
    const [karzaData, setKarzadata] = useState({})
    const [aadhaarData, setAadhaarData] = useState({})
    const [aadhaarXmlData, setAadharXmlData] = useState({})
    const [chartDataState, setchartDataState] = useState([])
    const [chartDataStateLocation, setchartDataStateLocation] = useState([])
    const [kycState, setkycState] = useState(false)
    const [kycSubStatePan, setkycSubStatePan] = useState(false)
    const [kycSubStateAAdhaar, setkycSubStateAAdhaar] = useState(false)
    const [kycSubStateLocation, setkycSubStateLocation] = useState(false)
    const [internalState, setinternalState] = useState(false)
    const [matchedSimilarData, setmatchedSimilarData] = useState('')
    const [loaderState, setLoader] = useState(false)
    const [tableMinPagination, settableMinPagination] = useState(0)
    const [tableMaxPagination, settableMaxPagination] = useState(paginationNumber)
    const [filterData, setFilterData] = useState('')
    const [filterType, setFilterType] = useState('')
    const [fraudData, setFraudData] = useState('')
    const [allSimilarProfileData, setallSimilarProfileData] = useState('')
    const [smsAnalysis, setsmsAnalysis] = useState(false)
    const [smsData, setSmsData] = useState('')
    const [finbitData, setFinbitData] = useState('')
    const [financialTab, setfinancialTab] = useState('')
    const [finBitMainTab, setfinBitMainTab] = useState(false)
    const [crifMainTab, setcrifMainTab] = useState(false)
    const [analysisData, setanalysisData] = useState('')
    const [DateOfRequest, setDateOfRequest] = useState('')
    const [crifTab, setcrifTab] = useState('crifreport')
    const [userCrifData, setuserCrifData] = useState('')
    const [regenerate, setregenerate] = useState(0)
    const [smsTab, setSmsTab] = useState('')
    const [secondTab, setSecondT] = useState('')
    const [crifLoaderData, setcrifLoaderData] = useState(false)
    const [, updateState] = React.useState();
    const [msgData, setMessageData] = useState('')
    const [loadarState, setloadarState] = useState(false)
    const [parseData, setparseData] = useState('')
    const [sortedData, setSortedData] = useState([])
    const [repeatUserData, setrepeatUserData] = useState('')
    const [underWriterStatus, setunderWriterStatus] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [sidePoupState, setsidePoupState] = useState('')
    const [newNotes, setnewNotes] = useState('')
    const [notesType, setNotesType] = useState('')
    const [notesPopup, setNotesPopup] = useState(false)
    const [crifdatapopup, setcrifdatapopup] = useState(false)
    const [notesText, setNotesText] = useState('')
    const [zoomState, setzoomState] = useState(false)
    const [crifprevdata, setcrifprevdata] = useState('')
    const [rawReportData, setRawReport] = useState(false)
    const [regenerateButton, setregenerateButton] = useState(true)
    const [crifDataDashboard, setCrifData] = useState('')
    const [crifButtonFromApi, setCrifShowButton] = useState(false)
    const [originalCrifReport, setOriginalReport] = useState('')
    const [showOrignal, setshowOrignal] = useState(false)

    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [htmlData, setHtmlData] = useState('')

    const crfJsonData = (data, value) => {
        if (jsondata.hasOwnProperty(data)) {
            const index = jsondata[data].indexOf(value);
            if (index !== -1) {
                jsondata[data].splice(index, 1);
            } else {
                jsondata[data].push(value);
            }
        } else {
            jsondata[data] = [value];
        }

        sendDataToMainPageCrif(jsondata)

    };
    const saveUnderWritter = (type) => {
        let decisionType = 'crifDecision'
        if (underWriterStatus !== '' && decisionType !== '') {
            saveUnderWriterApi(decisionType, allDetail, underWriterStatus, admin, callBack => {

                if (callBack === 'success') {
                    setunderWriterStatus('')
                    setpopupStatus('successfully saved !')
                    setsidePoupState(true)
                    removePopup()

                } else {
                    setpopupStatus('please try again later !')
                    setsidePoupState(true)
                    removePopup()
                }
            })
        } else {
            setpopupStatus('Please select value !')
            setsidePoupState(true)
            removePopup()
        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setsidePoupState(false)
        }, 5000);
    }
    const underWriterHandler = (e) => {
        setunderWriterStatus(e.target.value)
    }
    const closepopupSide = () => {
        setsidePoupState(false)

    }
    const openCommentPopup = (data) => {
        setNotesPopup(true)
        setNotesType(data)
        getNotesnew(allDetail.loanId, allDetail.userId, data, callBack => {
            setnewNotes(callBack)
        })

    }

    const closepopup = () => {
        setNotesPopup(false)
        setNotesText('')
        setnewNotes('')
    }
    const sortByDisbursedDate = (data) => {
        try {
            let dataArray = Array.isArray(data) ? data : [data];

            setSortedData(dataArray.sort((a, b) => {
                const dateAValue = a["LOAN-DETAILS"]["DISBURSED-DATE"];
                const dateBValue = b["LOAN-DETAILS"]["DISBURSED-DATE"];

                const dateA = dateAValue ? new Date(dateAValue.split('-').reverse().join('-')) : null;
                const dateB = dateBValue ? new Date(dateBValue.split('-').reverse().join('-')) : null;

                if (a["LOAN-DETAILS"]["ACCOUNT-STATUS"] === "Active" && b["LOAN-DETAILS"]["ACCOUNT-STATUS"] !== "Active") {
                    return -1;
                } else if (a["LOAN-DETAILS"]["ACCOUNT-STATUS"] !== "Active" && b["LOAN-DETAILS"]["ACCOUNT-STATUS"] === "Active") {
                    return 1;
                } else {
                    return dateB && dateA ? dateB - dateA : 0;
                }
            }))

        } catch (error) {
            console.error("Error sorting data:", error);
        }
    };

    const checkRepeatUser = () => {
        fetch(APIS.REPEAT_USER_CHECK + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                setrepeatUserData(json.repeatUser)
            })
    }
    const checkIsRegenerate = () => {
        setcrifLoaderData(true)
        fetch(APIS.REGENERATE_CRIF_CHECK + allDetail.userId + '&loanId=' + allDetail.loanId)
            .then(res => res.json())
            .then(json => {
                setcrifLoaderData(false)
                console.log(json)
                if (json.isCrifGenerated === true) {
                    setCrifShowButton(json.isCrifGenerated)
                } else {
                    setCrifShowButton(json.isCrifGenerated)
                }
            })
    }

    useEffect(() => {

        financialTabHandler('')
        checkIsRegenerate()
        jsondata = crifJson
    }, [])

    const personaDataApi = () => {
        getPersonalAnalysisReport(allDetail.userId, allDetail.loanId, callback => {
            let lt = parseFloat(callback.aadharAddressMatch)
            let data = [
                { name: '', value: Math.round(lt) },
                { name: '', value: (100 - Math.round(lt)) }
            ]
            setchartDataStateLocation(data)
            setpersonalData(callback)
        })
    }
    const makegraphDataAadhar = (To) => {
        let data = [
            { name: '', value: parseFloat(To) },
            { name: '', value: (100 - parseFloat(To)) }
        ]
        setchartDataState(data)


    }
    const similarProfileDataApiData = () => {
        similarProfileData(allDetail.loanId, callback => {
            setmatchedSimilarData(callback.matchBy)
        })
    }
    useEffect(() => {
        // getCrifAnalysisStatusdata()
        // getAnalysisData()
        setcrifLoaderData(true)
        fetch(
            APIS.GET_CRIF_DATA +
            allDetail.userId +
            '&regenerateParam=' +
            '0' + '&loanId=' + allDetail.loanId + '&empId=' + admin.emailId
        )
            .then(res => res.text())
            .then(res => {
                if (res && res?.responseCode == 0) {
                    setcrifLoaderData(false)
                } else if(res){
                    setcrifprevdata(res)
                    handletabs();   
                }
                setcrifLoaderData(false)
            })
            crifDataApionCrifPage()

    }, [])
    
    const refreshDataCrif = () => {
        getCrifAnalysisStatus(allDetail.userId, allDetail.loanId, callback => {
            // setCrifAnalysisData(callback)
            console.log(callback, 'callbackcallbackcallbackcallbackcallback')
            setanalysisData(callback.finBoxDecision)
            setCrifAnalysisData(callback);
        })
    }

    const getpanProfileDetailsData = (userId) => {
        panProfileDetails(allDetail.userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {
                setKarzadata(callBack)
            }
        })
    }
    const getAadhaarData = () => {
        aadharVerificationDetails(allDetail.userId, callBack => {
            setAadhaarData(callBack)

        })
    }
    const aadharXmlDetailsData = () => {
        aadharXmlDetails(allDetail.userId, callBack => {
            setAadharXmlData(callBack)
            if (callBack && callBack.percentageOfMatch) {
                makegraphDataAadhar(callBack.percentageOfMatch)
            }
        })
    }



    const phocketTablePagination = (data, type) => {
        if (type == 'back') {
            if (tableMinPagination >= paginationNumber) {
                settableMinPagination(tableMinPagination - paginationNumber)
                settableMaxPagination(tableMaxPagination - paginationNumber)


            }
        } else if (type == 'forward') {
            if (tableMaxPagination < data.length) {
                settableMinPagination(tableMinPagination + paginationNumber)
                settableMaxPagination(tableMaxPagination + paginationNumber)

            }
        }
    }
    const similarFilterData = (data) => {
        setViewPage('similar')
        settableMinPagination(0)
        settableMaxPagination(10)
        setSmsTab('')

        let d = []
        let similarProfileData = allSimilarProfileData
        if (data) {
            setFilterType(data)

            similarProfileData = similarProfileData.filter(row => {
                if (row.matchBy != null) {
                    if (row.matchBy.includes(data)) {

                        d.push(row)
                        setFilterData(d)
                        setFraudData(d)
                        forceUpdate()
                    }
                }
            })
        }
        if (data === '') {
            setFraudData(allSimilarProfileData)
            setFilterType('')
            forceUpdate()
        }

    }
    const getSmsAnalysisStatusData = () => {
        getSmsAnalysisStatus(allDetail.userId, callback => {
            setSmsData(callback)
        })
    }

    const getFinbitAnalysisData = () => {
        getFinbitAnalysis(allDetail.userId, callback => {
            setFinbitData(callback)


        })
    }
    const financialTabHandler = () => {
        setfinancialTab(!financialTab)
        setfinBitMainTab(false)
        setSecondT('')

    }
    const openFinbitPage = () => {
        setfinBitMainTab(true)
        setViewPage('finbit')
        setSecondT('')
        setSmsTab('')


    }
    const crifDataApionCrifPage = () => {
        console.log('crifDataApicalled')
        getCrifAnalysisStatus(allDetail.userId, allDetail.loanId, callback => {
            if (callback.finBoxDecision !== null) {
                setRawReport(false)
                setCrifData(callback)
                setanalysisData(callback.finBoxDecision);
                setCrifAnalysisData(callback)
                // if (JSON.parse(callback.finBoxDecision.progress) && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] && JSON.parse(callback.finBoxDecision.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']) {
                //     setparseData(JSON.parse(callback.finBoxDecision.progress))
                // }
                // const progress = JSON.parse(callback.finBoxDecision.progress);

                // if (
                //     progress &&
                //     progress['INDV-REPORT-FILE'] &&
                //     progress['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                //     progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                //     progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] &&
                //     progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']
                // ) {
                //     sortByDisbursedDate(progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']);
                // }
                const parser = new DOMParser();
                const doc = parser.parseFromString(callback.finBoxDecision.progress, 'text/html');
                console.log(doc)
                setDateOfRequest(getCrifValueWiseData(doc, 'Date of Request:', 'dataHeader1'));
            }

        })
    }

    const getCrifValueWiseData = (obj, type, searchType) => {
        if (type !== '' && type !== undefined && type !== null) {
            let headerElem = [];
            let valueElem = [];
            if (searchType == 'dataHeader') {

                headerElem = obj.querySelectorAll('.dataHeader');
                valueElem = obj.querySelectorAll('.dataValue');
            } else if (searchType == 'dataHeader1') {
                headerElem = obj.querySelectorAll('.dataHeader1');
                valueElem = obj.querySelectorAll('.dataValue1');
            }


            for (let i = 0; i < headerElem.length; i++) {
                const headerElement = headerElem[i];
                const valueElement = valueElem[i];

                if (headerElement.textContent.trim() === type) {
                    const dateOfRequestValue = valueElement.textContent;
                    return dateOfRequestValue;
                }
            }
        }
    }
    // const getCrifAnalysisStatusdata = () => {
    //     if (crifDataDashboard && crifDataDashboard.finBoxDecision !== null) {
    //         setRawReport(false)
    //         setCrifAnalysisData(crifDataDashboard);
    //         setanalysisData(crifDataDashboard.finBoxDecision);

    //         if (crifDataDashboard.finBoxDecision.progress) {
    //             setparseData(JSON.parse(crifDataDashboard.finBoxDecision.progress));
    //             forceUpdate();

    //             const progress = JSON.parse(crifDataDashboard.finBoxDecision.progress);

    //             if (
    //                 progress &&
    //                 progress['INDV-REPORT-FILE'] &&
    //                 progress['INDV-REPORT-FILE']['INDV-REPORTS'] &&
    //                 progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
    //                 progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] &&
    //                 progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']
    //             ) {
    //                 sortByDisbursedDate(progress['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']);
    //             }
    //         }
    //     } else {

    //     }
    // };

    const getAnalysisData = () => {
        setcrifLoaderData(true)
        fetch(APIS.GET_CRIF_ANALYSIS_DATA_NEW + allDetail.userId + '&loanId=' + allDetail.loanId)
            .then(res => res.json())
            .then(res => {
                setanalysisData(res)
                setcrifLoaderData(false)
                setparseData(JSON.parse(res.progress))
                setHtmlData(res.progress)
                forceUpdate()
                if (JSON.parse(res.progress) && JSON.parse(res.progress)['INDV-REPORT-FILE'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES'] && JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']) {
                    sortByDisbursedDate(JSON.parse(res.progress)['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['RESPONSES']['RESPONSE']);
                }
            })
        setTimeout(() => {
            setLoader(false)
        }, 2000);

    }
    const crifpopup = () => {
        setcrifdatapopup(false);
    }
    const regenerateCrif = () => {
        console.log("regener");
        setcrifLoaderData(true)
        fetch(
            APIS.GET_CRIF_DATA +
            allDetail.userId +
            '&regenerateParam=' +
            '1' + '&loanId=' + allDetail.loanId + '&empId=' + admin.emailId
        )
            .then(res => res.text())
            .then(res => {
                if (res && res?.responseCode == 0) {
                } else if(res) {
                    setuserCrifData(res)
                    setcrifLoaderData(false)
                    setregenerateButton(false)
                    // crifDataApi()
                    crifDataApionCrifPage()
                    setcrifdatapopup(true);
                    handletabs();
                }
                setcrifLoaderData(false)
            })
    }
    const commentChangeHandler = (e, type) => {
        if (e.target.value !== 'add') {
            setNotesText(e.target.value)
        }
        if (e.target.value === 'add') {
            setNotesPopup(true)
            setNotesText(e.target.value)

        }
        setNotesType(type)

    }
    const saveNotesType = () => {
        if (notesText !== '' && notesText !== undefined && notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, notesText, admin.emailId, 'crifDecision', callBack => {
                setNotesPopup(false)
                if (callBack === 'success') {
                    getNotesnew(allDetail.loanId, allDetail.userId, 'crifDecision', callBack => {
                        setnewNotes(callBack)
                    })
                }

            })
        }
    }
    const zoomPage = () => {
        setzoomState(!zoomState)

    }
    const viewOriginaReportCrif = () => {
        if (showOrignal === false) {
            if (originalCrifReport === '') {
                setcrifLoaderData(true)
                fetch(
                    APIS.GET_CIRF_SAVE_DATA +
                    allDetail.userId + '&loanId=' + allDetail.loanId 
                )
                    .then(res => res.text())
                    .then(res => {
                        if (res.responseCode == 0) {
                        } else {
                            // setuserCrifData(res)
                            setcrifLoaderData(false)
                            setOriginalReport(res)
                        }
                    })
            }
        }
        setshowOrignal(!showOrignal)
    }

    return (
        <>
            {sidePoupState ?
                <PopUp popupStatus={popupStatus} closePopup={closepopupSide} />
                : ""}
            {zoomState ?
                <div>

                </div>
                :''}
           <div className="container-fluid details-body-credit full-height credit-module">
           <div className="row" >
               <div className="col-sm-3 col-xs-12">
                   <div className="credit-blue-box">
                       <div className="row">
                           <div className="col-sm-6 col-xs-12">Cibil Score</div>
                           <div className="col-sm-6 col-xs-12 text-right">
                               <b>{analysisData?.score || 0}</b>
                           </div>
                       </div>
                       <hr style={{ borderTop: '2px solid #A8C8FF' }} />
                       <div className="row">
                           <div className="col-sm-6 col-xs-12">Decision <br />
                           </div>

                           <div className="col-sm-6 col-xs-12 text-right">
                               {analysisData?.finalDecision || ''}
                               <br/>
                              <span style={{fontSize:'10px',textAlign:'left'}}>{setDateOfRequest !== '' ? DateOfRequest : ''}</span> 
                               {/* {parseData &&
                                   parseData['INDV-REPORT-FILE'] &&
                                   parseData['INDV-REPORT-FILE']['INDV-REPORTS'] &&
                                   parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT'] &&
                                   parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER'] ? <div style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.40' }}>Fetched on -
                                   {parseData['INDV-REPORT-FILE']['INDV-REPORTS']['INDV-REPORT']['HEADER']['DATE-OF-REQUEST']}
                               </div> : ""} */}
                           </div>
                       </div>
                   </div>
                   <div className="row" style={{ marginTop: '20px' }}  >
                       <div className="col-sm-3 text-right">
                           <img src={crifTab === 'obligation' ? Image1Active : Image1InActive} width={'30px'} />
                       </div>

                       <div className="col-sm-9" onClick={() => setcrifTab('obligation')} style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'obligation' ? '#2B78FF' : '#000', fontWeight: crifTab === 'obligation' ? '700' : '400' }}>
                           Obligation Amount
                           {crifData && crifData.obligationData && crifData.obligationData.emiSum ?
                               <div style={{ color: crifTab === 'obligation' ? '#2B78FF' : '#000', fontWeight: '700' }}> INR {crifData ? Math.round(crifData.obligationData.emiSum) : 0}</div> : <div className="col-sm-12 col-xs-12 text-blue text-right">N/A </div>}
                       </div>

                   </div>

                   <div className="row" style={{ marginTop: '20px' }} >
                       <div className="col-sm-3 text-right">
                           <img src={crifTab === 'greenflag' ? Image2Active : Image2InActive} width={'30px'} />
                       </div>

                       <div className="col-sm-9" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'greenflag' ? '#2B78FF' : '#000', fontWeight: crifTab === 'greenflag' ? '700' : '400' }} onClick={() => setcrifTab('greenflag')}>

                           Green Flag<br />      {analysisData != null &&
                               analysisData != undefined &&
                               analysisData != '' &&
                               analysisData.approve != null &&
                               analysisData.approve != '' &&
                               analysisData.approve != undefined && analysisData.finalDecision != 'Rejected'
                               ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                               <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                       </div>
                   </div>
                   <div className="row" style={{ marginTop: '20px' }} >
                       <div className="col-sm-3 text-right">
                           <img src={crifTab === 'exceptional' ? Image3Active : Image3InActive} width={'30px'} />
                       </div>

                       <div className="col-sm-9" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'exceptional' ? '#2B78FF' : '#000', fontWeight: crifTab === 'exceptional' ? '700' : '400' }} onClick={() => setcrifTab('exceptional')}>

                           Yellow Flag<br />
                           {analysisData != null &&
                               analysisData != undefined &&
                               analysisData != '' &&
                               analysisData.exceptionCase != null &&
                               analysisData.exceptionCase != '' &&
                               analysisData.exceptionCase != undefined
                               ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                               <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                       </div>
                   </div>
                   <div className="row" style={{ marginTop: '20px' }} >
                       <div className="col-sm-3 text-right">
                           <img src={crifTab === 'clearrejection' ? Image4Active : Image4InActive} width={'30px'} />
                       </div>
                       <div className="col-sm-9" style={{ fontSize: '12px', cursor: 'pointer', color: crifTab === 'clearrejection' ? '#2B78FF' : '#000', fontWeight: crifTab === 'clearrejection' ? '700' : '400' }} onClick={() => setcrifTab('clearrejection')}>
                           Red Flag<br />
                           {analysisData != null &&
                               analysisData != undefined &&
                               analysisData != '' &&
                               analysisData.rejected != null &&
                               analysisData.rejected != '' &&
                               analysisData.rejected != undefined && analysisData.finalDecision == 'Rejected'
                               ? <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '10px' }}><i className="fa fa-check-circle"></i>&nbsp;Found</span> :
                               <span style={{ color: '#FF5C5D', background: '#FFEFF2', borderRadius: '4px', padding: '2px', fontSize: '10px', marginRight: '5px' }}><i className="fa fa-times-circle"></i>&nbsp;Not Found</span>}
                       </div>
                   </div>
                   <div className="row" style={{ marginTop: '20px' }} >
                       <div className="col-sm-3 text-right">
                           <img src={crifTab === 'crifreport' ? InfoImage : InfoInactive} width={'30px'} />

                       </div>
                       <div className="col-sm-9" style={{ fontSize: '14px', cursor: 'pointer', color: crifTab === 'crifreport' ? '#2B78FF' : '#000', fontWeight: crifTab === 'crifreport' ? '700' : '400', marginTop: '5px' }} onClick={() => setcrifTab('crifreport')}>

                           Detailed View</div>

                       <div className="col-sm-5 text-right" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => setcrifTab('crifreport')}>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-9 col-xs-12 " style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.25)', height: '600px', overflow: 'auto' }}>
                        {viewPage === 'detail' ?
                            <CrifNew
                                crifButtonFromApi={crifButtonFromApi}
                                regenerateButton={regenerateButton}
                                zoomState={zoomState}
                                zoomPage={zoomPage}
                                allDetail={allDetail}
                                jsondata={jsondata}
                                crfJsonData={crfJsonData}
                                notesType={notesType} 
                                saveUnderWritter={saveUnderWritter}
                                underWriterHandler={underWriterHandler}
                                openCommentPopup={openCommentPopup}
                                newNotes={newNotes}
                                saveNotesType={saveNotesType}
                                commentChangeHandler={commentChangeHandler}
                                closepopup={closepopup}
                                notesPopup={notesPopup}
                                admin={admin}
                                sortedData={sortedData}
                                crifLoaderData={crifLoaderData}
                                analysisData={analysisData} crifTab={crifTab} crifData={crifData}
                                userCrifData={userCrifData} regenerateCrif={regenerateCrif}
                                parseData={parseData} crifprevdata={crifprevdata}
                                viewOriginaReportCrif={viewOriginaReportCrif}
                                showOrignal={showOrignal}
                                originalCrifReport={originalCrifReport}
                            />
                            : ""}

                    </div>
                </div>
            </div>
        </>



    )
}
export default Analysis