import React, { useState, useEffect } from 'react'
import moment from "moment";
import TablePopup from './TablePopup';
import {
    XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Label, ScatterChart,
    Scatter
} from 'recharts';
import { getKPILoanDashboardStatsAPI, getLoanAmountTrendsGraphAPI, getYearWiseLoanAmountTrendsAPI, getUpcomingDuePaymentAPI, getLoanStatusWiseCountsAPI } from './DebtListActionCreator';
import Loader from '../../../presentationals/Loader/Loader.component';
export default function KPILoanManagementDashboard({ nbfcList, userDetailHandler }) {
    const [loader, setLoader] = useState(false)
    const [tablePopupData, setTablePopupData] = useState([]);
    const [tablePopup, setTablePopup] = useState(false);
    const [nbfcFilter, setnbfcFilter] = useState('')
    const [KPITabsData, setKPITabsData] = useState({})
    const [LoanTrendData, setLoanTrendData] = useState([])
    const [YearWiseLoanData, setYearWiseLoanData] = useState([])
    const [UpcomingDuePaymentData, setUpcomingDuePaymentData] = useState([])
    const [LoanStatusWiseCountsData, setLoanStatusWiseCountsData] = useState([])
    const [YearWiseLoanType, setYearWiseLoanType] = useState('applied')
    const [loanTrendsChartType, setLoanTrendsChartType] = useState(['appliedAmount', 'approvedAmount', 'paybackAmount']);

    const Piecolors = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '58509D', '003F5C', 'bc5090', '#639d70', '#87ceeb', '#ffb6c1', '#8a2be2', '#4682b4', '#daa520', '#cd5c5c']
    const loanStatusColors = {
        "New Lead": '#003f5c',
        "Communicated": '#2f4b7c',
        "Approved": '#665191',
        "Disbursed": '#a05195',
        "Paid": '#d45087',
        "Rejected": '#f95d6a',
        "Cancelled": '#ff7c43',
        "Expired": '#ffa600',
        "Permanent Reject": '#58509D',
        "Loan Under Process": '#003F5C',
        "Ongoing": '#bc5090',
        "Pending": '#639d70',
        "Received": '#87ceeb',
        "Payment Overdue": '#ffb6c1',
        "Cheque Bounced": '#8a2be2',
        "Incomplete Application": '#4682b4',
        "Soft Approval": '#daa520',
        "Write-Off": '#cd5c5c'

    };
    const [filterColorData, setFiltrColorData] = useState([])
    const [activeStatuses, setActiveStatuses] = useState([]);



    const toggleStatus = (status) => {
        setActiveStatuses(prevStatuses =>
            prevStatuses.includes(status)
                ? prevStatuses.filter(item => item !== status)
                : [...prevStatuses, status]
        );
    };

    useEffect(() => {
        filterPieData();
    }, [activeStatuses]);

    const filterPieData = () => {
        setFiltrColorData(LoanStatusWiseCountsData.filter(entry => activeStatuses.includes(entry.loanStatus)))
    }


    useEffect(() => {
        APIFunction()
    }, [])

    const handlePopup = (data) => {
        setTablePopupData(data)
        setTablePopup(true)
    }
    const closePopup = () => {
        setTablePopup(false)
        setTablePopupData([])
    }
    const handleChange = (e, type) => {
        if (type == 'nbfcFilter') {
            setnbfcFilter(e.target.value)
        } else if (type == 'YearWiseLoanType') {
            setLoader(true)
            setYearWiseLoanType(e.target.value)
            setTimeout(() => {
                setLoader(false)
            }, 500)
        }

    };

    const APIFunction = () => {
        getKPILoanDashboardStatsFunction()
        getLoanAmountTrendsGraphFunction()
        getYearWiseLoanAmountTrendsFunction()
        getUpcomingDuePaymentFunction()
        getLoanStatusWiseCountsFunction()
    }

    const getKPILoanDashboardStatsFunction = () => {
        setLoader(true)
        getKPILoanDashboardStatsAPI(nbfcFilter, '', (callback) => {
            setLoader(false)
            console.log(callback)
            if (callback && callback?.status == 200) {
                setKPITabsData(callback?.data)
            } else {
                setKPITabsData({})
            }
        })
    }

    const getLoanAmountTrendsGraphFunction = () => {
        getLoanAmountTrendsGraphAPI(nbfcFilter, '', (callback) => {
            console.log(callback)
            if (callback && callback?.status == 200) {
                setLoanTrendData(callback?.data)
            }
        })
    }

    const getUpcomingDuePaymentFunction = () => {
        getUpcomingDuePaymentAPI(nbfcFilter, '', (callback) => {
            console.log(callback)
            if (callback && callback?.status == 200) {
                setUpcomingDuePaymentData(callback?.data)
            }
        })
    }

    const getYearWiseLoanAmountTrendsFunction = () => {
        getYearWiseLoanAmountTrendsAPI(nbfcFilter, '', (callback) => {
            console.log(callback)
            if (callback && callback?.status == 200) {
                let graphData = callback?.data?.data
                if (graphData?.length > 0) {
                    graphData?.pop()
                }

                const data = {
                    data: graphData,
                    percentageChange: callback?.data?.percentageChange
                }
                setYearWiseLoanData(data)
            }
        })
    }
    const getLoanStatusWiseCountsFunction = () => {
        getLoanStatusWiseCountsAPI(nbfcFilter, '', (callback) => {
            console.log(callback)
            if (callback && callback?.status == 200 && callback?.data?.length > 0) {
                const filteredData = callback.data.filter(entry => (entry.loanStatus !== "Select All" && entry.count != 0));

                const filteredDataWithColors = filteredData.map(entry => ({
                    ...entry,
                    color: loanStatusColors[entry.loanStatus] || '#000000'
                }));

                setLoanStatusWiseCountsData(filteredDataWithColors)

                const loanStatuses = filteredDataWithColors.map(entry => entry.loanStatus);
                setActiveStatuses(loanStatuses)
            }
        })
    }

    const handlechartValues = (e, type) => {
        if (loanTrendsChartType?.includes(type)) {
            setLoanTrendsChartType(loanTrendsChartType.filter(item => item !== type));
        } else {
            setLoanTrendsChartType([...loanTrendsChartType, type]);
        }
    }

    const handleParticularDataView = (data) => {
        userDetailHandler(data)
    }

    return (
        <div className="DebtManagementDashboard">
            <div className="MonthDateFilter" style={{ marginBottom: '30px' }}>
                <div className="row">

                    <div className="col-lg-2 col-sm-3 col-xs-12">
                        <div className="inputGrp">
                            <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                            <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e) => handleChange(e, 'nbfcFilter')} style={{ width: '100%' }} >
                                <option value="">All</option>
                                {
                                    nbfcList && nbfcList?.length > 0 ? nbfcList?.map((data, index) => {
                                        return (
                                            <option value={data?.nbfcId}>{data?.name}</option>
                                        )
                                    })
                                        : ''}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-3 col-xs-12">
                        <button className='FilterApply' onClick={() => APIFunction()} style={{ marginTop: window.innerWidth > 1800 ? '29px' : '24px' }}>Apply Filter</button>
                    </div>
                    {
                        UpcomingDuePaymentData && UpcomingDuePaymentData?.length > 0 ?
                            <div className="col-lg-3 col-sm-4 col-xs-12" style={{ float: window.innerWidth > 1200 ? 'right' : 'none', textAlign: 'right' }}>
                                <button className='FilterApply' style={{ backgroundColor: '#223d64', color: '#fff', border: '1px solid #223d64' }} onClick={() => handlePopup(UpcomingDuePaymentData)}><i class="fa fa-eye" aria-hidden="true"></i> Payments Due in Next 15 Days</button>
                            </div>
                            : ''}

                </div>
            </div>
            {
                loader ?
                    <Loader /> : ''
            }
            <div className="LinechartBlock LoanManagementBlock">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox">
                                <div className="HeadingBox" style={{ backgroundColor: '#223d64' }}>
                                    <h5>Existing Customers</h5>
                                </div>
                                <div className="Linecharts">
                                    <h4>{KPITabsData?.existingCustomers?.toLocaleString("en-IN") || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox">
                                <div className="HeadingBox" style={{ backgroundColor: '#4481d5' }}>
                                    <h5>Ongoing Loans</h5>
                                </div>
                                <div className="Linecharts">
                                    <h4>{KPITabsData?.ongoingLoans?.toLocaleString("en-IN") || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox">
                                <div className="HeadingBox" style={{ backgroundColor: '#223d64' }}>
                                    <h5>Today's Payment Received</h5>
                                </div>
                                <div className="Linecharts">
                                    <h4><i className="fa fa-rupee"></i>&nbsp;{KPITabsData?.todaysPaymentReceived?.toLocaleString("en-IN") || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox">
                                <div className="HeadingBox" style={{ backgroundColor: '#4481d5' }}>
                                    <h5>OverDue Loans</h5>
                                </div>

                                <div className="Linecharts">
                                    <h4>{KPITabsData?.overdueLoans?.toLocaleString("en-IN") || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox">
                                <div className="HeadingBox" style={{ backgroundColor: '#223d64' }}>
                                    <h5>% Overdue</h5>
                                </div>
                                <div className="Linecharts">
                                    <h4>{KPITabsData?.overduePercent?.toLocaleString("en-IN") || 0} %</h4>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox">
                                <div className="HeadingBox" style={{ backgroundColor: '#4481d5' }}>
                                    <h5>Overdue Payments</h5>
                                </div>
                                <div className="Linecharts">
                                    <h4><i className="fa fa-rupee"></i>&nbsp;{KPITabsData?.overduePayments?.toLocaleString("en-IN") || 0}</h4>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className=" col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox" style={{ height: '480px' }}>
                                <div className="HeadingBox WidthInc" style={{ backgroundColor: '#223d64' }}>
                                    <h5>Loan Trends</h5>
                                </div>
                                <div className="Linecharts">
                                    {/* <h6 style={{ color: '#223d64' }}>14,000.00</h6>
                                    <h6 className='text'>Loan Amount Over Time</h6> */}

                                    <div className="charthead Barchart" style={{ height: '360px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <ScatterChart
                                                width={560}
                                                height={300}
                                                data={LoanTrendData}
                                                margin={{
                                                    top: 30, right: 10, left: 30, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} />
                                                <XAxis dataKey="applyDate" />
                                                <YAxis />
                                                <Tooltip content={<CustomTooltip />} />
                                                {
                                                    loanTrendsChartType?.includes('appliedAmount') ?
                                                        <Scatter type="monotone" dataKey="appliedAmount" fill="#f24839" line shape="dot" />
                                                        : ''}
                                                {
                                                    loanTrendsChartType?.includes('approvedAmount') ?
                                                        <Scatter type="monotone" dataKey="approvedAmount" fill="#223D64" line shape="dot" />
                                                        : ''}
                                                {
                                                    loanTrendsChartType?.includes('paybackAmount') ?
                                                        <Scatter type="monotone" dataKey="paybackAmount" fill="#4481d5" line shape="dot" />
                                                        : ''}
                                            </ScatterChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', flexWrap: 'wrap' }}>
                                        <div className="Legend Scatterchart" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('appliedAmount') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'appliedAmount')}>
                                            <div className="square" style={{ margin: 'auto 10px auto 0px', backgroundColor: '#f24839' }}></div>
                                            <p>Applied Amount</p>
                                        </div>
                                        <div className="Legend Scatterchart" style={{ marginRight: '15px', cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('approvedAmount') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'approvedAmount')}>
                                            <div className="square" style={{ margin: 'auto 10px auto 0px' }}></div>
                                            <p>Approved Amount</p>
                                        </div>
                                        <div className="Legend Scatterchart" style={{ cursor: 'pointer', textDecoration: loanTrendsChartType?.includes('paybackAmount') ? '' : 'line-through' }} onClick={(e) => handlechartValues(e, 'paybackAmount')}>
                                            <div className="square" style={{ margin: 'auto 10px auto 0px', backgroundColor: '#4481d5' }}></div>
                                            <p>Payback Amount</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox" style={{ height: '480px' }}>
                                <div className="HeadingBox WidthInc" style={{ backgroundColor: '#4481d5', width: window.innerWidth > 991 && window.innerWidth < 1200 ? '60%' : '50%' }}>
                                    <h5>Year Wise Comparison</h5>
                                </div>
                                <div className="Linecharts">
                                    <div className="txts" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <div className="lfttxt MonthDateFilter">
                                            <div className="inputGrp" style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                                <label htmlFor="YearWiseLoanType" className='labeltag' style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>Comparison Type</label>
                                                <select className='MonthinputTag' id="YearWiseLoanType" name="YearWiseLoanType" value={YearWiseLoanType} onChange={(e) => handleChange(e, 'YearWiseLoanType')} style={{ width: '100%' }} >
                                                    <option value="applied">Applied</option>
                                                    <option value="approved">Approved</option>
                                                    <option value="payback">Payback</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="Rttxt">
                                            {
                                                YearWiseLoanData?.percentageChange?.[YearWiseLoanType] > 0 ?
                                                    <h6 style={{ color: '#10d910', fontWeight: '600' }}>
                                                        <i class="fa fa-long-arrow-up" aria-hidden="true"></i> &nbsp;
                                                        {YearWiseLoanData?.percentageChange?.[YearWiseLoanType]} %</h6>
                                                    :
                                                    <h6 style={{ color: '#f24839' }}>
                                                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i> &nbsp;
                                                        {YearWiseLoanData?.percentageChange?.[YearWiseLoanType]} %</h6>
                                            }

                                            <h6 className='text'>
                                                Since Last Year
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="charthead Barchart">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart
                                                width={560}
                                                height={300}
                                                data={YearWiseLoanData?.data}
                                                barSize={15}
                                                margin={{
                                                    top: 20, right: 0, left: 35, bottom: 10,
                                                }}
                                            >
                                                <CartesianGrid vertical={false} />
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <Tooltip />
                                                {YearWiseLoanType == 'applied' ?
                                                    <>
                                                        <Bar type="monotone" dataKey="previousApplied" fill="#4481d5" radius={[5, 5, 0, 0]} />
                                                        <Bar type="monotone" dataKey="currentApplied" fill="#223d64" radius={[5, 5, 0, 0]} />
                                                    </>
                                                    : ''}
                                                {YearWiseLoanType == 'approved' ?
                                                    <>
                                                        <Bar type="monotone" dataKey="previousApproved" fill="#4481d5" radius={[5, 5, 0, 0]} />
                                                        <Bar type="monotone" dataKey="currentApproved" fill="#223d64" radius={[5, 5, 0, 0]} />
                                                    </>
                                                    : ''}
                                                {YearWiseLoanType == 'payback' ?
                                                    <>
                                                        <Bar type="monotone" dataKey="previousPayback" fill="#4481d5" radius={[5, 5, 0, 0]} />
                                                        <Bar type="monotone" dataKey="currentPayback" fill="#223d64" radius={[5, 5, 0, 0]} />
                                                    </>
                                                    : ''}
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="Legend Barchart">
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#4481d5' }}></div>
                                            <p>Last Year</p>
                                        </div>
                                        <div className='legendbox'>
                                            <div className="square" style={{ backgroundColor: '#223d64' }}></div>
                                            <p>This Year</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-6 col-xs-12 newPadding">
                        <div className="outerBox" style={{ marginTop: window.innerWidth > 1800 ? '65px' : '40px' }}>
                            <div className="innerBox" style={{ height: '480px' }}>
                                <div className="HeadingBox WidthInc" style={{ backgroundColor: '#223d64', width: window.innerWidth > 991 && window.innerWidth < 1200 ? '60%' : '50%' }}>
                                    <h5>Loan Status Overview</h5>
                                </div>
                                <div className="Linecharts" style={{ marginTop: '20px' }}>
                                    <div className="charthead " style={{ height: '330px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <PieChart
                                                width={300}
                                                height={300}
                                            >
                                                <Pie data={filterColorData} dataKey="count" cx="50%" cy="50%" radius={80} fill="#82ca9d">
                                                    {filterColorData?.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                                    ))}
                                                </Pie>
                                                <Tooltip content={<CustomTooltipPie />} />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="Legend Barchart" style={{ flexWrap: 'wrap' }}>
                                        {LoanStatusWiseCountsData && LoanStatusWiseCountsData.length > 0 ? LoanStatusWiseCountsData.map((value, index) => (
                                            <div
                                                className='legendbox'
                                                style={{ alignItems: 'baseline', cursor: 'pointer' }}
                                                key={index}
                                                onClick={() => toggleStatus(value.loanStatus)}
                                            >
                                                <div className="square small-size circular" style={{ backgroundColor: value.color }}></div>
                                                <p style={{
                                                    textDecoration: activeStatuses.includes(value.loanStatus) ? 'none' : 'line-through',
                                                    color: activeStatuses.includes(value.loanStatus) ? '#000' : '#999'
                                                }}>
                                                    {value.loanStatus}
                                                </p>
                                            </div>
                                        )) : ''}
                                        {/* {Object.entries(loanStatusColors).map(([status, color]) => (
                                            <div className='legendbox' style={{ alignItems: 'baseline', cursor: 'pointer' }} key={status} onClick={() => toggleStatus(status)}>
                                                <div className="square small-size circular" style={{ backgroundColor: color }}></div>
                                                <p style={{
                                                    textDecoration: activeStatuses.includes(status) ? 'none' : 'line-through',
                                                    color: activeStatuses.includes(status) ? '#000' : '#999'
                                                }}>{status}</p>
                                            </div>
                                        ))} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                tablePopup ?
                    <TablePopup tablePopupData={tablePopupData} close={closePopup} tabName={'KPILoanManagementDashboard'} handleParticularDataView={handleParticularDataView} />
                    : ''}
        </div>
    )
}


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p className="label">{`Date: ${label || 'No Date'}`}</p>

                {payload.map((item, index) => (
                    <p key={index} style={{ color: item.fill }}>
                        {`${item.name || 'Value'}: ${item.value !== undefined ? item.value : 'N/A'}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

const CustomTooltipPie = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const { loanStatus, count } = payload[0].payload;

        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p><strong>{loanStatus}</strong></p>
                <p>Count: {count}</p>
            </div>
        );
    }
    return null;
}; 