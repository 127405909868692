import React, { useEffect, useState } from "react";
import "./../compo.scss";
import List from "../../maketingimages/list.png";
import Eye from "../../maketingimages/eye.png";
import arrowDown from '../../newdesigns/imagesmarket/arrow-down.png'
import arrowup from '../../newdesigns/imagesmarket/arrow-up.png'
import { Pagination, Slider } from "@mui/material";
import AggregatorDetails from "./aggregatorDetails";
import { getEventDataByPageNo, getEventDataCountPageNo } from "../actioncreator";
import ConfigPage from "./components/ConfigMainTabs";
import Skeleton from "react-loading-skeleton";
import BackLogo from '../../../../../images/rewampimages/Chevron.png'
const Component3 = ({ admin, setViewState, pageViewHandler }) => {
  const [page, setPage] = useState(1);
  const [noOfEntry, setnoOfEntry] = useState(10)
  const [counts, setCounts] = useState(10)

  const [aggregatorId, setAggregatorId] = useState("");
  const [detailShow, setDetailsShow] = useState('list');
  const [eventsData, setEventsData] = useState([]);
  const [listData, setListData] = useState('');
  const [editPage, setEditPage] = useState(false);
  const [editdata, setsetEditdata] = useState('');
  const [loaderState, Setloader] = useState(true);
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    getData()
  }, []);
  const getCount = () => {
    // Setloader(true)
    getEventDataCountPageNo(
      page,
      noOfEntry,
      (callBack) => {
        setCounts(callBack.aggregatorCount);
        Setloader(false)
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getData = () => {
    getEventDataByPageNo(
      // page,
      // noOfEntry,
      (callBack) => {
        setEventsData(callBack.data);
        // getCount()
        Setloader(false)
      },
      (error) => {
        console.log(error);
      }
    );
  }
  const handleEye = (partnerId, listData) => {
    setListData(listData)
    setAggregatorId(partnerId);
    // setDetailsShow(true);
    setDetailsShow('view')

  };
  const changeHandler = (event, value) => {
    getEventDataByPageNo(
      // value,
      // noOfEntry,
      (callBack) => {
        setEventsData(callBack);
        getEventDataCountPageNo(
          value,
          noOfEntry,
          (callBack) => {
            setCounts(callBack.aggregatorCount);
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );

  };
  const editHandler = (item) => {
    console.log(item)
    setsetEditdata(item);
    setDetailsShow('edit')
  }

  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? null : id);
  };
  return (
    <>
      {/* <div className="row">
        <div className="col-sm-6 col-xs-6" onClick={() => setViewState('list')} style={{ background: detailShow === 'list' ? '#267DFF' : '#A8C8FF', padding: '10px', textAlign: 'center', color: detailShow === 'list' ? '#fff' : '#000', cursor: 'pointer' }}>
          <i class="fa fa-list" aria-hidden="true"></i>&nbsp; Aggregators List

        </div>
        <div className="col-sm-6 col-xs-6" onClick={() => setViewState('configuration')} style={{ background: detailShow === 'configuration' ? '#267DFF' : '#A8C8FF', padding: '10px', textAlign: 'center', color: detailShow === 'configuration' ? '#fff' : '#000', cursor: 'pointer' }}>

          <i class="fa fa-user-plus" aria-hidden="true"></i>&nbsp;    Add New Aggregator

        </div>

      </div> */}
      {detailShow === 'list' ? (
        <div className="" >
          {loaderState === false ?
            <div className="container">
              <div className="row details-new-tab-div" style={{ marginTop: '100px' }}>
                <div className="col-sm-4 " style={{ marginTop: '15px' }}>
                  <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button>
                </div>
              </div>

              {/* <div className="" style={{ marginTop: '20px', display: 'flex' }}>
              <img src={List} alt="" width={18} height={18} style={{ marginTop: '10px' }} />{" "}
              &nbsp;&nbsp;
              <h4 className="ms-4">Aggregators List</h4>

              <i className="fa fa-plus-square" style={{ color: '#006c67', fontSize: '24px', marginTop: '10px', marginLeft: '20px', cursor: 'pointer' }} onClick={() => setViewState('configuration')}></i>
            </div>
            <div className='credit-table'>
              <table>
                <thead style={{ background: 'rgba(0, 108, 103, 0.2)', color: '#000' }}>
                  <tr className="thead-class">
                    <th>View Details</th>
                    <th className="">Aggregator Name</th>
                    <th className="">Aggregator Type</th>
                    <th className="">Creator Mode</th>
                    <th className="">Status</th>
                    <th className="">Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {eventsData &&
                    eventsData.length > 0 &&
                    eventsData.map((item, index) => {
                      return (
                        <tr key={index} style={{ borderBottom: "none" }}>
                          <td onClick={() => handleEye(item.partnerId, item)}>
                            <img
                              className="hoverCusrsor"
                              src={Eye}
                              width={20}
                              height={20}
                              alt=""
                            />
                          </td>
                          <td scope="col">
                            <span id="comment">
                              <b>{item.companyName || ""}</b>
                            </span>
                          </td>
                          <td scope="col">
                            <b>{item.type || ""}</b>
                          </td>
                          <td scope="col">
                            <b>{item.creatorMode || ""}</b>
                          </td>
                          <td scope="col" className="text-success">
                            <b>{item.status?<span style={{color:'green'}}>Active</span>:<span style={{color:'red'}}>Inactive</span>}</b>
                          </td>
                          <td scope="col" className="text-success">
                            {item.partnerId?
                            <i className="fa fa-edit" onClick={() => editHandler(item)}></i>
                            :""}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {counts > 1 ?
              <div style={{ float: 'right', marginTop: '10px', marginBottom: '10px', marginRight: '40px' }}>
                <Pagination count={Math.ceil(counts / noOfEntry)} color="primary"
                  onChange={changeHandler}
                />
              </div>
              : ""}
              <i className="fa fa-plus-square" style={{ color: '#417bf6', fontSize: '24px', marginTop: '10px', marginLeft: '20px', cursor: 'pointer' }} onClick={() => setViewState('configuration')}></i>
            </div> */}
              <div className="projection-new">
                <div className="Projection">
                  <div className="accords openaccord" style={{ background: '#fff',border:'none' }}>
                    <div className="">
                      {Object.keys(eventsData)?.length > 0 ? Object.keys(eventsData)?.map((item, index) => (
                        <AccordionItem
                          key={index} // Using index as a unique key
                          id={index} // Passing index as id
                          isOpen={openAccordion === index}
                          toggleAccordion={toggleAccordion}
                          item={item} // Pass the entire item object as prop
                          dayWiseApiData={eventsData} handleEye={handleEye} editHandler={editHandler}
                        />
                      )) : ""}

                    </div>
                  </div>

                </div>
              </div>
              {/* <div className='credit-table' style={{ marginTop: '10px' }}>
                <table>
                  <thead style={{ background: 'rgba(0, 108, 103, 0.2)', color: '#000' }}>
                    <tr className="thead-class">
                      <th>View Details</th>
                      <th className="">Aggregator Name</th>
                      <th className="">Aggregator Type</th>
                      <th className="">Creator Mode</th>
                      <th className="">Status</th>
                      <th className="">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventsData &&
                      eventsData.length > 0 &&
                      eventsData.map((item, index) => {
                        return (
                          <tr key={index} style={{ borderBottom: "none" }}>
                            <td onClick={() => handleEye(item.partnerId, item)}>
                              <img
                                className="hoverCusrsor"
                                src={Eye}
                                width={20}
                                height={20}
                                alt=""
                              />
                            </td>
                            <td scope="col">
                              <span id="comment">
                                <b>{item.companyName || ""}</b>
                              </span>
                            </td>
                            <td scope="col">
                              <b>{item.type || ""}</b>
                            </td>
                            <td scope="col">
                              <b>{item.creatorMode || ""}</b>
                            </td>
                            <td scope="col" className="text-success">
                              <b>{item.status ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>}</b>
                            </td>
                            <td scope="col" className="text-success">
                              {item.partnerId ?
                                <i className="fa fa-edit" onClick={() => editHandler(item)}></i>
                                : ""}
                            </td>
                          </tr>
                        );
                      })}

                  </tbody>
                </table>
              </div> */}
              {/* {counts > 1 && loaderState === false ?
                <div style={{ float: 'right', marginTop: '10px', marginBottom: '10px', marginRight: '40px' }}>
                  <Pagination count={Math.ceil(counts / noOfEntry)} color="primary"
                    onChange={changeHandler}
                  />
                </div>
                : ""} */}

            </div>
            : ""}
          {
            loaderState ?
              <div className='phocket-table-new' style={{ overflow: 'auto', marginTop: '130px' }} >
                <table className='text-center' style={{ marginTop: '0px' }}>
                  <thead>
                    <tr>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                      <th><Skeleton height={40} /></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>

                    </tr>
                    <tr>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                      <td><Skeleton height={40} /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
              : ""
          }
        </div>
      ) : ""}

      {detailShow === 'view' ?
        <AggregatorDetails
          listData={listData}
          admin={admin}
          aggregatorId={aggregatorId}
          setDetailShow={setDetailsShow}
        />
        : ""}
      {detailShow === 'edit' ?
        <ConfigPage setDetailsShow={setDetailsShow} editdata={editdata} />
        : ""}


    </>
  );
};

export default Component3;

function AccordionItem({ id, isOpen, item, toggleAccordion, dayWiseApiData ,handleEye,editHandler}) {

  return (
    <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
      <div className="heading table-responsive">
        <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
          <h5 style={{ color: isOpen ? 'white' : 'black' }}>
            <img style={{ width: '60%', marginTop: '-10px' }} src={isOpen ? arrowup : arrowDown} alt="" />&nbsp;
            {/* {id} */}
          </h5>
        </div>
        <table class="table text-center tableheader">
          <tbody>
            <tr>
              <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>Event Name</td>

              <th >{item}</th>
             
            </tr>
          </tbody>
        </table>
      </div>
      {isOpen && (
        <div className="bottomtable">
          <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`} style={{border:'none'}}>
            {/* <table class="table text-center tablebottom"> */}
            <div className='credit-table' style={{ marginTop: '10px' }}>
                <table>
                  <thead style={{ background: 'rgba(0, 108, 103, 0.2)', color: '#000' }}>
                    <tr className="thead-class">
                      <th>View Details</th>
                      <th className="">Aggregator Name</th>
                      <th className="">Partner Name</th>
                      <th className="">Aggregator Type</th>
                      <th className="">Creator Mode</th>
                      <th className="">Status</th>
                      <th className="">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dayWiseApiData[item] &&
                      dayWiseApiData[item]?.length > 0 &&
                      dayWiseApiData[item]?.map((item, index) => {
                        return (
                          <tr key={index} style={{ borderBottom: "none" }}>
                            <td onClick={() => handleEye(item.partnerId, item)}>
                              <img
                                className="hoverCusrsor"
                                src={Eye}
                                width={20}
                                height={20}
                                alt=""
                              />
                            </td>
                            <td scope="col">
                              <span id="comment">
                                <b>{item.companyName || ""}</b>
                              </span>
                            </td>
                            <td scope="col">
                              <span id="comment">
                                <b>{item.partnerName || ""}</b>
                              </span>
                            </td>
                            <td scope="col">
                              <b>{item.type || ""}</b>
                            </td>
                            <td scope="col">
                              <b>{item.creatorMode || ""}</b>
                            </td>
                            <td scope="col" className="text-success">
                              <b>{item.status ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>Inactive</span>}</b>
                            </td>
                            <td scope="col" className="text-success">
                              {item.partnerId ?
                                <i className="fa fa-edit" onClick={() => editHandler(item)}></i>
                                : ""}
                            </td>
                          </tr>
                        );
                      })}

                  </tbody>
                </table>
              </div>
            {/* </table> */}
          </div>
        </div>
      )}
    </div>
  );
}