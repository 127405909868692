import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { saveBusinessTargetData, updateBusinessTargetData } from "../../targetactioncreator";

const EditTarget = ({ editData }) => {
    const [categoryData, setCategoryData] = useState({
        "categoryId": "",
        "categoryName": "",
        "subCategoryId": "",
        "subCategoryName": "",
        "targetApplication": '',
        "targetApplicationFresh": '',
        "targetApplicationRepeat": '',
        "comment": '',
        "active": false,
    });

    // const categoryOptions = [{
    //     "categoryId": "SWXaW31",
    //     "categoryName": "Utilities",
    //     "subCategoryId": "456",
    //     "subCategoryName": "Electricity",
    // }];
    const [categoryList, setCategoryList] = useState('')
    const [subcategoryList, setsubCategoryList] = useState('')

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!categoryData.categoryId) {
            formErrors.categoryId = "Category ID is required";
            isValid = false;
        }
        if (!categoryData.categoryName) {
            formErrors.categoryName = "Category Name is required";
            isValid = false;
        }
        if (!categoryData.subCategoryId) {
            formErrors.subCategoryId = "Subcategory ID is required";
            isValid = false;
        }
        if (!categoryData.subCategoryName) {
            formErrors.subCategoryName = "Subcategory Name is required";
            isValid = false;
        }
        if (!categoryData.targetApplication) {
            formErrors.targetApplication = "Target Application is required";
            isValid = false;
        }
        if (!categoryData.targetApplicationFresh) {
            formErrors.targetApplicationFresh = "Target Application (Fresh) is required";
            isValid = false;
        }
        if (!categoryData.targetApplicationRepeat) {
            formErrors.targetApplicationRepeat = "Target Application (Repeat) is required";
            isValid = false;
        }
        if (!categoryData.comment) {
            formErrors.comment = "Comment is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Category Data Saved", categoryData);
            if (editData.id) {
                categoryData.id = editData.id
                updateBusinessTargetData(categoryData, callback => {
                    if (callback === 'success') {
                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                });
            }else{
                saveBusinessTargetData(categoryData, callback => {
                    if (callback === 'success') {
                        setPopup(true);
                        setPopupStatus('Data Saved Successfully!');
                    } else {
                        setPopup(true);
                        setPopupStatus('Please try again later!');
                    }
                    removePopup();
                });
            }
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setCategoryData({
                categoryId: editData.categoryId || "",
                categoryName: editData.categoryName || "",
                subCategoryId: editData.subCategoryId || "",
                subCategoryName: editData.subCategoryName || "",
                targetApplication: editData.targetApplication || '',
                targetApplicationFresh: editData.targetApplicationFresh || '',
                targetApplicationRepeat: editData.targetApplicationRepeat || '',
                comment: editData.comment || "",
                active: editData.active !== undefined ? editData.active : false,
            });
        }
    }, [editData]);


    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="target-box">
                <h3>Add/Update Business Target Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category Id</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="categoryId"
                                        value={categoryData.categoryId}
                                        onChange={handleChange}
                                    />
                                    {errors.categoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="categoryName"
                                        value={categoryData.categoryName}
                                        onChange={handleChange}
                                    />
                                    {errors.categoryName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryName}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Sub Category Id</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="subCategoryId"
                                        value={categoryData.subCategoryId}
                                        onChange={handleChange}
                                    />
                                    {errors.subCategoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.subCategoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Sub Category Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="subCategoryName"
                                        value={categoryData.subCategoryName}
                                        onChange={handleChange}
                                    />
                                    {errors.subCategoryName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.subCategoryName}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target Application</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="targetApplication"
                                        value={categoryData.targetApplication}
                                        onChange={handleChange}
                                    />
                                    {errors.targetApplication && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetApplication}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target Application Fresh</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="targetApplicationFresh"
                                        value={categoryData.targetApplicationFresh}
                                        onChange={handleChange}
                                    />
                                    {errors.targetApplicationFresh && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetApplicationFresh}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Target Application Repeat</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="targetApplicationRepeat"
                                        value={categoryData.targetApplicationRepeat}
                                        onChange={handleChange}
                                    />
                                    {errors.targetApplicationRepeat && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.targetApplicationRepeat}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>

                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Active</label>
                                    &nbsp;&nbsp;
                                    <input
                                        style={{ marginTop: '5px' }}
                                        type="checkbox"
                                        name="active"
                                        checked={categoryData.active}
                                        onChange={(e) => setCategoryData((prevData) => ({
                                            ...prevData,
                                            active: e.target.checked,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 col-xs-12">
                            <div style={{ display: '', marginTop: '20px' }}>
                                <div style={{ width: '' }}>
                                    <label style={{ marginTop: '10px' }}>Comment</label>
                                </div>
                                <div style={{ width: '' }}>
                                    <textarea
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="comment"
                                        value={categoryData.comment}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-xs-12" style={{ marginTop: '20px', textAlign: 'center' }}>
                            <button type="submit" className="btn btn-primary">Save Business Target</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditTarget;
