import React, { useState } from 'react'
import moment from "moment";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line, AreaChart, Area,
} from 'recharts';
import Pagination from '@mui/material/Pagination';

const paddingStyle ={
    padding: window.innerWidth > 1800 ? '20px 7px':'5px 10px'
}

export default function TablePopup({ tablePopupData, close, tabName, tablePopupDataType, handleParticularDataView}) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const paginatedData = tabName == 'DebtManagement' ? tablePopupData?.data?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : tabName == 'KPILoanManagementDashboard'  ? tablePopupData?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : '';


    const dateformatFn = (date) => {
        if (date === '' || date === undefined || date == null) {
            return '-'
        } else {
            const newDate = date.split(' ')[0]
            return newDate
        }

    }
    return (
        <>
            <div className="background-blur " onClick={close}></div>
            {
                tabName == 'DebtManagement' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '-20px', width: '100%', maxWidth: '90%' }}>
                        <div className="popup-content income">
                            <h4 className="text-center">{tablePopupData?.month}</h4>
                            <div className="tables">
                                <div className="phocket-table-new newTable2" style={{overflowX:'auto'}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={paddingStyle}>Debtor Name</th>
                                                <th className="text-center" style={paddingStyle}>Outstanding Balance</th>
                                                <th className="text-center" style={paddingStyle}>Overdue Amount</th>
                                                <th className="text-center" style={paddingStyle}>Principal</th>
                                                <th className="text-center" style={paddingStyle}>Interest</th>
                                                <th className="text-center" style={paddingStyle}>Penalty</th>
                                                <th className="text-center" style={paddingStyle}>Days Overdue</th>
                                                <th className="text-center" style={paddingStyle}>EMI Due Date</th>
                                                <th className="text-center" style={paddingStyle}>Paid</th>
                                                <th className="text-center" style={paddingStyle}>Any EMI Paid</th>
                                                <th className="text-center" style={paddingStyle}>Last EMI Paid Date</th>
                                                <th className="text-center" style={paddingStyle}>Last EMI Paid Number</th>
                                                <th className="text-center" style={paddingStyle}>Tenure</th>
                                                <th className="text-center" style={paddingStyle}>Processing Fee</th>
                                                <th className="text-center" style={paddingStyle}>Total Overdue Count</th>
                                                <th className="text-center" style={paddingStyle}>Bounce Charge</th>
                                                <th className="text-center" style={paddingStyle}>Paid Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedData?.map((data, index) => (
                                                <tr key={index}>
                                                    <td style={{ minWidth: '250px' }}>{data?.debtorName.trim()}</td>
                                                    <td className="text-center">{data?.outstandingBalance?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center">{data?.overdueAmount?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center">{data?.principal?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center">{data?.interest?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center">{data?.penalty?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center">{data?.daysOverdue}</td>
                                                    <td className="text-center" style={{ minWidth: '120px' }}>{data?.emiDueDate}</td>
                                                    <td className="text-center">{data?.paid ? 'Yes' : 'No'}</td>
                                                    <td className="text-center" style={{ minWidth: '95px' }}>{data?.anyEmiPaid ? 'Yes' : 'No'}</td>
                                                    <td className="text-center" style={{ minWidth: '120px' }}>{data?.lastPaidDate || '-'}</td>
                                                    <td className="text-center" style={{ minWidth: '130px' }}>{data?.lastPaidNo}</td>
                                                    <td className="text-center">{data?.tenure}</td>
                                                    <td className="text-center">{data?.processingFee?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center" style={{ minWidth: '135px' }}>{data?.totalOverdueCount}</td>
                                                    <td className="text-center">{data?.bounceCharge?.toLocaleString("en-IN")}</td>
                                                    <td className="text-center">{data?.paidAmount?.toLocaleString("en-IN")}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <Pagination
                                        count={Math.ceil(tablePopupData?.data?.length / itemsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'DebtManagementGraph' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '100%', maxWidth: '90%' }}>
                        <div className="popup-content income">
                            <div className='text-center'>
                                <h4>{tablePopupData?.month + ' (OverDue Amount Vs Debtor Name)'}</h4>
                            </div>
                            <div className="tables" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable2" style={{ overflowY: 'auto', maxHeight: '400px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>Total Overdue</th>
                                                <th className='text-center'>Total Principal</th>
                                                <th className='text-center'>Total Interest</th>
                                                <th className='text-center'>Total Penalty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(tablePopupData?.totalData)?.length > 0 ?
                                                <tr >
                                                    <td className='text-center'>{tablePopupData?.totalData?.totalOverdue?.toFixed(2)}</td>
                                                    <td className='text-center'>{tablePopupData?.totalData?.totalPrincipal?.toFixed(2)}</td>
                                                    <td className='text-center'>{tablePopupData?.totalData?.totalInterest?.toFixed(2)}</td>
                                                    <td className='text-center'>{tablePopupData?.totalData?.totalPenalty?.toFixed(2)}</td>
                                                </tr>
                                                : ''}

                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="charts" style={{ marginTop: '50px', border: '1px solid rgba(0,0,0,0.25)', borderRadius: '6px', padding: '10px' }}>
                                <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '600px' : '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        {/* <ComposedChart
                                            width={500}
                                            height={150}
                                            data={tablePopupData.data}
                                            barSize={window?.innerWidth > 1800 ? 40 : 20 }
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid stroke='none' />
                                            <XAxis dataKey="debtorName" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="overdueAmount" fill="#8884d8" />
                                            <Line type="monotone" dataKey="overdueAmount" stroke="#223D64" strokeWidth={2} dot={function customDot() { return null }} />
                                        </ComposedChart> */}
                                        <AreaChart
                                            width={500}
                                            height={150}
                                            data={tablePopupData.data}
                                            margin={{
                                                top: 10,
                                                right: 10,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="debtorName" />
                                            <YAxis />
                                            <Tooltip />
                                            <Area type="monotone" dataKey="overdueAmount" stroke="#8884d8" fill="#8884d8" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>

                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'DebtManagementDashboard' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '40px', width: '800px' }}>
                        <div className="popup-content income">
                            <h4 className="text-center">{tablePopupDataType?.name}</h4>
                            <div className="tables">
                                <div className="phocket-table-new newTable2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Creditors</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tablePopupData?.data?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data?.creditors}</td>
                                                    <td>{data?.amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            {
                tabName == 'KPILoanManagementDashboard' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '-10px', width: '100%', maxWidth: '90%' }}>
                        <div className="popup-content income">
                            <div className='text-center'>
                                <h4>Payment Due in Next 15 Days</h4>
                            </div>
                            <div className="tables" style={{ marginTop: '10px' }}>
                                <div className="phocket-table-new newTable2" >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="text-center">View</th>
                                                <th className="text-center">Debtor Name</th>
                                                <th className="text-center">Principal Amount</th>
                                                <th className="text-center">Interest</th>
                                                <th className="text-center">EMI Amount</th>
                                                <th className="text-center" style={{ minWidth: '100px' }}>Due Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedData?.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="text-center" style={{padding: window.innerWidth > 1800 ? '15px 7px' : '5px 10px'}}><h6 onClick={() => handleParticularDataView(data)} style={{ cursor: 'pointer' }}><i class="fa fa-info-circle fa-lg" aria-hidden="true"></i></h6></td>

                                                    <td className="text-center" style={{padding: window.innerWidth > 1800 ? '15px 7px' : '5px 10px'}}>{data?.name || '-'}</td>
                                                    <td className="text-center" style={{padding: window.innerWidth > 1800 ? '15px 7px' : '5px 10px'}}>{data?.principal?.toLocaleString("en-IN") || '-'}</td>
                                                    <td className="text-center" style={{padding: window.innerWidth > 1800 ? '15px 7px' : '5px 10px'}}>{data?.interest?.toLocaleString("en-IN") || '-'}</td>
                                                    <td className="text-center" style={{padding: window.innerWidth > 1800 ? '15px 7px' : '5px 10px'}}>{data?.emiAmount?.toLocaleString("en-IN") || '-'}</td>
                                                    <td className="text-center" style={{padding: window.innerWidth > 1800 ? '15px 7px' : '5px 10px'}}>{dateformatFn(data?.dueDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Pagination
                                    count={Math.ceil(tablePopupData?.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </div>
                        </div>
                    </div>
                    : ''}
          
        </>
    )
}
