import React, { useState } from 'react';
import { saveAttendenceApi } from '../../containers/Admin/Targets/targetactioncreator';
import Popup from './Popup.component';
import 'react-datepicker/dist/react-datepicker.css';

const AddAttendance = ({ admin, closeDisbursePopup, editData }) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2022 + 1 }, (_, i) => 2022 + i);
    
    const months = [
        { value: 'January', label: 'January' },
        { value: 'February', label: 'February' },
        { value: 'March', label: 'March' },
        { value: 'April', label: 'April' },
        { value: 'May', label: 'May' },
        { value: 'June', label: 'June' },
        { value: 'July', label: 'July' },
        { value: 'August', label: 'August' },
        { value: 'September', label: 'September' },
        { value: 'October', label: 'October' },
        { value: 'November', label: 'November' },
        { value: 'December', label: 'December' },
    ];

    const [attendanceData, setAttendanceData] = useState({
        employeeId: editData.employeeID,
        month: '',
        year: '',
        present: 0,
        noAbsent: 0,
        noHalfDay: 0,
        noPaidLeave: 0,
        noEarnLeave: 0,
        addedBy: admin ? admin.emailId : '',
    });
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({}); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAttendanceData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', 
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!attendanceData.month) {
            newErrors.month = 'Month is required.';
        }
        if (!attendanceData.year) {
            newErrors.year = 'Year is required.';
        }
        if (attendanceData.present < 0) {
            newErrors.present = 'Present cannot be negative.';
        }
        if (attendanceData.noAbsent < 0) {
            newErrors.noAbsent = 'Absent cannot be negative.';
        }
        if (attendanceData.noHalfDay < 0) {
            newErrors.noHalfDay = 'Half Day cannot be negative.';
        }
        if (attendanceData.noPaidLeave < 0) {
            newErrors.noPaidLeave = 'Paid Leave cannot be negative.';
        }
        if (attendanceData.noEarnLeave < 0) {
            newErrors.noEarnLeave = 'Earn Leave cannot be negative.';
        }
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return; // Prevent submission if there are validation errors
        }
        
        console.log(attendanceData);
        saveAttendenceApi(attendanceData, (callBack) => {
            if (callBack.message === 'success') {
                setPopup(true);
                setPopupStatus('Successfully Saved!');
                setTimeout(() => {
                    closeDisbursePopup();
                }, 2000);
                removePopup();
            } else {
                setPopup(true);
                setPopupStatus('Please Try Again Later!');
                removePopup();
            }
        });
    };

    const closePopup = () => {
        setPopup(false);
    };
    
    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    return (
        <div>
            {sidePopup ? <Popup popupStatus={popupStatus} closePopup={closePopup} /> : ""}
            <div className='background-blur'></div>
            <div className='popup-main small-popup container-fluid dbl-border'>
                <div className='row from-group'>
                    <div className='col-xs-12'>
                        <button className='close' onClick={closeDisbursePopup}>
                            &times;
                        </button>
                    </div>
                </div>
                <h4>Add Attendance for {editData.employeeName}</h4>
                <form onSubmit={handleSubmit} className='attendance-form'>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='month'>Month:</label>
                        <select
                            id='month'
                            name='month'
                            value={attendanceData.month}
                            onChange={handleChange}
                            required
                            className='form-control'
                        >
                            <option value=''>Select Month</option>
                            {months.map((month) => (
                                <option key={month.value} value={month.value}>
                                    {month.label}
                                </option>
                            ))}
                        </select>
                        {errors.month && <small className='text-danger'>{errors.month}</small>} 
                    </div>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='year'>Year:</label>
                        <select
                            id='year'
                            name='year'
                            value={attendanceData.year}
                            onChange={handleChange}
                            required
                            className='form-control'
                        >
                            <option value=''>Select Year</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {errors.year && <small className='text-danger'>{errors.year}</small>} 
                    </div>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='present'>Present:</label>
                        <input
                            className='form-control'
                            type='number'
                            id='present'
                            name='present'
                            value={attendanceData.present}
                            onChange={handleChange}
                        />
                        {errors.present && <small className='text-danger'>{errors.present}</small>} 
                    </div>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='noAbsent'>No. of Absent:</label>
                        <input
                            className='form-control'
                            type='number'
                            id='noAbsent'
                            name='noAbsent'
                            value={attendanceData.noAbsent}
                            onChange={handleChange}
                        />
                        {errors.noAbsent && <small className='text-danger'>{errors.noAbsent}</small>} 
                    </div>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='noHalfDay'>No. of Half Day:</label>
                        <input
                            className='form-control'
                            type='number'
                            id='noHalfDay'
                            name='noHalfDay'
                            value={attendanceData.noHalfDay}
                            onChange={handleChange}
                        />
                        {errors.noHalfDay && <small className='text-danger'>{errors.noHalfDay}</small>} 
                    </div>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='noPaidLeave'>No. of Paid Leave:</label>
                        <input
                            className='form-control'
                            type='number'
                            id='noPaidLeave'
                            name='noPaidLeave'
                            value={attendanceData.noPaidLeave}
                            onChange={handleChange}
                        />
                        {errors.noPaidLeave && <small className='text-danger'>{errors.noPaidLeave}</small>} 
                    </div>
                    <div className='form-group'>
                        <label style={{ marginTop: '5px' }} htmlFor='noEarnLeave'>No. of Earn Leave:</label>
                        <input
                            className='form-control'
                            type='number'
                            id='noEarnLeave'
                            name='noEarnLeave'
                            value={attendanceData.noEarnLeave}
                            onChange={handleChange}
                        />
                        {errors.noEarnLeave && <small className='text-danger'>{errors.noEarnLeave}</small>} 
                    </div>
                    <div className='text-center'>
                        <button style={{ marginTop: '20px' }} type='submit' className='btn btn-primary'>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddAttendance;
