import React from 'react';
import { ROLE, STRING } from '../../../../utils/Constant'

const PhocketCustomerDetailForm = ({ UserDetailTabsHandler, navItemsArray, selectedIndex,teaGardenId,
    admin, actionButtonState, userDetail }) => (
    <div className="container-fluid user-detail-tab">
        <div className="row bt-color">
            {userDetail.loanFrom !== 'bazarBussiness' && admin.rolelist != undefined && userDetail != null ?
            
                <div className="edit-profile-menu col-xs-12">
           {navItemsArray.map((item, index) => (
                        <div className="btn-group" key={index}>{item === 'BASIC' ?
                            <button id="basicTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 0)}>Profile</button>
                            : item === 'STATEMENT' && (teaGardenId!==''? admin.rolelist.indexOf(ROLE.TE_Analysis) >= 0:admin.rolelist.indexOf(ROLE.PROFILE_ANALYSIS) >= 0 )?
                                <button id="statementTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 1)}>Analysis</button>
                                // : item === 'CIBIL' ?
                                //     <button id="cibilTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 2)}>Cibil</button>
                                : item === 'FINANCE' ?
                                    <button id="FinancialTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 3)}>Financial</button>
                                    // : item === 'DOCS' && admin.rolelist.indexOf(ROLE.DOCS) >= 0 ?
                                    //     <button id="docsTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 4)}>Docs</button>
                                    : item === 'ACTION' && actionButtonState == true && (teaGardenId!==''?admin.rolelist.indexOf(ROLE.TE_Action) >= 0 :(admin.rolelist.indexOf(ROLE.ACTION) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0)) ?
                                    
                                        userDetail.followUpStatusCode != null && userDetail.followUpStatusCode != STRING.CANCEL_BY_USER ?
                                            <button id="actionTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                                            : admin.role == 'superuser' ? <button id="actionTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                                                : ""
                                        : item === 'MESSAGE' && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                            <button id="messageTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 6)}>Communication</button>
                                            // : item === 'TRANS' && admin.rolelist.indexOf(ROLE.TRANSACTION) >= 0 ?
                                            //     <button id="transTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 7)}>Transaction</button>
                                            //: item === 'FEEDBACK' && admin.rolelist.indexOf(ROLE.USER_FEEDBACK) >= 0 ?
                                            // <button id="feedbackTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 8)}>Feedback</button>
                                            : item === 'STATUS ACTIVITY' && admin.rolelist.indexOf(ROLE.STATUS_ACTIVITY) >= 0 ?
                                                <button id="StatusActivityTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 9)}>Activity</button>
                                                : item === 'AGREEMENT'&&teaGardenId==='' && userDetail.followUpStatusCode != null ? userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED ?
                                                    <button id="agreementTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 10)}>Agreement</button> : ""
                                                    // : item === 'EDIT PROFILE' && actionButtonState == true && admin.rolelist.indexOf(ROLE.EDIT) >= 0 ?
                                                    //     <button id="StatusActivityTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 11)}>Edit Profile</button>
                                                    // : item === 'FIELD BOY' ? admin.rolelist.indexOf(ROLE.FIELD_OPERATION) >= 0 && (userDetail.followUpStatusCode != null && userDetail.followUpStatusCode == STRING.APPROVED_STATUS || userDetail.followUpStatusCode == STRING.DISBURSED || userDetail.followUpStatusCode == STRING.PAID || userDetail.followUpStatusCode == STRING.RECEIVED) ?
                                                    //     <button id="StatusActivityTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 12)}>Field Boy</button>
                                                    //     : null
                                                    // : item === 'EMI' && admin.rolelist.indexOf(ROLE.USER_EMI) >= 0 ?
                                                    //     <button id="EMI" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 13)}>EMI</button>
                                                    // : item === 'EMI History' && admin.rolelist.indexOf(ROLE.USER_EMI) >= 0 ?
                                                    //     <button id="EMIHistory" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 14)}>EMI History</button>
                                                    : item === 'CONTACTS APP' && admin.rolelist.indexOf(ROLE.CONTACT_APP) >= 0 ?
                                                    // : item === 'CONTACTS APP' && admin.emailId==='shivani.choudhary@credfin.money' ?
//uncomment
                                                        <button id="contactsApps" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 15)}>Contacts</button>
                                                        : null
                            //  item === 'AGREEMENT' ?
                            //     <button id="agreementTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 9)}>Agreement</button>
                            //     : ""
                        } </div>
                    ))}
                </div>

                : ""}
            {userDetail.loanFrom === 'bazarBussiness'&&admin.rolelist != undefined && userDetail != null  ?
                <div className="edit-profile-menu col-xs-12">
                    {navItemsArray.map((item, index) => (
                        <div className="btn-group" key={index}>{item === 'BASIC' ?
                           <button id="basicTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 0)}>Profile</button>
                           : item === 'STATEMENT' && admin.rolelist.indexOf(ROLE.PROFILE_ANALYSIS) >= 0 ?
                                <button id="statementTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 1)}>Analysis</button>
                           : item === 'FINANCE' ?
                            <button id="FinancialTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 3)}>Financial</button>
                         
                            : item === 'ACTION' && actionButtonState == true && (admin.rolelist.indexOf(ROLE.ACTION) >= 0 || admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0) ?
                                userDetail.followUpStatusCode != null && userDetail.followUpStatusCode != STRING.CANCEL_BY_USER ?
                                    <button id="actionTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                                    : admin.role == 'superuser' ? <button id="actionTab" className={(index === selectedIndex) ? "selected" : ""} onClick={(e) => UserDetailTabsHandler(e, 5)}>Action</button>
                                        : ""
                                : item === 'MESSAGE' && admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0 ?
                                    <button id="messageTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 6)}>Communication</button>
                                    : item === 'STATUS ACTIVITY' && admin.rolelist.indexOf(ROLE.STATUS_ACTIVITY) >= 0 ?
                                        <button id="StatusActivityTab" className={(index === selectedIndex) ? "active" : ""} onClick={(e) => UserDetailTabsHandler(e, 9)}>Activity</button>

                                        : ""} </div>
                    ))}
                </div>

                : ""}
        </div>
    </div>
)

export default PhocketCustomerDetailForm;