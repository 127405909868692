// import React from 'react';
// import LoaderImgSvg from '../../../images/rupees_icon.svg';
// import {ReactSVG} from 'react-svg'

// const PhocketLoader = ({ }) => (
//     <div className="phocket-loader">
//         <ReactSVG
//             src={LoaderImgSvg}
//             className="phocket-loader-img"
//         />
//     </div>
// )

// export default PhocketLoader;
import React from "react";

const SpinnerLoader = () => {
    return (
        <div className="spinner-loader text-center" style={{marginTop:'5%'}}>
        <span class="loader"></span>
        </div>

    )
}
export default SpinnerLoader