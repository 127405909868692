import React from "react";
import Email from "./email";
import Message from './sms'
import Notification from './notification'
import WhatsappChat from "./whatsaapp.container";
import CommentsNew from "./commentsNew";
import { getUserDetailApi } from "../../../Dashboard/DashboardActionCreator.component";
import { APIS } from "../../../../../utils/api-factory";

class CommTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewTab: 'email'
        }
    }
    buttonHandle(type) {
        this.setState({ viewTab: type })
    }
    componentDidMount() {
        const { allDetail, userDetail, admin } = this.props

  
    }
    render() {
        const { allDetail, userDetail, admin } = this.props

        return (
            <div className="container-fluid details-new">
                <div className="btn-group btn-grp-own" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px' }}>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'email' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'email' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('email')}> Email </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'whatsapp' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'whatsapp' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('whatsapp')} > Whatsapp </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'SMS' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'SMS' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('SMS')} > SMS </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'Notification' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'Notification' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('Notification')} > Notification </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'comments' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'comments' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('comments')} > Comments </button>

                </div>
                {this.state.viewTab === 'email' ?
                    <div className="container-fluid details-new">
                        <Email admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    </div>
                    : ""}
                {this.state.viewTab === 'SMS' ?
                    <div className="container-fluid details-new">
                        <Message admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    </div>
                    : ""}
                {this.state.viewTab === 'Notification' ?
                    <div className="container-fluid details-new">
                        <Notification admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    </div>
                    : ""}
                {this.state.viewTab === 'whatsapp' ?
                    <div className="container-fluid details-new">
                        <WhatsappChat admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    </div>
                    : ""}
                {this.state.viewTab === 'comments' ?
                    <div className="container-fluid details-new">
                        <CommentsNew admin={admin} userDetail={userDetail} allDetail={allDetail} />
                    </div>
                    : ""}
            </div>
        )
    }
}
export default CommTab