import React, { useState } from "react";
import { lastDateOfMonth, monthStartDate, reverseFormat, todayDate } from "../../../maketingComponents/utils/utils";
import arrowDown from '../../../newdesigns/imagesmarket/arrow-down.png';
import arrowup from '../../../newdesigns/imagesmarket/arrow-up.png';
import { useEffect } from "react";
import { getSingularCountsByPartnerEventNameAPI } from '../../actioncreator'
import moment from "moment";
const List = ({ }) => {
    const [fromDate, setfromDate] = useState(monthStartDate())
    const [toDate, settoDate] = useState(todayDate())
    const [openAccordion, setOpenAccordion] = useState(null);
    const [dayWiseApiData, setdayWiseData] = useState({});
    const [totalData,setTotalData] =useState('')

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };
    const getSingularCountsByDate = () => {
        getSingularCountsByPartnerEventNameAPI(fromDate, toDate, (callback) => {
            console.log(callback)
            if (callback && callback?.message == 'success') {
                setdayWiseData(callback?.data?.data)
                setTotalData(callback?.data?.overallTotal)
            }else{
                setdayWiseData({})
                setTotalData('')
            }
        })
    }


    const handleChange = (e, type) => {
        // getData(data)
        if (type === 'fromDate') {
            setfromDate(reverseFormat(e.target.value))
        }
        if (type === 'toDate') {
            settoDate(reverseFormat(e.target.value))
        }
        if (type === 'submit') {
            getSingularCountsByDate()
        }
        console.log(e.target.value)


    }

    useEffect(() => {
        getSingularCountsByDate()
    }, [])


    return (
        <div className="projection-new">
            <div className="container" style={{ padding: '20px 50px' }}>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-sm-2 col-xs-12" style={{ marginTop: '4px' }}>
                        From Date
                        <input type="date" id="startDate" placeholder="DD/MM/YY" name="fromDate" value={reverseFormat(fromDate)} onChange={(e) => handleChange(e, 'fromDate')} className=""
                            style={{ border: "1px solid gray", borderRadius: "6px", padding: "10px", background: '#fff', width: '100%' }}
                        />
                    </div>
                    <div className="col-sm-2 col-xs-12">
                        <div className="filterHeaders" style={{ marginTop: "5.5px" }}>
                            To Date
                        </div>

                        <input type="date" id="endDate" name="toDate" value={reverseFormat(toDate)} onChange={(e) => handleChange(e, 'toDate')} placeholder="DD/MM/YY" className="" style={{ border: "1px solid gray", borderRadius: "6px", padding: "10px", background: '#fff', width: '100%' }}
                        />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div style={{  marginTop: '22px' }}>
                            <button style={{ width: '145px', marginLeft: '10px', background: '#006c67', padding: '10px', color: '#fff', borderRadius: '6px', marginRight: '10px' }} onClick={(e) => handleChange(e, 'submit')}>Submit</button>
                            </div>
                    </div>

                    <div className=" col-lg-2 col-sm-6 col-xs-12" style={{ float: 'inline-end',marginTop:'10px' }}>
                            <div className='count-div' style={{display:'flex'}}>
                                <div className="btn-count-new text-center"><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700'}}>TOTAL COUNTS <br></br><br></br> </div> <span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}>{totalData  || '0'}</span></div>
                               </div>
                        </div>
                </div>


                <div className=" Projection " style={{ marginTop: '40px' }}>
                    <div className="">
                        {
                            Object.keys(dayWiseApiData)?.length > 0 ?
                                <div className="accords openaccord" style={{ background: '#fff', border: 'none', boxShadow: '5px 2px 10px 5px #efefef' }}>
                                    <div className="">
                                        {Object.keys(dayWiseApiData)?.length > 0 ? Object.entries(dayWiseApiData)?.map(([heading, value], index) => (
                                            <AccordionItem
                                                key={index}
                                                id={index}
                                                isOpen={openAccordion === index}
                                                toggleAccordion={toggleAccordion}
                                                itemHead={heading}
                                                itemValue={value}
                                                dayWiseApiData={dayWiseApiData}
                                            />
                                        )) : ""}

                                    </div>

                                </div>
                                :
                                <div className="text-center">
                                    <h2 style={{ padding: '20px 0px', fontSize: '24px', fontWeight: '500' }}>No Data Found</h2>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default List

function AccordionItem({ id, isOpen, toggleAccordion, dayWiseApiData, itemHead, itemValue }) {
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        <img style={{ width: '60%', marginTop: '-10px' }} src={isOpen ? arrowup : arrowDown} alt="" />&nbsp;
                        {/* {id} */}
                    </h5>
                </div>
                <table class="table text-center tableheader">
                    <tbody>
                        <tr>
                            <td className='td1 subtd' style={{ width: '40%', textAlign: 'left', border: 'none', textAlign: 'center' }}>Partner Name</td>
                            <td style={{ background: '#fff' }}>{itemHead}</td>
                        </tr>
                        <tr>
                            <td className='td1 subtd' style={{ width: '40%', textAlign: 'left', border: 'none', textAlign: 'center' }}>Count</td>
                            <td style={{ background: '#fff' }}>{itemValue?.total?.total || 0}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`}>
                        {
                            itemValue?.data && Object.keys(itemValue?.data)?.length > 0 ?
                                <table class="table text-center tablebottom">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="BTLR Leftcolborder">Event Name</th>
                                            <th>Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            itemValue?.data && Object.keys(itemValue?.data)?.length > 0 ? Object.entries(itemValue?.data)?.map(([key, value], index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="Leftcolborder" style={{ width: '50%' }}>{key}</td>
                                                        <td>{value}</td>

                                                    </tr>

                                                )
                                            })
                                                : ''}
                                    </tbody>
                                </table>
                                :   
                                <div className="text-center">
                                    <h2 style={{ padding: '20px 0px', fontSize: '24px', fontWeight: '500' }}>No Data Found</h2>
                                </div>
                        }
                    </div>
                </div>

            )}
        </div>
    );
}
