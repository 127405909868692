import React, { useEffect, useState } from "react";
import { getAllExpenseSubCategory, deleteExpenseSubCategoryById } from "../../targetactioncreator";
import ExpensesList from "../list";
import NodataImage from '../../../../../../images/nodata.png'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import moment from "moment";
import Popup from '../../../../../presentationals/Popup/Popup.component';
const SubCategoryList = ({ editHandler,applyFilter,fromDate,toDate,ActiveMonthFilter,applyFilterState }) => {
    // const expenseData = {
    //     "categoryId": "SWXaW31",
    //     "subCategoryType": "Sample1",
    //     "subCategoryDescription": "Sample1",
    //     "id":"2"

    // };
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [expenseData, setExepensedata] = useState({})
    useEffect(() => {
        if(applyFilter == true || ActiveMonthFilter !== ''){ 
            ExpensesList()
        }
    }, [applyFilter,ActiveMonthFilter])

    const handleDelete = (data) => {
        setLoader(true)
        deleteExpenseSubCategoryById(data.id,(callback)=>{
            setLoader(false)
            if(callback?.status == 200){
                ExpensesList()
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };
   
    const ExpensesList =() =>{
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getAllExpenseSubCategory('dateRequired',formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback?.status == 200 && callback?.data?.length > 0){
                setExepensedata(callback.data)
            }else{
                setExepensedata([])
            }
        })
        applyFilterState()
    }
    return (
        <div className="container Executor">
             {loader ? <Loader /> : ''}
                {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
                {expenseData !== '' && expenseData?.length > 0 ?
            <div
                className=""
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Sub Category Name</th>
                                <th>Sub Category Type</th>
                                <th>Category Sub Description</th>

                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                            return(

                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                <td >{data?.subCategoryName}</td>
                                <td >{data.subCategoryType}</td>
                                <td>{data.subCategoryDescription}</td>
                                <td>
                                    <i
                                        className="fa fa-pencil-square-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => editHandler(data)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Edit Expense"
                                        title="Edit Expense"
                                    ></i>
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => handleDelete(data)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Delete Expense"
                                        title="Delete Expense"
                                    ></i>
                                </td>
                            </tr>
                        )}):""}
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="text-center" style={{marginTop: '50px'}}>
            <img src={NodataImage}  width={'40%'}/>
            </div>
            }
        </div>
    );
};

export default SubCategoryList;
