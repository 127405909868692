import React, { useState,useEffect } from 'react'
import { getOverdueEMIPortfolioAPI } from './DebtListActionCreator';
import Loader from '../../../presentationals/Loader/Loader.component';
export default function LoanPortfolioReview({nbfcList}) {
    const [loader ,setLoader] =useState(false);
    const [nbfcFilter, setnbfcFilter] = useState('')
    const [portfolioData,setPortfolioData] =useState([])
    const [portfolioTotalData,setPortfolioTotalData] =useState({})
    const [data, setData] = useState({
        data: [
            {
                overdueDay: 'current',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '678.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '1-7 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '678.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '8-14 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '15-30 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '31-60 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: '61-90 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '36.74',
                outstandingBalancePercentage: 97.39
            },
            {
                overdueDay: 'Over 90 days',
                borrowerNumber: 220,
                clientPercentage: 97.26,
                amountLeft: null,
                percentageInAreas: 0.00,
                outstandingBalance: '3678',
                outstandingBalancePercentage: 97.39
            }
        ],
        Total: {
            borrowerNumber: 1540,
            clientPercentage: 680.82,
            amountLeft: null,
            percentageInAreas: 0.00,
            outstandingBalance: 5083.44,
            outstandingBalancePercentage: 951.73
        }
    })

    const calculateSubtotal = () => {
        const subtotal = data.data.reduce((acc, curr) => {
            if (curr.overdueDay === 'current') return acc;

            acc.borrowerNumber += curr.borrowerNumber;
            acc.clientPercentage += curr.clientPercentage;
            acc.amountLeft += curr.amountLeft ? parseFloat(curr.amountLeft) : 0;
            acc.percentageInAreas += curr.percentageInAreas;
            acc.outstandingBalance += parseFloat(curr.outstandingBalance);
            acc.outstandingBalancePercentage += curr.outstandingBalancePercentage;

            return acc;
        }, {
            borrowerNumber: 0,
            clientPercentage: 0,
            amountLeft: 0,
            percentageInAreas: 0,
            outstandingBalance: 0,
            outstandingBalancePercentage: 0
        });

        subtotal.clientPercentage = subtotal.clientPercentage.toFixed(2);
        // subtotal.clientPercentage /= (data.data.length - 1);
        // subtotal.outstandingBalance = subtotal.outstandingBalance.toFixed(2);

        return subtotal;
    };
    

    const subtotal = calculateSubtotal();

    useEffect(()=>{
        APIFunction()
    },[])
    
    const APIFunction=()=>{
        getOverdueEMIPortfolioFunction()

    }

    const textNullfn = (text) =>{
        if(text == null || text == undefined || text == ''){
            return '-'
        }else{
                return text;
        }
    }
    const handleChange = (e) => {

        setnbfcFilter(e.target.value)

    };

    const getOverdueEMIPortfolioFunction = ()=>{
        setLoader(true)
        getOverdueEMIPortfolioAPI(nbfcFilter,'',(callback)=>{
            setLoader(false)
            console.log(callback)
            if (callback && callback?.status == 200) {
                setPortfolioData(callback?.data?.data)
                setPortfolioTotalData(callback?.data?.total)
            }
        })
    }
    return (
        <>
            <div className="LoanPortfolio" style={{borderRadius:'16px',padding:'20px'}}>
                {
                    loader ?
                    <Loader />
                    :''
                }
            <div className="MonthDateFilter" style={{marginBottom:window.innerWidth > 1800 ? '50px':'30px'}}>
                    <div className="row">

                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={(e) => handleChange(e, 'nbfcFilter')} style={{ width: '100%' }} >
                                    <option value="">ALL</option>
                                    {
                                    nbfcList && nbfcList?.length > 0 ? nbfcList?.map((data, index) => {
                                        return (
                                            <option value={data?.nbfcId}>{data?.name}</option>
                                        )
                                    })
                                        : ''}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply' onClick={() => APIFunction()}>Apply Filter</button>
                        </div>

                    </div>
                </div>
                <div className="phocket-table-new TableNew">
                    <table>
                        <thead>
                            <tr>
                                <th>OverDue Days</th>
                                <th>Number Of EMI</th>
                                <th>Overdue Amount</th>
                                <th>Principal</th>
                                <th>Interest</th>
                                <th>Penalty</th>
                                <th>Bounce</th>
                            </tr>
                        </thead>
                        <tbody>
                            {portfolioData.map((data, index) => (
                                <tr key={index}>
                                    <td>{textNullfn(data.interval)}</td>
                                    <td>{textNullfn(data.emiCount)?.toLocaleString("en-IN") || 0}</td>
                                    <td>{textNullfn(data.overdueAmount)?.toLocaleString("en-IN") || 0}</td>
                                    <td>{textNullfn(data.principal)?.toLocaleString("en-IN") || 0}</td>
                                    <td>{textNullfn(data.interest)?.toLocaleString("en-IN") || 0}</td>
                                    <td>{textNullfn(data.penalty)?.toLocaleString("en-IN") || 0}</td>
                                    <td>{textNullfn(data.bounce)?.toLocaleString("en-IN") || 0}</td>
                                </tr>
                            ))}
                        </tbody>
                        <thead>
                            <tr>
                                <th>Total</th>
                                <th>{textNullfn(portfolioTotalData.totalCount)?.toLocaleString("en-IN") || 0}</th>
                                <th>{textNullfn(portfolioTotalData.totalOverdue)?.toLocaleString("en-IN") || 0}</th>
                                <th>{textNullfn(portfolioTotalData.totalPrincipal)?.toLocaleString("en-IN") || 0}</th>
                                <th>{textNullfn(portfolioTotalData.totalInterest)?.toLocaleString("en-IN") || 0}</th>
                                <th>{textNullfn(portfolioTotalData.totalPenalty)?.toLocaleString("en-IN") || 0}</th>
                                <th>{textNullfn(portfolioTotalData.totalBounce)?.toLocaleString("en-IN") || 0}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </>
    )
}
