import React,{useEffect, useState} from 'react'
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import Loader from '../../../presentationals/Loader/Loader.component';
import Nodataimg from '../../../../images/nodata.png'
export default function PaymentPopup({tablePopupData, close, tabName,paymentcollectedFilterFunction,paymentCollectedFilter,APIFunction }) {
    const itemsPerPage = 10;
    const todayDate = moment().format('YYYY-MM-DD');

    const [loader ,setLoader] =useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const paginatedData =tabName == 'PaymentsCollected' ? tablePopupData?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : '';

    const dateformatFn = (date) => {
        if (date === '' || date === undefined || date == null) {
            return '-'
        } else {
            const newDate = date.split(' ')[0]
            return newDate
        }

    }

    useEffect(()=>{
        if(tablePopupData){
            setLoader(false)
        }
    },[tablePopupData])

    const handleFilters =()=>{
        setLoader(true)
        APIFunction()
    }
  return (
    <>
     <div className="background-blur " onClick={close}></div>
     {
                tabName == 'PaymentsCollected' ?
                    <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: '-10px', width: '100%', maxWidth: '90%' }}>
                        <div className="popup-content income">
                            <div className='text-center'>
                                <h4>{paymentCollectedFilter.text || ''}</h4>
                            </div>

                            <div className="filters" style={{marginBottom:'30px'}}>
                                <div className="row">
                                    <div className="col-lg-2 col-md-6 col-xs-12">
                                        <label className='fromDate' style={{display:'block'}}>From</label>
                                        <input type="date" className='selectBox2' id="customDateInput" name="fromDate" value={paymentCollectedFilter.fromDate} onChange={(e)=>paymentcollectedFilterFunction(e)} style={{ width: '100%' ,padding:'10px'}} />
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-xs-12">
                                        <label className='toDate' style={{display:'block'}}>To</label>
                                        <input type="date" className='selectBox2' id="customDateInput" name="toDate" value={paymentCollectedFilter.toDate} min={paymentCollectedFilter.fromDate} max={todayDate} onChange={(e)=>paymentcollectedFilterFunction(e)} style={{ width: '100%',padding:'10px' }} />
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-xs-12">
                                    <button className='ApplyFilter' style={{marginTop:window.innerWidth > 1800 ? '26px':'22px'}} onClick={()=>handleFilters()}>Apply Filter</button>
                                    </div>
                                </div>
                            </div>
                            {
                                loader ? 
                                <Loader />
                                :''
                            }

                            {
                                tablePopupData && tablePopupData?.length > 0 ?
                                <>
                              
                            <div className="tables" style={{ marginTop: '10px' }}>
                                <div className="phocket-table-new newTable2" style={{ overflowY: 'auto', maxHeight: window.innerWidth > 1800 ? 'fit-content' : '490px' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                {/* <th className="text-center">View</th> */}
                                                <th className="text-center">Application Id</th>
                                                <th className="text-center">Name</th>
                                                <th className="text-center">Paid Amount</th>
                                                <th className="text-center">Due Date</th>
                                                <th className="text-center">Paid Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedData?.map((data, index) => (
                                                <tr key={index}>
                                                    {/* <td className="text-center"><h6 onClick={() => handleParticularDataView(data)} style={{ cursor: 'pointer' }}><i class="fa fa-info-circle fa-lg" aria-hidden="true"></i></h6></td> */}

                                                    <td className="text-center" style={{padding:window.innerWidth > 1800 ? '20px 7px':'5px 10px'}}>{data?.applicationId || '-'}</td>
                                                    <td className="text-center" style={{padding:window.innerWidth > 1800 ? '20px 7px':'5px 10px'}}>{data?.name || '-'}</td>
                                                    <td className="text-center" style={{padding:window.innerWidth > 1800 ? '20px 7px':'5px 10px'}}>{data?.paidAmount?.toLocaleString("en-IN") || '-'}</td>
                                                    <td className="text-center" style={{padding:window.innerWidth > 1800 ? '20px 7px':'5px 10px'}}>{dateformatFn(data?.dueDate)}</td>
                                                    <td className="text-center" style={{padding:window.innerWidth > 1800 ? '20px 7px':'5px 10px'}}>{dateformatFn(data?.paidDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Pagination
                                    count={Math.ceil(tablePopupData?.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </div>
                            </>
                                :

                                <div className='text-center'>
                                    <img src ={Nodataimg} width={'40%'} />
                                </div>
                            }
                        </div>
                    </div>
                    : ''}
    </>
  )
}
