import React from 'react';
import ReactTable from "react-table";
import { STRING } from '../../../../utils/Constant'
import DatePicker from 'react-datepicker';
import DotLoader from '../../Loader/layerLoader.component'
import Workbook from 'react-excel-workbook'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import { ROLE } from '../../../../utils/Constant';
import { Pagination } from '@mui/material';

const PhocketCustomerDetails = ({ countData, forwordPageState, paginationHandler, handleChangeNewPagination, pageNumber, userDetailHandler, userViewHandler, dataFilter,
    forwordpage, backpage, allUserDetail, admin, loadarState, refreshpage, dateToShow,
    loanVarificationHandler, loanTXNHandler, getFilterValue, searchBymobile, recoveryComunicateHandler,
    FilteredData, getPageValue, pageIndexToShow, searchByAppNo, searchByemail, dateToSend, tableMaxPagination, tableMinPagination,
    paymentDeductHandler, nachTypeHandler, phocketTablePagination }) => (
    <div className="container-fluid">
        <div className="row">
            <h3 className="text-center blue-text">Incoming Payment</h3>
        </div>
        <div className="row">
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> By Date</label>
                <DatePicker
                    selected={dateToShow}
                    value={dateToSend}
                    onChange={e => dataFilter(e, "Date")}
                    className="form-control"
                    dateFormat="DD-MM-YYYY"
                />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Application No.</label>
                <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                <input type="email" className="form-control" value={searchByemail} onChange={e => dataFilter(e, "email")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                <input type="number" value={searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Nach Type</label>
                <select className='form-control' onChange={(e) => nachTypeHandler(e)}>
                    <option value=''>All</option>
                    <option value='razorpay'>Razorpay</option>
                    <option value='etech'>Etech</option>
                    <option value='manual enach'>Manual Enach</option>
                </select>
            </div>
            <div className="col-sm-2 col-xs-12">
                <Workbook filename="ApplicationStatus.xlsx" element={<button className="profile-save-btn " style={{ marginTop: '22px' }}><span className="fa fa-download"></span>&nbsp;&nbsp;Excel</button>}>
                    <Workbook.Sheet data={allUserDetail} name="Sheet A">
                        <Workbook.Column label="Loan Id" value="applicationLoanId" />
                        <Workbook.Column label="Application Id" value="applicationId" />
                        <Workbook.Column label="UserName" value="userName" />
                        <Workbook.Column label="Contact" value="mobileNumber" />
                        <Workbook.Column label="Disburse Date" value="disburseDate" />
                        <Workbook.Column label="Payback Date" value="loanPaybackDate" />
                        <Workbook.Column label="Payback Amount" value="loanPaybackAmount" />
                        <Workbook.Column label="Loan Amount" value="loanAmount" />
                        <Workbook.Column label="Tenure" value="loanDuration" />
                    </Workbook.Sheet>
                </Workbook>
                &nbsp;
                {/* <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button> */}
                {/* <p>1-100</p> */}
            </div>
            <br />
            <br />
        </div>

        {countData !== '' ?
            <div className='row'>
                <div className='col-sm-3 col-xs-12'>
                    <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                        Total Payment  <br />
                        <h3 style={{ color: 'grey', fontWeight: '700' }}> <i className='fa fa-rupee'></i>&nbsp;{countData.totalPayment !== undefined ? Number(parseFloat(countData.totalPayment).toFixed(2)).toLocaleString('en-IN') : "0"}</h3>
                    </div>
                </div>
               
                <div className='col-sm-3 col-xs-12'>
                    <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                        Total Principal <br />
                        <h3 style={{ color: 'grey', fontWeight: '700' }}> <i className='fa fa-rupee'></i>&nbsp;{countData.totalPrincipal !== undefined ? Number(parseFloat(countData.totalPrincipal).toFixed(2)).toLocaleString('en-IN') : "0"}</h3>

                    </div>
                </div>
                <div className='col-sm-3 col-xs-12'>
                    <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                    Total Interest <br />
                        <h3 style={{ color: 'grey', fontWeight: '700' }}><i className='fa fa-rupee'></i>&nbsp;{countData.totalInterest !== undefined ? Number(parseFloat(countData.totalInterest).toFixed(2)).toLocaleString('en-IN') : "0"} </h3>
                    </div>
                </div>
                <div className='col-sm-3 col-xs-12'>
                    <div className='shadow-card' style={{ marginTop: '30px', fontWeight: '700' }}>
                    Processing Fee  <br />
                        <h3 style={{ color: 'grey', fontWeight: '700' }}><i className='fa fa-rupee'></i>&nbsp;{countData.processingFee !== undefined ? Number(parseFloat(countData.processingFee).toFixed(2)).toLocaleString('en-IN') : "0"}</h3>

                    </div>
                </div>



            </div>
            : ""}

        {/*  */}
        {loadarState === false ?
            <div className="row text-center">
                {allUserDetail && allUserDetail.length > 0 ?
                    <div className="overflow-x-auto phocket-table-new">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Communicate</th>
                                    <th>Application Id</th>
                                    <th>Loan Id</th>
                                    <th>Name</th>
                                    <th>Mobile No.</th>
                                    <th>Disburse Date</th>
                                    <th>PayBack Date</th>
                                    <th>Amount</th>
                                    <th>Repayment Amount</th>
                                    <th>Nach Status</th>
                                    <th>Installment </th>
                                    <th>Tenure</th>
                                    <th>status</th>
                                    {/* {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                    <th>Deduct Payment</th>
                                    : ""}
                                <th>Verification</th>
                                <th>Schedule</th> */}

                                </tr>

                            </thead>
                            <tbody>
                                {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    return (
                                        <tr key={i} >
                                            <td>
                                                <button onClick={e => recoveryComunicateHandler(data.loanId)}><span className="fa fa-envelope-o" /></button>                                                <label htmlFor={"ignore_" + data.loanId}><span></span></label>
                                            </td>
                                            <td> {data.applicationId}</td>
                                            <td> {data.applicationLoanId}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>  {data.userName}</td>
                                            <td> {data.mobileNumber}</td>
                                            <td> {data.disburseDate !== null && data.disburseDate !== '' ? (data.disburseDate).split(' ')[0] : ""}</td>
                                            <td> {data.loanPaybackDate !== null && data.loanPaybackDate !== '' ? (data.loanPaybackDate).split(' ')[0] : ""}</td>
                                            <td> {data.loanAmount}</td>
                                            <td> {data.loanPaybackAmount}</td>
                                            <td>  <div>{data.nachStatus == true ? 'Active' : 'Inactive'}</div> </td>
                                            <td> {data.loanEmi}</td>
                                            <td>{data.loanDuration} </td>
                                            <td>{data.applicationStatus}</td>
                                            {/* {admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                            <td>
                                                <button onClick={e => paymentDeductHandler(data)}><span className="fa fa-credit-card" /></button></td>
                                            : ""}
                                        <td> <button onClick={e => loanVarificationHandler(data.loanId)}><span className="fa fa-viacoin" /></button></td>
                                        <td><button onClick={e => loanTXNHandler(data.loanId)}><span className="fa fa-credit-card" /></button></td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    : <div></div>}
                {/* {allUserDetail ? allUserDetail.length > 0 && loadarState === false ?
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block mage" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button></div>
                        <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}></button></div>
                        <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                        <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}></button></div>
                        <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block mage" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button></div> </div>
                </div>
                : "" : ""} */}
                <div style={{ float: 'right', marginBottom: '15px', marginTop: '10px', display: 'flex' }}>
                    {forwordPageState > 1 ? <div className='grey-circle' onClick={e => paginationHandler('left')}><i className="fa fa-chevron-left" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div> : ""}  <Pagination size='large' onChange={handleChangeNewPagination} color="primary"
                        count={Math.ceil(allUserDetail.length / 10)}
                        page={pageNumber} />
                    {/* {Math.ceil(allUserDetail.length / 10) === 10 ?  */}
                    <div className='grey-circle' onClick={e => paginationHandler('right')}><i className="fa fa-chevron-right" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div>
                    {/* //  : ""} */}

                </div>
            </div >
            : ""}
        {loadarState == true ?
            <DotLoader />
            : ""}
    </div >
)

export default PhocketCustomerDetails;
