import React, { useState } from 'react'
import accountreconciliaitonimg from './images/accountreconciliaiton.png';
import MandateReportimg from './images/MandateReport.png';
import AccountReconciliaitonPage from './AccountReconciliaitonPage';
import MandateReport from './MandateReport';

const tabStyle = {
    width: 'calc(100%/2)',
    background: 'rgb(219, 233, 255)',
    cursor: 'pointer',
    color: '#000',
    padding: '12px'
};
const activeTabStyle = {
    width: 'calc(100%/2)',
    cursor: 'pointer',
    padding: '12px',
    background: '#006c67',
    color: '#fff',
};
const h5Style = {
    textAlign: 'center',
    fontSize: '17px',
    marginBottom:'0px'
}

export default function ARMainTab({ admin, allStatus, newDataBackground, pageViewHandler }) {
    const [tab, setTab] = useState('accountreconciliation');

    const handlepagechange = (type) => {
        setTab(type)
    }
    return (
        <>
            <div className="" style={{ marginTop: '-12px' }}>

                <div className='topTabs' style={{ display: 'flex', width: '100%' }}>
                    <div className="tabs" style={tab === "accountreconciliation" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('accountreconciliation')}>
                        <h5 style={h5Style}>Account Reconciliation</h5>
                    </div>
                    <div className="tabs" style={tab === "unveried" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('unveried')}>
                        <h5 style={h5Style}>Unverified Payments</h5>
                    </div>
                </div>
                {/* <div className="row text-center"> */}

                    {/* <div className='' style={{ background: '#1D3557', height: '100%', width: '5%', minWidth: '100px', position: 'fixed' }}> */}


                        {/* <div className="aggDetailsTab" style={{ color: '#fff', marginTop: '15px', cursor: 'pointer' }} > */}
                            {/* <a
                                className="tabText"
                                style={{ color: '#fff', fontSize: '10px' }}
                                onClick={() => handlepagechange('accountreconciliation')}
                            >
                                <div className={tab === 'accountreconciliation' ? 'active-box' : 'inactive-box'}> */}
                                    {/* <i class="fa fa-users" aria-hidden="true"></i> */}
                                    {/* <img src={accountreconciliaitonimg} width={'100%'} alt="" />
                                </div>
                                Account Reconciliation
                            </a>
                        </div> */}
                        {/* <div className="aggDetailsTab" style={{ color: '#fff',marginTop:'15px',cursor:'pointer' }} >
                    <a
                        className="tabText"
                        style={{ color: '#fff' ,fontSize: '10px' }}
                        onClick={() => handlepagechange('MandateReport')}
                    >
                        <div className={tab === 'MandateReport' ? 'active-box' : 'inactive-box'}>
                            <img src={MandateReportimg} width={'100%'} alt="" />
                        </div>
                       
                        Mandate<br/> Report
                    </a>
                </div> */}
                        {/* <div className="aggDetailsTab" style={{ color: '#fff', marginTop: '15px', cursor: 'pointer' }} >
                            <a
                                className="tabText"
                                style={{ color: '#fff', fontSize: '10px' }}
                                onClick={() => handlepagechange('unveried')}
                            >
                                <div className={tab === 'unveried' ? 'active-box' : 'inactive-box'}>
                                    <img src={MandateReportimg} width={'100%'} alt="" />
                                </div>

                                Unverified<br /> Payments
                            </a>
                        </div> */}

                    {/* </div> */}
                {/* </div> */}
                <div>
                {/* <div style={{ width: 'calc(95%-100px)', marginLeft: '100px' }}> */}

                    {tab === 'accountreconciliation' ?
                        <AccountReconciliaitonPage pageViewHandler={pageViewHandler} />
                        : ""}
                    {tab === 'MandateReport' ?
                        <MandateReport />
                        : ""}

                </div>
            </div>
        </>
    )
}