import React, { useEffect, useState } from "react";
import { getPendingForKycLeadsApi } from "../AdminActionCreator.component";
import moment from "moment";
import { Pagination } from "@mui/material";
import Loader from '../../../presentationals/Loader/spinner'
import PopUp from "../../../presentationals/Popup/downloadKyc";
import SidePopup from "../../../presentationals/Popup/Popup.component";
const PendingKycLeads = ({ allStatus, admin }) => {
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [endDate, setendDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [page, setpage] = useState(1)
    const [size, setsize] = useState(15)
    const [leadsData, setLeadsData] = useState([])
    const [loaderState, setloaderState] = useState(false)
    const [totalCount, settotalCount] = useState('')
    const [downloadPopup, setDownloadPopup] = useState(false)
    const [loanStatus, setLoanStatus] = useState('')
    const [popupStatus, setPopupStatus] = useState('')
    const [popupState, setPopup] = useState(false)
    const [generateReportState, setgenerateReportState] = useState(true)
    const [type, settype] = useState(' ')






    useEffect(() => {
        getData(startDate, endDate, page, size, loanStatus, type)
        if (admin.emailId !== undefined && admin.role != "superuser" && admin.emailId !== 'vishnu.pandey@credfin.money' && admin.emailId !== 'sunil.kumar@credfin.money' && admin.emailId !== 'ravinder.sharma@credfin.money' && admin.emailId !== 'mohit.bansal@credfin.money' && admin.emailId !== 'priyanka.kumari@credfin.money' && admin.emailId !== 'piyush.jain@credfin.money' && admin.emailId !== 'rakesh.rawat@credfin.money') {
            var today = new Date().getHours();
            if (today > 19) {
                console.log(today, 'Hour')
            }
            if (today < 9) {
                console.log(today, 'Hours')
            }
            if (today > 9 && today < 19) {
                console.log(today)
                setgenerateReportState(false)
            }
        }
    }, [])
    const getData = (startDate, endDate, page, size, loanStatus, type) => {
        setloaderState(true)
        getPendingForKycLeadsApi(startDate, endDate, page, size, loanStatus, type, callBack => {
            if (callBack.data !== 'No Data Found') {
                setLeadsData(callBack.data)
            } else {
                setPopupStatus('No Data Found')
                setPopup(true)
                removePOpup()
                setLeadsData([])
                settotalCount(0)
            }
            settotalCount(callBack.totalCount)
            setloaderState(false)
        })
    }
    const removePOpup = () => {
        setTimeout(() => {
            setPopup(false)
        }, 5000);
    }
    const dateHandler = (e, type) => {
        console.log(e.target.value)
        if (type === 'start') {
            setStartDate(e.target.value)
        }
        if (type === 'end') {
            setendDate(e.target.value)
        }

    }
    const searchData = () => {
        getData(startDate, endDate, page, size, loanStatus,type)
    }
    const handleChangeNewPagination = (e, value) => {
        setpage(value)
        getData(startDate, endDate, value, size, loanStatus)

    };
    const downloadReport = () => {
        setDownloadPopup(!downloadPopup)

    }
    const closePopup = () => {
        setPopup(false)
    }
    return (
        <div className="container">
            {popupState ?
                <SidePopup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
            {downloadPopup ?
                <PopUp allStatus={allStatus} downloadReport={downloadReport} />
                : ""}
            <h3 className="text-center">KYC Pending Leads</h3>
            <div className="row">
                <div className="col-sm-2 col-xs-12 mt-3 mb-2">
                    <input className="form-control" value={startDate} type="date" onChange={(e) => dateHandler(e, 'start')} />
                </div>
                <div className="col-sm-2 col-xs-12 mt-3 mb-2">
                    <input className="form-control" value={endDate} type="date" onChange={(e) => dateHandler(e, 'end')} />
                </div>
                <div className="col-sm-2 col-xs-12">


                    <select onChange={(e) => setLoanStatus(e.target.value)} className='form-control'>
                        <option value={''}>Select Loan Status</option>
                        {allStatus && allStatus.map((data, i) => {
                            return (
                                <option value={data.statusId}>{data.adminStatus}</option>
                            )
                        })}
                    </select>

                </div>
                <div className="col-sm-2 col-xs-12">


                    <select value={type} onChange={(e) => settype(e.target.value)} className='form-control'>
                        <option value={'repeat'}>Repeat</option>
                        <option value='new'>New</option>
                        <option value=' '>All</option>

                    </select>

                </div>
                <div className="col-sm-1 col-xs-12">
                    <button className="btn btn-primary " style={{ marginTop: '1px', padding: '10px' }} onClick={() => searchData()}>Search</button>
                </div>
                {generateReportState == true ?
                    <div className="col-sm-1 col-xs-12">
                        <button className="btn btn-primary " style={{ marginTop: '1px', padding: '10px' }} onClick={() => downloadReport()}>Download</button>
                    </div>
                    : ""}
            </div>
            {
                loaderState === false && leadsData && leadsData.length > 0 ?
                    <div className=" overflow-x-auto phocket-table-new">
                        <table>
                            <thead>
                                <tr style={{ height: 'auto ' }}>
                                    <th>Application Id</th>
                                    <th>Loan Status</th>
                                    <th>Date</th>
                                    <th>Aadhaar Status</th>
                                    <th>PAN Status</th>
                                    <th style={{ textAlign: 'center' }}>Repeat</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadsData && leadsData.length > 0 ? leadsData.map((data, i) => {
                                    return (
                                        <tr>
                                            <td>{data.applicationId}</td>
                                            <td>{data.loanStatus}</td>

                                            <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                            <td ><div style={{ marginLeft: '20px' }}>{data.aadhaarStatus === false ? 'False' : data.aadhaarStatus === true ? 'True' : data.aadhaarStatus}</div></td>
                                            <td><div style={{ marginLeft: '20px' }}>{data.panStatus === 'Active' ? 'True' : data.panStatus === 'Inactive' ? 'False' : data.panStatus}</div></td>
                                            <td style={{ textAlign: 'center' }}>{data.repeatCustomer?<i className="fa fa-check" style={{color:'green',fontSize:'20px'}}></i>:<i style={{color:'red',fontSize:'14px'}} className="fa fa-times"></i>}</td>
                                        </tr>
                                    )
                                })

                                    : ""}
                            </tbody>
                        </table>

                        <div style={{ marginTop: '10px', float: 'right', marginBottom: '10px' }}>
                            <Pagination
                                // count={10}
                                count={Math.ceil(totalCount / 15 - 1)}
                                page={page}
                                onChange={handleChangeNewPagination}
                                color="primary" />
                        </div>
                    </div>
                    : ""
            }
            {
                loaderState ?
                    <Loader />
                    : ""
            }
        </div >
    )
}
export default PendingKycLeads