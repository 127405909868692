import React, { useState, useEffect } from "react";
import { getCheckPointsRepeatApi, updateRepeatConfig } from "../AdminActionCreator.component";

const FreshMandatory = () => {
    const [checks, setChecks] = useState({});
    const [loading, setLoading] = useState(true);

    const checkLabels = {
        PAN_Verified: "PAN Verified",
        Aadhaar_Linked_Number: "Aadhaar Linked Number",
        Mandate: "Mandate",
        If_treated_as_a_new_customer: "Treated as New Customer",
        Organization_Verification: "Organization Verification",
        AML_Check: "AML Check",
        Bureau_Report: "Bureau Report",
        Scoring: "Scoring",
        Aadhaar_Verified: "Aadhaar Verified"
    };

    const handleToggle = (key) => {
        setChecks(prevChecks => {
            const newValue = !prevChecks[key].mandatory; 
            updateRepeatConfig(key, newValue, (response) => {
                console.log('Update response:', response);
                if(response==='success'){
                    getCheckPointsRepeatApi((response) => {
                        if (response && response.length > 0) {
                            const initialChecks = response.reduce((acc, { checkPoint, mandatory, status }) => {
                                acc[checkPoint] = { mandatory, status }; 
                                return acc;
                            }, {});
                            setChecks(initialChecks);
                            console.log(response);
                        } else {
                            console.error("No data received from the API or data is empty.");
                        }
                        setLoading(false);
                    });
                }

            });
            return {
                ...prevChecks,
                [key]: {
                    ...prevChecks[key],
                    mandatory: newValue
                }
            };
        });
    };

    useEffect(() => {
        getCheckPointsRepeatApi((response) => {
            if (response && response.length > 0) {
                const initialChecks = response.reduce((acc, { checkPoint, mandatory, status }) => {
                    acc[checkPoint] = { mandatory, status }; 
                    return acc;
                }, {});
                setChecks(initialChecks);
                console.log(response);
            } else {
                console.error("No data received from the API or data is empty.");
            }
            setLoading(false);
        });
    }, []);
    

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-fluid" style={{ marginTop: '40px' }}>
            <div className="col-sm-3 col-xs-12"></div>
            <div className="col-sm-6 col-xs-12">
                <div className="">
                    <div className="phocket-table-new">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ padding: '20px 8px' }}>Repeat Checking Points</th>
                                    <th style={{ padding: '20px 80px' }}>ON/OFF</th>
                                    <th style={{ padding: '20px 8px' }}>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log(checks)}
                                {Object.entries(checks).map(([key, { mandatory, status }]) => (
                                    <tr key={key}>
                                        <td>{checkLabels[key] || key.replace(/_/g, ' ')}</td>
                                        <td>
                                            <div className="new-switch">
                                                <div className="toggle-button-cover">
                                                    <div className="button-cover">
                                                        <div className="button b2" id="button-18">
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                checked={mandatory}
                                                                onChange={() => handleToggle(key)}
                                                            />
                                                            <div className="knobs">
                                                                <span></span>
                                                            </div>
                                                            <div className="layer"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-sm-3 col-xs-12"></div>
        </div>
    );
};

export default FreshMandatory;
