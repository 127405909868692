
import React, { Component } from 'react'
import UserBasic from '../../../presentationals/Admin/CustomerPages/detailingpage.component'
import BadaBuisnessBasic from '../../../presentationals/Admin/CustomerPages/badabuisnessbasicdetails.component'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { APIS } from '../../../../utils/api-factory';
import Loader from '../../../presentationals/Loader/Loader.component'
import CreditScore from './UserCreditScoring.container'
import Popup from '../../../presentationals/Popup/Popup.component';
import { _formatDateInDash } from '../../../../utils/validation';
import { userReferenceApi, sendverificationRequestApi, getdeactivateNachByLoanId, panProfileDetails, aadharVerificationDetails, aadharXmlDetails, postCallPanDetails, aadharXmlData, postCallDigilocker, postCallAdharVerification, veriFyReferenceDetails, bankVerificationApi, recentActivitiesApi, docUploadSaveMail, getBankVerifyDataApi, aadhaarConsentUserWithoutSource, getBankNameList, getDigitapBasicData } from '../AdminActionCreator.component'
import Confirmation from '../../../presentationals/Popup/ConfirmationPopup.component'
import KarzaPOPUPUI from '../../../presentationals/Popup/KarzaPopup.component'
import AAdharPopup from '../../../presentationals/Popup/KarzaAddharPopup.component'
import PersonalEdit from './UserEdit/personaldetails.container';
import IndentityEdit from './UserEdit/IdentityInformation.container';
import EmploymentEdit from './UserEdit/employment.container';
import AddressEdit from './UserEdit/address.container';
import BankEdit from './UserEdit/bankdetails.conatiner';
import ReferenceEdit from './UserEdit/reference.conatiner';
import DocumentEdit from './UserEdit/document';
import Action from './NewActionHandle';
import IncompletePopup from '../../../presentationals/Popup/incompletecommentpopup'
import ConfirmationPopup from '../../../presentationals/Popup/markcompletePopup'
import ErrorPopup from '../../../presentationals/Popup/aadhaarerrorpopup'
import ConfirmationPopupPanUi from '../../../presentationals/Popup/panConfirmPopup'
import AgreementUser from './UserAgreement.container';
import { getAnalysisDataApiNew, updateLatestStatementOrNot } from '../Analysis/analysisactionCreator';
import BankNameList from '../../../presentationals/Popup/BankNameList';

// import EditProfile from '../../../containers/Admin/CustomerPages/EditUserDetail.container'
// import Documents from '../../../containers/Admin/CustomerPages/UserDocs.container'
var result = [];
class BasicUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            verificationType: '',
            aadharVerificationData: '',
            typeOfData: '',
            paninfo: false,
            aadharInfo: false,
            karzaAdharData: '',
            karzaData: '',
            addharPopup: false,
            panPopup: false,
            // panProfileDetail:'',
            panVerifiedstate: false,
            inputFeildState: '',
            nameOnPan: '',
            confirmationPopup: false,
            confirmationText: '',
            loaderState: false,
            creditScoringPopup: false,
            userScore: '',
            popupState: false,
            popupStatus: '',
            senderMailId: '',
            allDetail: this.props.allDetail,
            mailIdForVerification: '',
            randomValues: {
                first: 0,
                second: 0,
                third: 0,
                fourth: 0,
                five: 0,
                six: 0,
                total: 0
            },
            linkState: false,
            // pageViewState: 'msgPage'
            vreedhiData: '',
            appVersion: '',
            userReference: '',
            confirmationPopupIncomplete: false,
            deactivateNach: false,
            bankData: '',
            maskAadhar: '',
            aadharDataFromKarza: '',
            bankVerificationData: '',
            documentsData: '',
            typeofDocument: '',
            adminDocument: false,
            automationDocument: false,
            editType: '',
            recentActiviesData: '',
            markButton: false,
            confirmationPopupinCompleteState: false,
            invalidMsg: '',
            confirmationPopupState: false,
            showparse: true,
            jsonErrorData: '',
            errorPopupstate: false,
            confirmationPopupPan: false,
            agreement: false,
            latestStatement: '',
            newKycData: '',
            addressData: '',
            bankNameList: [],
            bankNameListPopup: false,
            bankResponseFromVerify:'',
            digitapBankData: '',
            showVerifyButton: true

        }
    }
    aadharconscent = () => {
        const { allDetail } = this.props
        if (allDetail.nameAsOnPan !== null && allDetail.firstName !== undefined && allDetail.firstName !== '') {
            aadhaarConsentUserWithoutSource(allDetail.userId, allDetail.firstName, callBack => {
                console.log(callBack)
            })
        }
    }
    saveDocName(e, url) {
        const { allDetail } = this.props
        docUploadSaveMail(e.target.value, url, allDetail.userId, callBack => {
            if (callBack == 'success') {
                this.setState({
                    popupState: true,
                    popupStatus: 'Saved Successfully'
                })
                this.removePopup()
            }
        })
    }
    saveLatestStatement() {
        const { allDetail } = this.props
        let data = {
            userId: allDetail.userId,
            loanId: allDetail.loanId,
            status: this.state.latestStatement
        }

        updateLatestStatementOrNot(data, callback => {
            console.log(callback)
        })

    }
    incompleteFunction(buttonType) {
        const { admin, allDetail, userDetail } = this.props

        if (buttonType == 'yes') {
            fetch(APIS.MARK_APPLICATION_INCOMPLETE + allDetail.userId + '&loanId=' + allDetail.loanId + '&giftCardId=' + userDetail.giftCardId + '&incompleteType=' + this.state.incompleteType + '&empId=' + admin.emailId)
                .then(res => res.text())
                .then(res => {
                    if (res == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Marked Successfully',
                            confirmationPopupinCompleteState: false,
                            markButton: false

                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                            confirmationPopupinCompleteState: false
                        })
                        this.removePopup();
                    }
                })

        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupinCompleteState: false
            })
        }
    }
    buttonsForFunction(type) {
        if (type === 'complete') {
            this.setState({
                confirmationPopupState: true, confirmationText: 'Are you sure to Mark this application complete ?'
            })

        }
        if (type === 'incomplete') {
            this.setState({ confirmationPopupinCompleteState: true, confirmationText: 'Are you sure to Mark this application Incomplete ?' })
        }
    }
    disableEsign() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        fetch(APIS.DISABLE_ESIGN_API + allDetail.userId)
            .then(res => res.text())
            .then(res => {
                if (res === 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Done Successfully',
                        loaderState: false,
                        showparse: false
                    })
                    this.removePopup()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again later!',
                        loaderState: false
                    })
                    this.removePopup()
                }

            })

    }

    markStatusButton() {
        this.setState({ markButton: !this.state.markButton })
    }
    sendToFinb() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        fetch(APIS.UPLOAD_STATEMENT_FOR_PARSING + allDetail.userId)
        .then(res => res.text())
        .then(res => {
            let parsedRes = JSON.parse(res);
                if (parsedRes && parsedRes.status === 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Passed Successfully',
                        loaderState: false,
                        showparse: false
                    })
                    this.removePopup()
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again later!',
                        loaderState: false
                    })
                    this.removePopup()
                }

            })
    }
    sendToDigitap() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })


        fetch(APIS.UPLOAD_STATEMENT_FOR_PARSING_DIGITAP + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                if (res.status === 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Passed Successfully',
                        loaderState: false,
                        showparse: false
                    })
                    this.removePopup()
                    this.getDocs()

                } else if (res && res?.message) {
                    this.setState({
                        popupState: true,
                        popupStatus: res.message,
                        loaderState: false,
                    })
                    this.removePopup()
                }
                else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again later!',
                        loaderState: false
                    })
                    this.removePopup()
                }

            })
        // fetch(APIS.UPLOAD_STATEMENT_TO_FINBIT + allDetail.userId)
        //     .then(res => res.text())
        //     .then(res => {
        //         if (res === 'success') {
        //             this.setState({
        //                 popupState: true,
        //                 popupStatus: 'Passed Successfully',
        //                 loaderState: false
        //             })
        //             this.removePopup()
        //         } else {
        //             this.setState({
        //                 popupState: true,
        //                 popupStatus: 'Please try again later!',
        //                 loaderState: false
        //             })
        //             this.removePopup()
        //         }

        //     })
    }
    getrecentActivitiesData() {
        const { allDetail } = this.props
        recentActivitiesApi(allDetail.loanId, callback => {
            this.setState({ recentActiviesData: callback })
        })

    }
    bankVerificationApiFunction() {
        const { allDetail } = this.props
        this.setState({ loaderState: true })
        bankVerificationApi(this.state.bankData.ifscCode, this.state.bankData.accountNumber, this.state.bankData.nameAsBankAccount, allDetail.userId, callback => {
            if (callback !== 'null') {
                this.setState({ bankVerificationData: callback, loaderState: false })
                this.getbankVerifyData()
            }
            if (callback === 'null') {
                this.setState({ loaderState: false, popupState: true, popupStatus: 'Failed to verify !' }, () => this.removePopup())

            }
        })
    }
    getbankVerifyData() {
        const { allDetail } = this.props

        getBankVerifyDataApi(allDetail.userId, callback => {
            this.setState({ bankVerificationData: callback })
            if(callback && callback?.bankResponse){
                let x = JSON.parse(callback.bankResponse)
this.setState({bankResponseFromVerify:x.message})
            }
        })
    }
    getDocs() {
        const { allDetail } = this.props
        fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                this.setState({ documentsData: res })
                console.log(res, 'i765ewertyuio')
                if (res.parsedByNetbanking === true) {

                    getDigitapBasicData(allDetail.userId, callBack => {
                        console.log(callBack)
                        this.setState({ digitapBankData: callBack })
                        if ((callBack.accountNumber === this.state.bankData.accountNumber) && (callBack.ifscCode === this.state.bankData.ifscCode)) {
                            console.log('yes')
                            this.setState({ showVerifyButton: false })
                        } else {
                            console.log(this.state.bankData)
                            this.setState({ showVerifyButton: true })

                            // alert('not matched')
                        }
                    })
                }
            })
    }
    changeVeriFyStatus(data, i) {
        let typeToSend = i === 0 ? 'one' : i === 1 ? 'two' : i === 2 ? 'three' : ''
        const { admin, allDetail, userDetail } = this.props
        if (typeToSend !== '') {
            this.setState({ loaderState: true })
            veriFyReferenceDetails(allDetail.userId, true, data.number, typeToSend, admin.emailId, allDetail.loanId, callBack => {
                this.setState({ loaderState: false }, () =>
                    userReferenceApi(allDetail.userId, callBack => {
                        this.setState({ userReference: callBack })
                    }))
            })
        }
    }
    newconfilrmation(buttonType) {
        const { admin, allDetail, userDetail } = this.props

        if (buttonType == 'yes') {
            fetch(APIS.MARK_APPLICATION_COMPLETE + allDetail.userId + '&loanId=' + allDetail.loanId + '&giftCardId=' + userDetail.giftCardId)
                .then(res => res.text())
                .then(res => {
                    if (res == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Marked Successfully',
                            confirmationPopupIncomplete: false,
                            markButton: false
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again',
                            confirmationPopupIncomplete: false
                        })
                        this.removePopup();
                    }
                })

        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupIncomplete: false
            })
        }
    }
    newconfilrmationPan(buttonType) {
        const { admin, allDetail, userDetail } = this.props

        if (buttonType == 'yes') {
            this.karzaPopup('', 'pan')

        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupPan: false
            })
        }
    }
    markincompleteapplication(buttonType) {
        const { allDetail } = this.props
        this.setState({ confirmationPopupIncomplete: true, confirmationText: 'Are You Sure To Mark Incomplete this application ?' })


    }

    userLinkHandler(data) {
        if (data == 'yes') {
            this.setState({ linkState: true })
        } else if (data == 'no') {
            this.setState({ linkState: false })
        }
    }
    getRandomNumber() {
        var letters = '012345';
        var number = '';

        for (var i = 0; i < 5; i++) {
            number = letters[Math.floor(Math.random() * 4)];
        }
        var sum = 0
        for (var j = 0; j < result.length; j++) {
            sum += Number(result[j]);
        }
        if (sum > 14) {
            this.getRandomNumber()
        }

        result.push(number)
        return number;

    }
    sumNumber() {
        var finalresult = result.reduce((a, b) => Number(a) + Number(b), 0)
        result = []
        return finalresult
    }
    getpanProfileDetailsData(userId) {
        panProfileDetails(userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {
                this.setState({ karzaData: callBack })
            }
        })
    }
    getuserReference() {
        const { allDetail, userDetail } = this.props;

        userReferenceApi(allDetail.userId, callBack => {
            this.setState({ userReference: callBack })
        })
    }
    componentDidMount() {

        this._isMounted = true;
        const { allDetail, userDetail } = this.props;
        if (allDetail && allDetail.firstName) {
            this.aadharconscent()
        }
        if (allDetail && allDetail.aadharNumber) {
            let data = this.maskAadharNumber(allDetail.aadharNumber)
            this.setState({ maskAadhar: data })
        }
        aadharVerificationDetails(allDetail.userId, callBack => {
            this.setState({ aadharVerificationData: callBack, })
        })
        this.getrecentActivitiesData()
        this.getanalysisData()
        panProfileDetails(allDetail.userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {

                this.setState({ karzaData: callBack, paninfo: true })
            } else {

            }
        })

        aadharXmlData(allDetail.userId, callBack => {
            if (callBack !== '' && callBack !== null) {
                // aadharXmlDetails(allDetail.userId, callBack => {
                this.setState({ typeOfData: callBack.type, aadharDataFromKarza: callBack })

                // })
                this.setState({ jsonErrorData: JSON.parse(callBack.error) })
                if (callBack.status === true) {
                    this.setState({ aadharInfo: true })
                }


            }
        })
        // if (this._isMounted) {
        //     fetch(APIS.GET_LOAN_SCORE + allDetail.loanId)
        //         .then(res => res.json())
        //         .then(json => {
        //             this.setState({ userScore: json })
        //         })
        // }

        getdeactivateNachByLoanId(allDetail.loanId, callBack => {
            if (callBack == '') {

                this.setState({ deactivateNach: false })
            } else {
                this.setState({ deactivateNach: true })

            }
        })
        fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
            .then(res => res.json())
            .then(json => {

                this.setState({
                    appVersion: (json.appVersion),

                })
            })
        this.getuserReference()

        if (userDetail.loanFrom === "vreedhi") {
            fetch(APIS.GET_VREEDHI_DATA + userDetail.userId)
                .then(res => res.json())
                .then(json => {

                    this.setState({ vreedhiData: json })
                })
        }
        if (allDetail.panVerified == true) {
            this.setState({
                panVerifiedstate: true
            })
        } else {
            this.setState({
                panVerifiedstate: false
            })
        }
      

        
        this.getbankData()
        // this.getDocs()
        this.getbankVerifyData()
    }
    getbankData() {
        const { userDetail } = this.props
        fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + userDetail.userId)
            .then(res => res.json())
            .then(json => {
                this.setState({ bankData: json })
                this.getDocs()
                // , () => this.bankVerificationApiFunction())
            })
    }
    verifyHandler(e) {
        this.setState({
            nameOnPan: e.target.value

        })

    }
    saveVerify() {
        if (this.state.nameOnPan != '' && this.state.nameOnPan != null) {
            fetch(APIS.PAN_VERIFY_CUSTOMER + this.state.allDetail.userId + "&pan=" + this.state.allDetail.panNumber + "&nameOnPan=" + this.state.nameOnPan)
                .then(res => res.text())
                .then(json => {
                    if (json == 'success') {
                        this.setState({
                            panVerifiedstate: true,
                        })

                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: "PAN could not be verified!",
                            panVerifiedstate: false
                        }, () => this.removePopup())

                    }
                })

        } else {
            this.setState({
                popupState: true,
                popupStatus: "Enter Name as on Pancard"
            }, () => this.removePopup())
        }
    }
    openConfirmPopup(type) {
        this.setState({ confirmationPopupPan: true })

    }

    karzaPopup(e, type) {
        const { allDetail, userDetail } = this.props;
        if (type === 'pan') {
            this.setState({ confirmationPopupPan: false })
            if (this.state.karzaData.namePanAuthentication === null) {
                if (allDetail.applicationStatus !== 'Rejected') {
                    this.setState({ loaderState: true })
                    postCallPanDetails(allDetail.userId, callBack => {
                        this.setState({ karzaData: callBack, paninfo: true, loaderState: false }, () => this.getpanProfileDetailsData(allDetail.userId))
                    })
                }
            }
            if (this.state.karzaData.status === undefined || this.state.karzaData.status === '' || this.state.karzaData.status === null || this.state.karzaData.status === 'null' && allDetail.applicationStatus !== 'Rejected') {
                this.setState({ loaderState: true })
                postCallPanDetails(allDetail.userId, callBack => {
                    if (callBack !== null && callBack !== '' && callBack !== undefined && callBack.result !== '' && callBack.result !== null && callBack.result !== undefined) {
                        this.setState({ karzaData: callBack.result, paninfo: true, loaderState: false, panPopup: true }, () => this.getpanProfileDetailsData(allDetail.userId))
                    } else {
                        this.setState({ loaderState: false, popupStatus: 'Data Not Available', popupState: true }, () => this.removePopup())
                    }

                })
            } else {
                this.setState({ panPopup: true, paninfo: true })

            }
        }
        if (type === 'aadhar') {

            aadharVerificationDetails(allDetail.userId, callBack => {
                if (callBack.gender === null) {
                    postCallAdharVerification(allDetail.userId, allDetail.aadharNumber, callBack => {
                        aadharVerificationDetails(allDetail.userId, callBack => {
                            this.setState({ aadharVerificationData: callBack, addharPopup: true })
                        })
                    })
                } else {
                    this.setState({ aadharVerificationData: callBack, addharPopup: true })

                }
            })
            if (this.state.typeOfData === 'aadhaar') {
                this.setState({ verificationType: 'XML' })

                this.setState({ loaderState: true })

                aadharXmlDetails(allDetail.userId, callBack => {
                    if (callBack) {
                        this.setState({ karzaAdharData: callBack, loaderState: false, addharPopup: true })
                        this.setState({ addressData: JSON.parse(callBack.address) })

                    }
                })
            }
            if (this.state.typeOfData === 'digiLocker') {
                this.setState({ verificationType: 'Digilocker' })
                this.setState({ loaderState: true })

                aadharXmlDetails(allDetail.userId, callBack => {
                    if (callBack) {
                        this.setState({ karzaAdharData: callBack, loaderState: false, addharPopup: true })
                        this.setState({ addressData: JSON.parse(callBack.address) })

                    }
                })
            }

        }
    }

    downloadFile(filename, content) {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + content);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }


    maskAadharNumber(aadharNumber) {
        if (/^\d{12}$/.test(aadharNumber)) {
            const lastFourDigits = aadharNumber.slice(-4);
            const maskedAadhar = 'XXXX-XXXX-' + lastFourDigits;
            return maskedAadhar;
        } else {
            return aadharNumber;
        }
    }
    bucketView(e) {
        if (e.target.value == 'KYC') {
            this.setState({ typeofDocument: 'KYC' })
        }
        if (e.target.value == 'BANKSTATEMENT') {
            this.setState({ typeofDocument: 'BANKSTATEMENT' })
        }
        if (e.target.value == 'SALARY') {
            this.setState({ typeofDocument: 'SALARY' })
        }
        if (e.target.value == 'NOC') {
            this.setState({ typeofDocument: 'NOC' })
        }
        if (e.target.value === 'adminDoc') {
            // this.openAdminDocuments()
            this.setState({ adminDocument: true })
        }
        if (e.target.value === 'automation') {
            this.setState({ automationDocument: true })
        }

    }
    openAdminDocuments() {
        this.setState({ adminDocument: true })
    }
    closeAdminDoc() {
        this.setState({ adminDocument: false })
    }
    openautomationDocuments() {
        this.setState({ automationDocument: true })
    }
    closeautomationDoc() {
        this.setState({ automationDocument: false })
    }
    editHandler(type) {
        this.setState({ editType: type })
    }
    incompleteHandler(e) {
        this.setState({ incompleteType: e.target.value })
    }
    completeHandler(e) {
        this.setState({ completeType: e.target.value })
    }
    completeFunction(buttonType) {
        const { allDetail, admin, userDetail } = this.props
        if (buttonType == 'yes') {
            if (this.state.completeType !== '') {
                this.setState({ invalidMsg: '' })
                if (userDetail.typeOfProduct !== 'Gift Card') {
                    fetch(
                        APIS.MARK_APPLICATION_COMPLETE +
                        allDetail.userId +
                        '&loanId=' +
                        allDetail.loanId +
                        '&giftCardId=' + ''
                        + '&completeType=' + this.state.completeType
                        + '&empId=' + admin.emailId
                    )
                        .then(res => res.text())
                        .then(res => {
                            if (res == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Marked Successfully',
                                    confirmationPopupState: false,
                                    markButton: false

                                })
                                this.removePopup()
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again',
                                    confirmationPopupState: false
                                })
                                this.removePopup()
                            }
                        })
                } else {
                    fetch(
                        APIS.MARK_APPLICATION_COMPLETE +
                        allDetail.userId +
                        '&loanId=' +
                        '' + '&giftCardId=' + userDetail.giftCardId
                        + '&completeType=' + this.state.completeType
                        + '&empId=' + admin.emailId
                    )
                        .then(res => res.text())
                        .then(res => {
                            if (res == 'success') {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Marked Successfully',
                                    confirmationPopupState: false,
                                    markButton: false

                                })
                                this.removePopup()
                            } else {
                                this.setState({
                                    popupState: true,
                                    popupStatus: 'Please try again',
                                    confirmationPopupState: false
                                })
                                this.removePopup()
                            }
                        })
                }
            } else {
                this.setState({
                    invalidMsg: 'Please Select Complete Type First'
                })
                this.removePopup()
            }
        } else if (buttonType == 'no') {
            this.setState({
                confirmationPopupState: false
            })
        }
    }
    errorPopup() {
        this.setState({ errorPopupstate: true })
    }
    agreementHandler() {
        this.setState({ agreement: !this.state.agreement })
    }
    latestStatementHanlder(e) {
        this.setState({ latestStatement: e.target.value })
    }
    getanalysisData() {
        const { allDetail } = this.props
        getAnalysisDataApiNew(allDetail.userId, callback => {
            console.log(callback, 'callbackcallback')
            if (callback) {
                this.setState({ newKycData: callback })
                this.setState({ latestStatement: callback.data.bankStatementLatest })
            }


        })
    }

    handleBankNameList() {
        getBankNameList((callback) => {
            console.log(callback);
            if (callback?.status == 'success') {
                this.setState({ bankNameList: callback?.data, bankNameListPopup: true })
            } else {
                this.setState({ bankNameListPopup: true })
            }
        })
    }

    bankPopupclose() {
        this.setState({ bankNameListPopup: false })
    }
    render() {
        // this.downloadFile("download.zip",this.state.karzaAdharData.file);
        const { allDetail, allStatus, showCredit, admin, userDetail } = this.props;
        return (
            <div className=' '>

                {this.state.loaderState == true ?
                    <Loader />
                    : ""}
                {this.state.errorPopupstate ?
                    <ErrorPopup aadharDataFromKarza={this.state.aadharDataFromKarza} karzaData={this.state.karzaData} jsonErrorData={this.state.jsonErrorData} closePopup={this.closePopup.bind(this)} />
                    : ""}

                {/* <Action admin={admin} allStatus={allStatus} userdata={allDetail} userDetail={userDetail}/> */}
                {this.state.editType === 'personal' ?
                    <PersonalEdit userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />

                    : ""}

                {this.state.editType === 'indentity' ?
                    <IndentityEdit userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />

                    : ""}
                {this.state.editType === 'employment' ?
                    <EmploymentEdit userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />

                    : ""}
                {this.state.editType === 'address' ?
                    <AddressEdit userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />

                    : ""}
                {this.state.editType === 'bank' ?
                    <BankEdit getbankData={this.getbankData.bind(this)} userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />
                    : ""}
                {this.state.editType === 'refrence' ?
                    <ReferenceEdit getuserReference={this.getuserReference.bind(this)} userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />
                    : ""}
                {this.state.editType === 'docs' ?
                    <DocumentEdit getDocs={this.getDocs.bind(this)} documentsData={this.state.documentsData} getuserReference={this.getuserReference.bind(this)} userDetailHandler={this.props.userDetailHandler} admin={admin} userdata={allDetail} editHandler={this.editHandler.bind(this)} />
                    : ""}
                {this.state.panPopup ?
                    <KarzaPOPUPUI userDetail={allDetail} karzaData={this.state.karzaData} closePopup={this.closePopup.bind(this)} />
                    : ""}

                {this.state.addharPopup ?
                    <AAdharPopup addressData={this.state.addressData} verificationType={this.state.verificationType} karzaData={this.state.karzaAdharData} closePopup={this.closePopup.bind(this)}
                        downloadFile={this.downloadFile.bind(this)} aadharVerificationData={this.state.aadharVerificationData} />
                    : ""}
                {/* {this.props.creditScore == false ?
                    <div className="col-xs-12 form-group">
                        <h3 className="text-center blue-text">
                            <span className="fa fa-chevron-left pull-left pointer" onClick={(e) => this.detailBackHandler()} />
                            {allDetail.firstName + " " + allDetail.lastName} Details ({allDetail.applicationId})</h3>
                            
                    </div>
                    : ""} */}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.props.creditScore != false && showCredit == false ?
                    <CreditScore
                        allDetail={this.state.allDetail}
                        getCreditPopupState={this.getCreditPopupState.bind(this)}
                        creditScoringPopup={this.state.creditScoringPopup}
                        admin={admin}
                    />
                    : ""}

                {userDetail.loanFrom !== 'bazarBussiness' ?
                    <UserBasic
                        disableEsign={this.disableEsign.bind(this)}
                        digitapBankData={this.state.digitapBankData}
                        showVerifyButton={this.state.showVerifyButton}
                        handleBankNameList={this.handleBankNameList.bind(this)}
                        aadharVerificationData={this.state.aadharVerificationData}
                        latestStatementHanlder={this.latestStatementHanlder.bind(this)}
                        newKycData={this.state.newKycData}
                        saveLatestStatement={this.saveLatestStatement.bind(this)}
                        latestStatement={this.state.latestStatement}
                        agreement={this.state.agreement}
                        agreementHandler={this.agreementHandler.bind(this)}
                        openConfirmPopup={this.openConfirmPopup.bind(this)}
                        errorPopup={this.errorPopup.bind(this)}
                        jsonErrorData={this.state.jsonErrorData}
                        showparse={this.state.showparse}
                        sendToDigitap={this.sendToDigitap.bind(this)}
                        loaderState={this.state.loaderState}
                        bankVerificationApiFunction={this.bankVerificationApiFunction.bind(this)}
                        saveDocName={this.saveDocName.bind(this)}
                        buttonsForFunction={this.buttonsForFunction.bind(this)}
                        markButton={this.state.markButton}
                        markStatusButton={this.markStatusButton.bind(this)}
                        sendToFinb={this.sendToFinb.bind(this)}
                        UserDetailTabsHandler={this.props.UserDetailTabsHandler}
                        recentActiviesData={this.state.recentActiviesData}
                        editHandler={this.editHandler.bind(this)}
                        reasonData={this.props.reasonData}
                        automationDocument={this.state.automationDocument}
                        adminDocument={this.state.adminDocument}
                        closeAdminDoc={this.closeAdminDoc.bind(this)}
                        openAdminDocuments={this.openAdminDocuments.bind(this)}
                        closeautomationDoc={this.closeautomationDoc.bind(this)}
                        openautomationDocuments={this.openautomationDocuments.bind(this)}
                        bucketView={this.bucketView.bind(this)}
                        typeofDocument={this.state.typeofDocument}
                        documentsData={this.state.documentsData}
                        bankVerificationData={this.state.bankVerificationData}
                        aadharDataFromKarza={this.state.aadharDataFromKarza}
                        karzaData={this.state.karzaData}
                        maskAadhar={this.state.maskAadhar}
                        changeVeriFyStatus={this.changeVeriFyStatus.bind(this)}
                        aadharInfo={this.state.aadharInfo}
                        paninfo={this.state.paninfo}
                        karzaPopup={this.karzaPopup.bind(this)}
                        bankData={this.state.bankData}
                        deactivateNach={this.state.deactivateNach}
                        markincompleteapplication={this.markincompleteapplication.bind(this)}
                        userReference={this.state.userReference}
                        appVersion={this.state.appVersion}
                        vreedhiData={this.state.vreedhiData}
                        linkState={this.state.linkState}
                        userLinkHandler={this.userLinkHandler.bind(this)}
                        userDetail={userDetail}
                        panVerifiedstate={this.state.panVerifiedstate}
                        verifyHandler={this.verifyHandler.bind(this)}
                        saveVerify={this.saveVerify.bind(this)}
                        allDetail={allDetail}
                        userScore={this.state.userScore}
                        verifyMail={this.verifyMail.bind(this)}
                        admin={admin}
                        randomValues={this.state.randomValues}
                        bankResponseFromVerify={this.state.bankResponseFromVerify}
                    />
                    : ""}
                {userDetail.loanFrom === 'bazarBussiness' ?
                    <BadaBuisnessBasic
                        bankData={this.state.bankData}
                        deactivateNach={this.state.deactivateNach}
                        markincompleteapplication={this.markincompleteapplication.bind(this)}
                        userReference={this.state.userReference}
                        appVersion={this.state.appVersion}
                        vreedhiData={this.state.vreedhiData}
                        linkState={this.state.linkState}
                        userLinkHandler={this.userLinkHandler.bind(this)}
                        userDetail={userDetail}
                        panVerifiedstate={this.state.panVerifiedstate}
                        verifyHandler={this.verifyHandler.bind(this)}
                        saveVerify={this.saveVerify.bind(this)}
                        allDetail={this.state.allDetail}
                        userScore={this.state.userScore}
                        verifyMail={this.verifyMail.bind(this)}
                        admin={admin}
                        randomValues={this.state.randomValues}
                    />
                    : ""}

                {this.state.confirmationPopup == true ?
                    <Confirmation
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.confirmationPopupIncomplete == true ?
                    <Confirmation
                        cancelLoanConfirm={this.newconfilrmation.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.confirmationPopupPan == true ?
                    <ConfirmationPopupPanUi
                        allDetail={allDetail}
                        cancelLoanConfirm={this.newconfilrmationPan.bind(this)}
                    />
                    : ""}
                {this.state.confirmationPopupinCompleteState == true ?
                    <IncompletePopup
                        completeHandler={this.incompleteHandler.bind(this)}
                        invalidMsg={this.state.invalidMsg}
                        cancelLoanConfirm={this.incompleteFunction.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}
                {this.state.confirmationPopupState == true ?
                    <ConfirmationPopup
                        completeHandler={this.completeHandler.bind(this)}
                        invalidMsg={this.state.invalidMsg}
                        cancelLoanConfirm={this.completeFunction.bind(this)}
                        confirmationText={this.state.confirmationText}
                    />
                    : ""}

                {
                    this.state.bankNameListPopup == true ?
                        <BankNameList
                            BankPopupclose={this.bankPopupclose.bind(this)}
                            bankNameList={this.state.bankNameList}
                        />
                        : ''}



            </div>
        )
    }


    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            sendverificationRequestApi(this.state.mailIdForVerification,
                (callBack) => {
                    if (callBack == 'success') {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Mail has been successfully sent to user.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again.',
                            confirmationPopup: false
                        })
                        this.removePopup()
                    }
                })
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false, panPopup: false, addharPopup: false, errorPopupstate: false });
    }


    getCreditPopupState(state) {
        this.props.getCreditPopupState(state)
    }

    detailBackHandler() {
        this.props.basicBackHandler()
    }

    verifyMail(mailId) {
        this.setState({
            mailIdForVerification: mailId,
            confirmationText: 'Are you sure for send verification mail to this user?',
            confirmationPopup: true
        })
        // sendverificationRequestApi(mailId,
        //     (callBack) => {
        //         if (callBack == 'success') {
        //             this.setState({
        //                 popupState: true,
        //                 popupStatus: 'Mail has been successfully sent to user.',
        //             })
        //             this.removePopup()
        //         } else {
        //             this.setState({
        //                 popupState: true,
        //                 popupStatus: 'Please try again.',
        //             })
        //             this.removePopup()
        //         }
        //     })
    }

    componentWillUnmount() {
        const { allDetail } = '';
        this._isMounted = false;
    }

}
const mapStateToProps = ({ pendingDetail }) => {
    return {
        pendingDetail: pendingDetail
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicUser);